import { filter } from 'rxjs';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, inject, Input, Output, SimpleChanges, TemplateRef, ViewChild,viewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatDialog } from '@angular/material/dialog';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { CommonService } from 'app/core/common/common.service';
import { AuthService } from 'app/core/auth/auth.service';
import { KnowledgeBaseService } from 'app/core/knowledge-base/knowledge-base.service';
import { IKnowledgeBase } from 'app/core/knowledge-base/knowledge-base.types';
import { KnowledgeCenterService } from 'app/core/knowledge_center/knowledge-center.service';
import { IKnowledgeCenter } from 'app/core/knowledge_center/knowledge-center.types';
import moment from 'moment';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { GenericConfirmationComponent } from 'app/modules/common/generic-confirmation/generic-confirmation.component';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/core/common/analytics_service';
import analytics_event from 'app/core/common/analytics_event';
import { WebsocketService } from 'app/core/websockets/websocket.service';

@Component({
  selector: 'app-documented-ai',
  standalone: true,
  imports: [NgIf, MatFormFieldModule,NgFor,FuseAlertComponent,MatMenuModule, NgClass, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule,FuseDrawerComponent],
  templateUrl: './documented-ai.component.html',
  styleUrl: './documented-ai.component.scss'
})
export class DocumentedAiComponent {
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  @ViewChild(FuseDrawerComponent) docDrawer: FuseDrawerComponent;

  documentData : [0,1,2,3];
  _proposalDetail: any = {};


  @Input() proposalID:string;

  @Input() receiveData: string;

  // @Input() proposal:any;
  @Input() set proposal(value: any) {
    if (value) {
      // Use a setter to detect changes in the Input property
      this._proposalDetail = value;
      this.ngOnInit();
      this.cdr.detectChanges(); // Optionally force change detection
    }
  }

  @Input() user:any;

  @Input() accessStatus:any;

  @Output() onKCDocUpload=new EventEmitter<any>();

  tagsEditMode: boolean = false;
  upload_loader: boolean = false;
  autosave_loader: boolean = false;
  saveClicked: boolean = false;
  docSaved: boolean = false;
  headerForm:UntypedFormGroup;
  documentForm:UntypedFormGroup;
  tagArray: FormArray<any>;
  cloudArray: FormArray<any>;
  filteredTags:any[]=[];
  alltagTypes:any[]=[];
  selectedFileDoc: File | null = null;
  uploadedFileName:string="";
  selectedFile=null;
  all_tags:any [] = [];
  all_docs:any [] = [];
  all_original_docs:any [] = [];
  selectedSupportingFiles:any [] =[];
  typeOptions=[];
  embeddingsTypeOptions=[];
  autosaveSubscription: any;
  private _tagsPanelOverlayRef: OverlayRef;
  mimeTypeMapping = {
    'application/pdf': 'PDF',
    'text/csv': 'CSV',
    'text/plain': 'TXT',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
    'image/jpeg': 'Image',
    'image/png': 'Image'
  };

  constructor(private _websocketService:WebsocketService,private _analyticsService:AnalyticsService,private cdr: ChangeDetectorRef,private overlayContainer: OverlayContainer,private _formBuilder: UntypedFormBuilder,private _knowledgeCenterService:KnowledgeCenterService,private _knowledgeBaseService:KnowledgeBaseService,private _proposalService:ProposalService, private _authService: AuthService, private _viewContainerRef: ViewContainerRef, private _overlay: Overlay,private _commonService:CommonService, public dialog: MatDialog){
    this.typeOptions=this._knowledgeCenterService.typeOptions;
    this.embeddingsTypeOptions=this._knowledgeCenterService.embeddingsTypeOptions;


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "supporting_doc_vectors_generated")
    ).subscribe((message:any) => {
      try{
       console.log("Resp",message.id);
        if(message.id){
          for(let doc of this.all_docs){
            if(doc._id==message.id){
              doc.vectorsGenerated=true;
              doc.isProcessed=true;
              break;
            }
          }
        }
      }catch(error){
        console.log(error);
      }
      
    });


  }

  ngOnInit(): void {
    this.documentForm = this._formBuilder.group({
      author    : [''],
      authorName    : [''],
      clouds: this._formBuilder.array([]),
      created: [''],
      description: [''],
      document_type: [''],
      fileName: [''],
      fileSize: [''],
      industry: [''],
      summary: [''],
      technology: [''],
      title: [''],
      type: [''],
      _id: [''],
      tags: this._formBuilder.array([])
    });
    
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_tag : ['']
    });
    // console.log(this.accessStatus);
    this.tagArray = this.documentForm.get('tags') as FormArray;
    this.cloudArray = this.documentForm.get('clouds') as FormArray;
    this.all_docs=[];
    this.all_original_docs=[];
    this.all_tags=[];
    if(this._proposalDetail && this._proposalDetail.supporting_docs && this._proposalDetail.supporting_docs.length){
      this._proposalDetail.supporting_docs.sort((a, b) => b.created.localeCompare(a.created));
      this._proposalDetail.supporting_docs.forEach((docrecord) => {
        let doc = JSON.parse(JSON.stringify(docrecord));
        doc['document_type']='supporting_docs';
        
        this.all_docs.push(doc);
        this.all_original_docs.push(doc);
        if(doc.tags && doc.tags.length){
          doc.tags.forEach((eachtag) => {
            if(!this.all_tags.find(el=>el.value==eachtag))this.all_tags.push({label:eachtag,value:eachtag,created:doc.created});
          });
        }
      });
    }
    if(this._proposalDetail && this._proposalDetail.knowledge_center_docs && this._proposalDetail.knowledge_center_docs.length){
      this._proposalDetail.knowledge_center_docs.forEach((doc) => {
        doc['document_type']='knowledge_center_docs';
        this.all_docs.push(doc);
        this.all_original_docs.push(doc);
        if(doc.tags && doc.tags.length){
          doc.tags.forEach((eachtag) => {
            if(!this.all_tags.find(el=>el.value==eachtag))this.all_tags.push({label:eachtag,value:eachtag,created:doc.created});
          });
        }
      });
    }
    this.all_docs.sort((a, b) => b.created.localeCompare(a.created));
    this.all_tags.sort((a, b) => a.label.localeCompare(b.label));
    this.all_original_docs.sort((a, b) => b.created.localeCompare(a.created));
    this.setupValueChangeListeners();   
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  setupValueChangeListeners(): void {
    // if (this.documentForm.get('description')) {
    //   this.autosaveSubscription = this.documentForm.get('description').valueChanges.pipe(
    //     debounceTime(1000), // Wait for 3 seconds after the last keystroke
    //     switchMap(value => this.autoSave()) // Cancel the previous API call if a new value comes in
    //   ).subscribe();
    // }
  }

  getFileTypeString(type:number){
    let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.value===type);
    return fileValue.label;
  }

  getFileClass(fileType: number): string {
    // console.log(fileType);
    switch (fileType) {
      case 0: return 'bg-red-600';
      case 1: return 'bg-green-600';
      case 2: return 'bg-gray-600';
      case 3: return 'bg-blue-600';
      case 4: return 'bg-blue-500';
      case 5: return 'bg-yellow-600';
      case 6: return 'bg-yellow-500';
      case 7: return 'bg-orange-600';
      case 8: return 'bg-orange-500';
      case 9: return 'bg-amber-600';
      case 10: return 'bg-green-600';
      default: return 'bg-gray-600';
    }
  }

  format(date,type){
    if(type=='initial')
      return moment(date).format('DD MMM YYYY');
    else if(type=='comma')
      return moment(date).format('DD MMM, YYYY');
  }

  formatFileSize(bytes) {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = bytes;
    let unitIndex = 0;
  
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
  
    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }

  openedChanged(event){
    if(event===false){
      this.onCloseDrawer();
    }
  }

  selectDocument(doc){
    this.selectedFile = doc;
    this.documentForm.get('description').setValue(doc.description);
    this.documentForm.get('document_type').setValue(doc.document_type);
    this.documentForm.get('_id').setValue(doc._id);
    // this.documentForm = this._formBuilder.group({
    //   author    : [doc.author],
    //   authorName    : [doc.authorName],
    //   clouds: this._formBuilder.array([]),
    //   created: [doc.created],
    //   description: [doc.description],
    //   document_type: [doc.document_type],
    //   fileName: [doc.fileName],
    //   fileSize: [doc.fileSize],
    //   industry: [doc.industry],
    //   summary: [doc.summary],
    //   technology: [doc.technology],
    //   title: [doc.title],
    //   type: [doc.type],
    //   _id: [doc._id],
    //   tags: this._formBuilder.array([])
    // });
    if(doc.tags && doc.tags.length){
      doc.tags.forEach((eachtag) => {
        this.tagArray.push(new FormControl(eachtag));
        this.alltagTypes.push({label:eachtag,value:eachtag});
      });
    }
    if(doc.clouds && doc.clouds.length){
      let self = this;
      doc.clouds.forEach((eachcloud) => {
        self.cloudArray.push(new FormControl(eachcloud));
      });
    }

  }

  /**
     * Open tags panel
     */
  openTagsPanel(): void
  {
    this.filteredTags = this.alltagTypes;
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });

      // Subscribe to the attachments observable
      this._tagsPanelOverlayRef.attachments().subscribe(() =>
      {
          // Focus to the search input once the overlay has been attached
          this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
      });

      // Create a portal from the template
      const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);

      // Attach the portal to the overlay
      this._tagsPanelOverlayRef.attach(templatePortal);

      // Subscribe to the backdrop click
      this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
      {
          // If overlay exists and attached...
          if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
          {
              // Detach it
              this._tagsPanelOverlayRef.detach();

              // Reset the tag filter
              this.filteredTags = this.alltagTypes;

              // Toggle the edit mode off
              this.tagsEditMode = false;
          }

          // If template portal exists and attached...
          if ( templatePortal && templatePortal.isAttached )
          {
              // Detach it
              templatePortal.detach();
          }
      });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();

      // Filter the tags
      this.filteredTags = this.alltagTypes.filter(tag => tag.label.toLowerCase().includes(value));
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string): boolean
  {
      return !!!(inputValue === '' || this.alltagTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event): void
  {
      // Return if the pressed key is not 'Enter'
      if ( event.key !== 'Enter' )
      {
          return;
      }

      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }

      // If there is a tag...
      const tag = this.filteredTags[0];
      const isTagApplied = this.alltagTypes.find(id => id === tag._id);

      // If the found tag is already applied to the task...
      if ( isTagApplied )
      {
          // Remove the tag from the task
          // this.deleteTagFromTask(tag);
      }
      else
      {
          // Otherwise add the tag to the task
          this.addTagToTask(tag);
      }
  }

  toggleTaskTag(event): void
  {   
    // console.log(event);
    this.filteredTags = this.alltagTypes.filter(tag => !tag.label.toLowerCase().includes(event.value));
    this.alltagTypes = this.filteredTags;
    // console.log(this.alltagTypes);
    this.tagArray.clear();
    this.filteredTags.forEach((eachtag) => {
      this.tagArray.push(new FormControl(eachtag.label));
    });
    // Toggle the edit mode off
    this.tagsEditMode = false;
    // this.autoSave();
  }

  /**
   * Create a new tag
   *
   * @param title
   */
  createTag(title: string): void
  {
    const tag = {
        title,
    };
    this.addTagToTask(tag);
    
    // this._tagsPanelOverlayRef.detach();
    this._tagsPanelOverlayRef.dispose();
    this.filteredTags = this.alltagTypes;

    // Toggle the edit mode off
    this.tagsEditMode = false;
      // // Create tag on the server
      // this._tasksService.createTag(tag)
      //     .subscribe((response) =>
      //     {
      //         // Add the tag to the task
      //         this.addTagToTask(response);
      //     });
  }
  /**
   * Add tag to the task
   *
   * @param tag
   */
  addTagToTask(tag): void
  {
    // console.log(tag);
    this._tagsPanelOverlayRef.dispose();
    this.tagArray.push(new FormControl(tag.title));
    this.alltagTypes.push({label:tag.title,value:tag.title});
    this.filteredTags = this.alltagTypes;

    // Toggle the edit mode off
    this.tagsEditMode = false;
    // this.autoSave();
      // // Add the tag
      // this.task.tags.unshift(tag.id);

      // // Update the task form
      // this.taskForm.get('tags').patchValue(this.task.tags);

      // // Mark for check
      // this._changeDetectorRef.markForCheck();
  }

  autoSave() {
    // Implement your save logic here
    // console.log('Auto-saving form data:', this.documentForm.value);//return;
    let all_values = this.documentForm.value;
    this.autosave_loader = true;
    let tags=[];

    this.tagArray.controls.forEach((control: FormControl, index: number) => {
      tags.push(control.value);
    });
    // return of(true);
    this._proposalService.updateProposalDoc({documentID:this._proposalDetail._id,_id:all_values._id,description: all_values.description,tags:tags,document_type:all_values.document_type}).subscribe(
      (value) =>
        {
          if(value.success){
            this.saveClicked = true;
          }
          this.autosave_loader = false;
          this.docSaved = true;
          setTimeout(()=>{
            this.docSaved = false;
          },1000);
          
        },
        (response) =>
        {
        },
    );
    return of(true);
  }

  onDeleteSupportingDocument(file:any){
    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {title:"Are you sure you want to delete this document?",subtitle:"All questions answered from this document will remain intact",yesAction:"Delete",noAction:"Cancel"},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.yes===true && file.document_type=='supporting_docs'){
        for(let i=0;i<this._proposalDetail.supporting_docs.length;i++){
          if(this._proposalDetail.supporting_docs[i]._id==file._id){
            this._proposalDetail.supporting_docs.splice(i,1);
            this._proposalService.deleteProposalSupportingDoc(this._proposalDetail._id,file._id).subscribe((response)=>{});
            this.onKCDocUpload.emit({refresh:true});
            this.onCloseDrawer();
            break;
          }
        }
      }
      else if(result.yes===true && file.document_type=='knowledge_center_docs'){
        for(let i=0;i<this._proposalDetail.knowledge_center_docs.length;i++){
          if(this._proposalDetail.knowledge_center_docs[i]._id==file._id){
            this._proposalDetail.knowledge_center_docs.splice(i,1);
            this._proposalService.deleteProposalKCDoc(this._proposalDetail._id,file._id).subscribe((response)=>{});
            this.onKCDocUpload.emit({refresh:true});
            this.onCloseDrawer();
            break;
          }
        }
      }

    });
  }

  search(event){
    const searchString = event.target.value.toLowerCase();
    let all_docs = JSON.parse(JSON.stringify(this.all_original_docs));
    this.all_docs = all_docs.filter((doc) =>
      [doc.title, doc.description, ...(doc.tags || [])]
        .some(field => field && field.toString().toLowerCase().includes(searchString))
    );
  }

  filterCount(){
    let all_docs = JSON.parse(JSON.stringify(this.all_original_docs));
    let tag_collection = [];
    if (this.headerForm.get('selected_tag').value && this.headerForm.get('selected_tag').value.length) {
      this.all_docs = [];
      tag_collection = this.headerForm.get('selected_tag').value;

      all_docs.forEach(element => {
        if (element.tags && element.tags.some(tag => tag_collection.includes(tag))) {
          this.all_docs.push(element);
        }
      });
    } else {
      this.all_docs = all_docs;
    }
  }

  onCloseDrawer(){
    if(this.saveClicked)this.onKCDocUpload.emit({refresh:true});
    this.selectedFile=null;
    this.filteredTags=[];
    this.alltagTypes=[];
    this.saveClicked = false;
    this.tagArray.clear();
    this.cloudArray.clear();
    this.docDrawer.close();
  }

  selectDoc(){
    this.upload_loader = false;
    document.getElementById('fileDoc').click();
  }

  selectFiles(event: any): void {
    this.upload_loader = true;
    const file: File = event.target.files[0];
    this.selectedFileDoc = file;
    this.sendForm();
  }

  async onFilesSelected(event){
    this.upload_loader = true;
    const maxSizeInBytes = 1048576*20; //20MB
     const input = event.target as HTMLInputElement;
     if (input.files) {
       const files: FileList = input.files;
       // Handle the selected files here
       for(let a=0;a<files.length;a++){
         if(files[a].size>maxSizeInBytes){
           
          //  this.alert={
          //    type:"error",
          //    message:"The maximum allowed file size is 20MB"
          //  }
          //  this.sizeLimitAlert=true;
          //  setTimeout(()=>{
          //    this.sizeLimitAlert=false;
          //  },3000);
           return;
       }
     }

      //console.log(files);
       for (let i = 0; i < files.length; i++) {
         if(files[i].size>maxSizeInBytes){
          //  this.alert={
          //    type:"error",
          //    message:"The maximum allowed file size is 20MB"
          //  }
          //  this.sizeLimitAlert=true;
          //  setTimeout(()=>{
          //    this.sizeLimitAlert=false;
          //  },3000);
         }else{
          let fileData=this.detectFileType(files[i]);console.log(fileData);
          if(fileData){
            this.selectedSupportingFiles.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size});
          }
         }
       }
       await this.uploadSupportingFiles();
     }
     
  }

  async uploadSupportingFiles(){
    for(let i=0;i<this.selectedSupportingFiles.length;i++){
      if(this.selectedSupportingFiles[i].uploadURL===""){
        try{
          let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.selectedSupportingFiles[i].title,filetype: this.selectedSupportingFiles[i].file.type}));
          this.selectedSupportingFiles[i].uploadURL=uploadResp.uploadURL;
          this.selectedSupportingFiles[i].fileName=uploadResp.filename;
        }catch(error){
          console.log("Error:",error);
        }

        try{

          this._analyticsService.trackEvent(analytics_event.PROJECT_DOCUMENT_AI_FILE_UPLOAD,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,documentType:this.selectedSupportingFiles[i].file.type});
          }catch(error){
            console.log(error);
          }

        await firstValueFrom(this._proposalService.uploadSupportingFile(this.selectedSupportingFiles[i].uploadURL,this.selectedSupportingFiles[i].file));

        let supportingDocument={
          title: this.selectedSupportingFiles[i].title,
          description: "",
          type: this.selectedSupportingFiles[i].type,
          fileName: this.selectedSupportingFiles[i].fileName,
          technology:this._proposalDetail.proposal_meta.technology,
          clouds:this._proposalDetail.proposal_meta.product,
          industry:this._proposalDetail.proposal_meta.industry,
          proposalID:this._proposalDetail._id,
          fileSize:this.selectedSupportingFiles[i].fileSize,
          authorName: `${this.user.personal?.name?.first} ${this.user.personal?.name?.last}` || ""
        };

        try{
          let sdResponse=await firstValueFrom(this._proposalService.saveSupportingDocument(supportingDocument));
          console.log(sdResponse);
          this.selectedSupportingFiles[i].id=sdResponse.data._id||"";
          if(this._proposalDetail.supporting_docs && this._proposalDetail.supporting_docs.length)this._proposalDetail.supporting_docs.push(sdResponse.data);
          else this._proposalDetail.supporting_docs = [sdResponse.data];

          this._proposalService.updateProposalSupportingDoc(this._proposalDetail._id,sdResponse.data._id).subscribe((response)=>{
            console.log("Supporting document updated successfully:",response);
            this.onKCDocUpload.emit({refresh:true});
            this.upload_loader = false;
          });
        }catch(error){

        }
      }
    }
  }

  detectFileType(file: File): { value: number, label: string } | null {
    // console.log(file);
    const fileTypeMap: { [key: string]: string } = {
      'application/pdf': 'PDF',
      'text/csv': 'CSV',
      'text/plain':"TXT",
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'image/jpeg': 'JPEG',
      'image/png': 'PNG',
      'image/jpg': 'JPG',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
    };
    const fileType = file.type;
    if (fileTypeMap[fileType]) {
      let thisFileType=fileTypeMap[fileType];
      let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
      return { value: fileValue.value, label: thisFileType };
    }
    return null;
  }

  async sendForm(){
    if(this.selectedFileDoc!=null){
      try{
      let uploadResponse:any=await firstValueFrom(this._knowledgeBaseService.createUploadURL({
        filename: this.selectedFileDoc.name,
        filetype: this.selectedFileDoc.type,
      }));
      let fileUploadResponse=await firstValueFrom(this._knowledgeCenterService.uploadSupportingFile(uploadResponse.uploadURL,this.selectedFileDoc));
      if(fileUploadResponse.ok){
        this.uploadedFileName=uploadResponse.filename;
      }

      }catch(error){
      console.log("Failed to upload file",error);
      return;
      }
    }

    let knowledgeBase:IKnowledgeCenter={
      title: this.selectedFileDoc.name,
      description: '',
      type: this.getFileTypeValue(this.selectedFileDoc.type),
      embeddingsType: 0,
      fileName:this.uploadedFileName,
      fileSize:this.selectedFileDoc.size,
      content:'',
    };
    knowledgeBase['proposal_id']=this.proposalID;
    knowledgeBase['function_type']='proposal_kc';//console.log(knowledgeBase);
    this._knowledgeCenterService.saveKnowledgeCenter(knowledgeBase).subscribe((success)=>{
      if(success){
        // this.onKCDocUpload.emit({refresh:true});
        this.upload_loader = false;
      }else{
        this.upload_loader = false;
      }
    });
  }

  getFileTypeValue(fileMimeType: string) {
    const label = this.mimeTypeMapping[fileMimeType];
    const fileType = this.typeOptions.find(type => type.label === label);
    return fileType ? fileType.value : null; // return value if found, otherwise null
  }

  downloadFile(url){
    window.open(environment.file_url_cf + url + '?token=' + this._authService.accessToken, '_blank');
  }

  openDialog(file) {
    this.selectedFile = file;
    const dialogRef = this.dialog.open(ChatBoxComponent,
      { 
        panelClass: 'add-feature-dialog',
        disableClose: true,
        autoFocus: false,  
        restoreFocus: false,
        data : {
          proposalID: this.proposalID,
          proposal : this._proposalDetail,
          document: this.selectedFile,
          accessStatus: this.accessStatus
        }
      }
    );
    dialogRef.beforeClosed().subscribe(result => {
      // console.log(result);
      // this.onKCDocUpload.emit({refresh:true});
      const overlayContainerElement = this.overlayContainer.getContainerElement();
      if (overlayContainerElement) {
        const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
        overlayContainers.forEach(overlayPane => {
          overlayPane.remove();  // Only removes the specific dialog content
        });
        // overlayContainerElement.innerHTML = '';  // Clear the contents of the overlay container
      }
      this.cdr.detectChanges();
    });
  }



}
