<div class="flex flex-col flex-auto min-w-0 relative popup-content overflow-visible" >
    <span class="close-dialog" [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
</div>

<div class="flex-auto">

<form class="p-4 max-w-2xl" [formGroup]="horizontalStepperForm">
    <mat-horizontal-stepper [linear]="true" #horizontalStepper>

        <mat-step *ngIf="!type" [stepControl]="horizontalStepperForm.get('step0')"
            #horizontalStepperStep0 >

            <div class="flex justify-center flex-col items-center">                
                <h5 class="text-2xl mt-2">Authenticate</h5>
                <p class="text-secondary mt-6">Click 'Connect to Salesforce Org' to log in securely and proceed with the integration process. Once authenticated, you can move forward with the integration process.</p>
                <button
                    class="px-8 mt-12 min-w-60 salesforceBt"
                    mat-flat-button

                    [disabled]="horizontalStepperStep0.stepControl.invalid"
                    type="button"
                    matStepperNext>
                    Connect to Salesforce Org
                </button>
            </div>
        </mat-step>

        <mat-step [formGroupName]="'step1'" [stepControl]="horizontalStepperForm.get('step1')"
            #horizontalStepperStep1 >
            <div class="flex justify-center flex-col items-center gap-3 m-auto max-w-[30rem]">
                    <h5 class="text-2xl mt-2">Add Credential</h5>
                    <mat-form-field class="w-full">
                        <mat-label>URL</mat-label>
                        <input matInput  [formControlName]="'salesForces_url'">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Username</mat-label>
                        <input matInput  [formControlName]="'username'">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Password</mat-label>
                        <input matInput [formControlName]="'password'" type="password">
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Security Token</mat-label>
                        <input matInput [formControlName]="'secret_key'">
                    </mat-form-field>
            </div>

            <!-- alert div start-->

            <div *ngIf="flashMessageType" class="m-auto max-w-[30rem] flex flex-col">
                <fuse-alert class="mb-3 mt-3" [type]="flashMessageType"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="false">
                    <mat-icon *ngIf="(flashMessageType=='success')" class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
                    <mat-icon *ngIf="(flashMessageType=='error')" class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:x-circle'"> </mat-icon>    
                    {{ flashMessage }}
                </fuse-alert>
            </div>
            <!-- alert div end-->
              
            <div class="flex justify-center mt-6 ">
                <button *ngIf="!type" class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                    Back
                </button>
                <button
                    class="px-8 "
                    mat-flat-button
                    [color]="'primary'"
                    [disabled]="(horizontalStepperStep1.stepControl.invalid || load_authenticate)"
                    type="button" (click)="authenticate()">
                    <mat-progress-spinner *ngIf="load_authenticate" mode="indeterminate"></mat-progress-spinner>
                    Authenticate
                </button>
            </div>
        </mat-step>

        <mat-step  [formGroupName]="'step2'" [stepControl]="horizontalStepperForm.get('step2')"
            #horizontalStepperStep2 >
            
            <div class="flex justify-center flex-col items-center gap-6">
                <h5 class="text-2xl mt-6">Metadata Extract</h5>
                <h5 class="text-2xl mt-6"> Do you wish to initiate the metadata extraction process?</h5>         
            </div>

            
            <div class="flex justify-center mt-12 gap-4">
                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                    Back
                </button>
                <button class="px-8 min-w-30" mat-flat-button [color]="'primary'" [disabled]="load_extract" type="button" (click)="metadataExtract()">
                    <mat-progress-spinner *ngIf="load_extract" mode="indeterminate"></mat-progress-spinner>
                    Yes
                </button>
                <button class="px-8 skipBt min-w-30" mat-flat-button type="button" (click)="skip()">Skip
                </button>
            </div>
        </mat-step>

    </mat-horizontal-stepper>
</form>
</div>