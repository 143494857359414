import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatSelectModule } from '@angular/material/select';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from 'app/core/common/event.service';
import { FuseHorizontalNavigationSpacerItemComponent } from '@fuse/components/navigation/horizontal/components/spacer/spacer.component';


export interface ObjectElement {
  name: string;
  id: number;
  description: string;
  apiname: string;
  completion: string;
  action: string;
  completionClass: string;
  actionClass:string;
}

export interface ApexElement {
  name: string;
  id: number;
  description: string;
  completion: string;
  action: string;
  completionClass: string;
  actionClass:string;
}



const ELEMENT_DATA: ObjectElement[] = [
  {id: 1, name: 'VehicleInventory', description: 'Stores vehicle details such as make, model, year, VIN, and availability status.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 2, name: 'CustomerDetails', description: 'Contains customer profiles, purchase history, and contact preferences.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 3, name: 'FinanceOptions', description: 'Manages various financing plans and associated terms available to customers.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 4, name: 'DealershipLocations', description: 'Stores dealership location details and contact information for easy customer reference.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 5, name: 'SalesLeads', description: 'Captures and categorizes new sales leads, including source and lead stage.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 6, name: 'AppointmentHistory', description: 'Logs past and upcoming customer appointments, including test drives and service visits.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy'},
  {id: 7, name: 'WarrantyRecords', description: 'Maintains warranty information for each vehicle sold and service coverage details.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 8, name: 'TradeInOffers', description: 'Tracks trade-in offers made to customers, including evaluation criteria and final offer details.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 9, name: 'SalesForecasts', description: 'Contains data on projected sales targets based on historical trends and market analysis.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 10, name: 'ServiceRequests', description: 'Manages customer service requests, including issue tracking and resolution status.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 11, name: 'MarketingCampaigns', description: 'Stores information on active and past marketing campaigns, including target audience and results.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 12, name: 'CustomerFeedback', description: 'Collects and organizes feedback from customers to guide service improvements.',  apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy'  },
  {id: 13, name: 'InventoryForecasting', description: 'Forecasts inventory needs based on current stock and expected sales volume.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 14, name: 'SalesPerformance', description: 'Tracks sales performance metrics for reporting and analysis purposes.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 15, name: 'RecallNotifications', description: 'Stores details on vehicle recalls and tracks notifications sent to affected customers.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
];



const ELEMENT_DATA2: ApexElement[] = [
  {id: 1, name: 'LeadAssignmentHandler', description: 'Manages automatic lead assignment based on criteria such as region, lead source, and sales rep load.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 2, name: 'VehicleInventoryManager', description: 'Handles vehicle inventory data integration and updates in Salesforce.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 3, name: 'CustomerNotification', description: 'Automates customer notifications for test drives, order updates, and promotional offers.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy' },
  {id: 4, name: 'FinanceApplication', description: 'Processes finance applications, including credit checks and approval workflows.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 5, name: 'DealerLocator', description: 'Provides functionality to locate and display nearby dealerships based on customer location.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 6, name: 'SalesForecasting', description: 'Generates sales forecasts using historical data and predictive models for future planning.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy'},
  {id: 7, name: 'AppointmentScheduler', description: 'Manages scheduling of test drives and service appointments, with customer reminders.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 8, name: 'WarrantyServiceManager', description: 'Tracks and manages warranty information and service eligibility for sold vehicles.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 9, name: 'TradeInEvaluator', description: 'Calculates trade-in values based on vehicle condition, mileage, and market data.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 10, name: 'SalesRepPerformance', description: 'Analyzes sales rep performance metrics and generates reports.', completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 11, name: 'CustomerFeedbackHandler', description: 'Collects and analyzes customer feedback post-sale to improve service quality.', completion:'Deployed',action:'Generate Code',completionClass:'deployed',actionClass:'view' },
  {id: 12, name: 'InventoryForecasting', description: 'Predicts inventory needs based on sales trends and order history.',  completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy'  },
  {id: 13, name: 'DealApprovalWorkflow', description: 'Manages approval workflows for vehicle deals and pricing adjustments.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 14, name: 'SalesOrderProcessor', description: 'Processes vehicle sales orders and updates relevant records.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 15, name: 'VehicleRecallNotifier', description: 'Notifies customers of vehicle recalls and manages scheduling for recall-related service.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy' },
];



@Component({
  selector: 'app-metadata-extract',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, FuseDrawerComponent,MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule, MatProgressSpinnerModule, FuseAlertComponent],
  templateUrl: './metadata-extract.component.html',
  styleUrl: './metadata-extract.component.scss'
})

export class MetadataExtractComponent {
  @ViewChild(FuseDrawerComponent) viewDrawer: FuseDrawerComponent;
  extractForm:UntypedFormGroup;
  headerForm:UntypedFormGroup;
  _proposalDetail: any = {};
  _userDetail: any = {};
  findMetadataDetail: any = {};
  describeMetadataList: any [] =[];
  originaldescribeMetadataList: any [] =[];
  fetch_meta_data_val: boolean = false;

  dispalyXml:boolean = false;
  xmlCode = `<note>
  <to>Tove</to>
  <from>Jani</from>
  <heading>Reminder</heading>
  <body>Don't forget me this weekend!</body>
</note>`;


  @Input() set fetch_meta_data(value: any) {
    if (value) {
      this.fetch_meta_data_val = value;
    }
  }

  @Input() set proposal(value: any) {
    if (value) {
      // Use a setter to detect changes in the Input property
      this._proposalDetail = value;
      this.ngOnInit();
      this.cdr.detectChanges(); // Optionally force change detection
    }
  }
  
  @Input() set user(value: any) {
    if (value) {
      this._userDetail = value;
    }
  }

  @Input() set findMetadata(value: any) {
    if (value) {
      this.findMetadataDetail = value;
    }
  }

  @Input() is_write_allowed:any;
  @Input() accessStatus:any;
  
  @Input() set describeMetadata(value: any) {
    if (value) {
      this.describeMetadataList = value;
      this.originaldescribeMetadataList = value;
      let findSelectedTab = this.describeMetadataList.findIndex(el=>el.xmlName==this.activeTab);
      if(findSelectedTab>-1){
        this.activeTab = this.describeMetadataList[findSelectedTab].extracted_data.length?this.describeMetadataList[findSelectedTab].fullName:this.describeMetadataList[0].fullName;
      }
      else {
        let findSelectedTabActive = this.describeMetadataList.findIndex(el => el.active === true);
        if (findSelectedTabActive > -1) {
          this.activeTab = this.describeMetadataList[findSelectedTabActive].extracted_data.length
            ? this.describeMetadataList[findSelectedTabActive].fullName
            : this.describeMetadataList[0].fullName;
        } else {
          this.activeTab = this.describeMetadataList[0].fullName;
        }
      }
    }
  }

  displayedColumns: string[] = ['name', 'description'];
  dataSource = ELEMENT_DATA;

  displayedColumns2: string[] = ['name', 'description','completion','action'];
  dataSource2 = ELEMENT_DATA2;

  activeTab: String;
  loader_xml:boolean = false;
  alert_xml:boolean = false;
  editMeta:boolean = false;
  filterCountActive:boolean = false;
  edit_extract:boolean = false;
  extractSaved:boolean = false;
  showText: string;
  total_message_received: number = 0;
  selectedMetadata: any = {};
  complete_category_list: any[] = [];
  category_list: any[] = [];
  subcategory_list: any[] = [];

  constructor(private cdr: ChangeDetectorRef,private _websocketService: WebsocketService,private _formBuilder: UntypedFormBuilder,private eventService: EventService, public dialog:MatDialog,private proposalService:ProposalService){
    
  }

  ngOnInit(): void {  
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_category: [''],
      selected_subcategory: ['']
    });
    this.extractForm = this._formBuilder.group({
      metadata_id: new FormControl(''),
      metadata_object_id: new FormControl(''),
      _id: new FormControl(''),
      fullName: new FormControl(''),
      description: new FormControl(''),
      type: new FormControl(''),
      category: new FormControl(''),
      sub_category: new FormControl(''),
      xmlCode: new FormControl('')
    });
    this.category_list = [];this.complete_category_list = [];
    this.proposalService.categoryList$.subscribe({
      next: (response) => {
          if (response && response.length) {
            this.complete_category_list = response;
            response.forEach(element => {
              this.category_list.push({label:element.category,value:element.category});
            });
            this.cdr.detectChanges();
          }
      },
      error: (err) => {
          console.error('Error fetching agents:', err);
      }
    });

    this.proposalService.getCategoryList().subscribe({
        error: (err) => {
            console.error('Error triggering batch fetch:', err);
        }
    }); 
    this.setupSearchListener();
  }

  setupSearchListener(): void {
    this.headerForm.get('search')?.valueChanges
    .pipe(
      debounceTime(500),
      map(() => this.filterCount())
    )
    .subscribe(filteredResults => {
      this.valueSet(filteredResults);
    });
    this.headerForm.get('selected_category')?.valueChanges
    .pipe(
      map(() => this.filterCount())
    )
    .subscribe(filteredResults => {
      this.valueSet(filteredResults);
    });
    this.headerForm.get('selected_subcategory')?.valueChanges
    .pipe(
      map(() => this.filterCount())
    )
    .subscribe(filteredResults => {
      this.valueSet(filteredResults);
    });
  }

  valueSet(filteredResults){
    this.describeMetadataList = filteredResults;
    if(this.describeMetadataList.length){
      let findSelectedTab = this.describeMetadataList.findIndex(el=>el.xmlName==this.activeTab);
      if(findSelectedTab>-1){
        this.activeTab = this.describeMetadataList[findSelectedTab].extracted_data.length?this.describeMetadataList[findSelectedTab].fullName:this.describeMetadataList[0].fullName;
      }
      else {
        let findSelectedTabActive = this.describeMetadataList.findIndex(el => el.active === true);
        if (findSelectedTabActive > -1) {
          this.activeTab = this.describeMetadataList[findSelectedTabActive].extracted_data.length
            ? this.describeMetadataList[findSelectedTabActive].fullName
            : this.describeMetadataList[0].fullName;
        } else {
          this.activeTab = this.describeMetadataList[0].fullName;
        }
      }
    }
  }

  onChangeCategory(event){
    this.headerForm.get('selected_subcategory').setValue('');
    this.subcategory_list=[];
    if(event.value){
      let matchValue = this.complete_category_list.find(el=>el.category==event.value);
      if(matchValue){
        matchValue.subcategories.forEach(element => {
          this.subcategory_list.push({label:element,value:element});
        });
      }
      this.cdr.detectChanges();
    }
  }

  filterCount(): any[] {
    const searchValue = this.headerForm.get('search')?.value?.toLowerCase() || '';
    const selectedCategory = this.headerForm.get('selected_category')?.value?.toLowerCase() || '';
    const selectedSubcategory = this.headerForm.get('selected_subcategory')?.value?.toLowerCase() || '';
    
    let describeMetadataList = JSON.parse(JSON.stringify(this.originaldescribeMetadataList));
    
    if (!searchValue && !selectedCategory && !selectedSubcategory) {
      this.filterCountActive = false;
      return describeMetadataList;
    }
    this.filterCountActive = true;
    const filteredList = describeMetadataList.map((item: any) => {
        const category = item.category?.toLowerCase() || '';
        const subcategory = item.subcategory?.toLowerCase() || '';

        // Match category (mandatory if selected)
        const isCategoryMatch = selectedCategory ? category === selectedCategory : true;

        // Match subcategory (only if both category and subcategory are selected)
        const isSubcategoryMatch = selectedSubcategory ? subcategory === selectedSubcategory : true;

        // Ensure category match is mandatory
        if (!isCategoryMatch) {
            return { ...item, extracted_data: [] }; // Return item with empty extracted_data
        }

        if (selectedCategory && selectedSubcategory && !isSubcategoryMatch) {
            return { ...item, extracted_data: [] }; // Return item with empty extracted_data
        }

        const isMatchInTopLevel =
            category.includes(searchValue) ||
            subcategory.includes(searchValue) ||
            item.childXmlNames?.toLowerCase().includes(searchValue) ||
            item.xmlName?.toLowerCase().includes(searchValue) ||
            item.fullName?.toLowerCase().includes(searchValue);

        const filteredExtractedData = item.extracted_data?.filter((data: any) => {
            return (
                data.fileName?.toLowerCase().includes(searchValue) ||
                data.filefullName?.toLowerCase().includes(searchValue) ||
                data.fullName?.toLowerCase().includes(searchValue) ||
                data.type?.toLowerCase().includes(searchValue)
            );
        }) || [];

        // Assign filtered extracted data back to item
        item.extracted_data = filteredExtractedData;

        // Return item with either matched extracted data or empty array
        return {
            ...item,
            extracted_data: filteredExtractedData.length > 0 ? filteredExtractedData : [],
        };
    });

    // Sort the list to ensure items with empty extracted_data come last
    return filteredList.sort((a, b) => {
        if (a.extracted_data.length === 0 && b.extracted_data.length > 0) {
            return 1; // a comes after b
        }
        if (a.extracted_data.length > 0 && b.extracted_data.length === 0) {
            return -1; // a comes before b
        }
        return 0; // keep original order for other cases
    });
  }

  changeTab(data){
    if(data.extracted_data.length)this.activeTab = data.fullName;
  }

  getcompletionIc(data){
    if(data=='In Progress'){
      return 'assets/icons/inprog.svg';
    }

    if(data=='Deployed'){
      return 'assets/icons/deployed.svg';
    }

    if(data=='Not Started'){
      return 'assets/icons/notstarted.svg';
    }
    
  }

  selectMetadata(extracted_data_element,metadata_object_element){
    this.editMeta = false;
    this.extractForm.get('metadata_id').setValue(this.findMetadataDetail._id);
    this.extractForm.get('metadata_object_id').setValue(metadata_object_element._id);
    this.extractForm.get('_id').setValue(extracted_data_element._id);
    this.extractForm.get('fullName').setValue(extracted_data_element.fullName);
    this.extractForm.get('description').setValue(extracted_data_element.description);
    this.extractForm.get('type').setValue(metadata_object_element.fullName);
    this.extractForm.get('category').setValue(metadata_object_element.category);
    this.extractForm.get('sub_category').setValue(metadata_object_element.subcategory);
    this.extractForm.get('xmlCode').setValue('');
    this.showText = extracted_data_element.fullName.includes('.css')?'CSS':(extracted_data_element.fullName.includes('.html')?'HTML':(extracted_data_element.fullName.includes('.js')?'JS':(extracted_data_element.fullName.includes('.svg')?'SVG':'XML')));
    this.selectedMetadata = this.extractForm.value;
    this.viewDrawer.open();
  }

  onDelete(){
    let selectedMetadata = this.selectedMetadata;
    this.viewDrawer.close();
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {
        message:"Are you sure you want to delete this extracted object detail?",
        title:"Confirm Delete",
        buttonTitle:"Delete"
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.yes===true){
        this.proposalService.extractDelete(selectedMetadata).subscribe((response)=>{
          if(response.success){
            this.eventService.emitEventMetadata({type:'refresh'});
            this.clear();
          }
        })
      }
    });
  }

  onEditSave(){
    this.edit_extract = true;
    this.proposalService.extractUpdate(this.extractForm.value).subscribe((response)=>{
      if(response.success){
        this.edit_extract = false;
        this.extractSaved = true;
        this.eventService.emitEventMetadata({type:'refresh'});
        setTimeout(() => {
          this.extractSaved = false;
          this.clear();
        }, 1000);
      }
      else{
        this.edit_extract = false;
      }
    });
  }

  displayXML(){
    this.loader_xml = true;
    this.proposalService.metadata_xml(this.extractForm.get('metadata_id').value,this.extractForm.get('metadata_object_id').value,this.extractForm.get('_id').value).subscribe((response)=>{
      if(response.success){
        this.extractForm.get('xmlCode').setValue(response.response);
        this.selectedMetadata['xmlCode']=response.response;
      }
      else {
        this.extractForm.get('xmlCode').setValue('');
        this.selectedMetadata['xmlCode']='';
      }
      this.loader_xml = false;
      this.cdr.detectChanges();
    });
  }

  async copyMessage() {
    let userPrompt=this.extractForm.get('xmlCode').value;
    let text_value = userPrompt;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text_value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alert_xml = true;
    setTimeout(()=>{
      this.alert_xml = false;
    },1000);
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  clear(){
    this.selectedMetadata = {};
    this.showText = '';
    this.editMeta = false;
    this.viewDrawer.close();
  }

}
