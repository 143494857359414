import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroup, FormControl, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { finalize } from 'rxjs';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/core/common/analytics_service';
import analytics_event from 'app/core/common/analytics_event';

@Component({
  selector: 'app-share',
  standalone: true,
  imports  : [MatIconModule, FormsModule,FuseAlertComponent, CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, MatDialogModule,MatProgressBarModule,ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule, MatProgressSpinnerModule],
  templateUrl: './share.component.html',
  styleUrl: './share.component.scss'
})

export class ShareComponent {

    @ViewChild('shareNgForm') shareNgForm: NgForm;
    shareForm: UntypedFormGroup;
    proposal_id: string;
    _page_id: string;
    loader_invite = false;
    loader_getmember = false;
    members: any[];
    roles: any[];
    user:any;
    showAlert: boolean = false;
    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: '',
    };

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _userService: UserService,
        private _authService: AuthService,
        private _proposalService: ProposalService,
        private _analyticsService:AnalyticsService,
        private _fuseConfirmationService: FuseConfirmationService,
        @Inject(MAT_DIALOG_DATA) public data: {proposal_data: object}
    ){
        this.proposal_id = this.data['proposal_id'];
        this._page_id = this.data['_page_id'];
        this.getMembers();
    }

    ngOnInit(): void
    {
        this.shareForm = this._formBuilder.group({
            email     : ['', [Validators.required, Validators.email]]
        });

        // Setup the roles
        this.roles = [
            {
                label      : 'View',
                value      : 'view',
                description: 'Can view and download the '+ this._page_id +'.',
                active: true
            },
            {
                label      : 'Write',
                value      : 'write',
                description: 'Can view, edit, and download the '+ this._page_id +'.',
                active: true
            },
            {
                label      : 'Admin (Coming Soon)',
                value      : 'admin',
                description: 'Can view, edit, share, and download the '+ this._page_id +'.',
                active: false
            },
        ];

        this._userService.user$.subscribe((user)=>{
            this.user=user;
        });
    }

    getMembers(): void
    {
        this.loader_getmember = true;
        this.members = [];
        // Return if the form is invalid
        if ( !this.proposal_id )
        {
            this.loader_getmember = false;
            return;
        }
        
  
        // Send the request to the server
        this._proposalService.listMembers(this.proposal_id,this._page_id)
            .pipe(
                finalize(() =>
                {
                }),
            )
            .subscribe(
                (response) =>
                {
                    if(response.permissions && response.permissions.length){
                        let activePermissions = response.permissions.filter(el => (!el.delete && !el.deactivate));
                        if(activePermissions.length){
                            activePermissions.forEach(element => {
                                if(element.user_id._id!=this.user._id)this.members.push({user_id: element.user_id._id,name: element.user_id.personal.name.first, email: element.user_id.contact.email, status: element.status, avatar: (element.user_id.meta && element.user_id.meta.profileImage?this.getImageURL(element.user_id.meta.profileImage):''),role: (element.read?'view':(element.write?'write':(element.admin?'admin':'')))})
                            });
                        }
                    }
                    this.loader_getmember = false;
                },
                (error) =>
                {
                    this.loader_getmember = false;
                },
            );
    }

    invite(): void
    {
        if ( this.shareForm.invalid )
        {
            this.loader_invite = false;
            return;
        }
        else if(this.members.find(el => el.email.toLowerCase()==this.shareForm.get('email').value.toLowerCase())){
            this.loader_invite = false;
            // Set the alert
            this.alert = {
                type   : 'error',
                message: this._page_id +' is already shared with the user.'
            };
            // Show the alert
            this.showAlert = true;

            setTimeout(() => {
                // Hide the alert
                this.showAlert = false;
            }, 1000);

            // Re-enable the form
            this.shareForm.enable();

            // Reset the form
            this.shareNgForm.resetForm();

          
            return;
        }
        else {
            // Disable the form
            this.shareForm.disable();
    
            // Hide the alert
            this.showAlert = false;
    
            this.loader_invite = true;
    
            this._proposalService.inviteUser({proposal:this.proposal_id,email:this.shareForm.get('email').value.toLowerCase(),category:this._page_id})
                .subscribe(
                    (response) =>
                    {
                        // Navigate to the confirmation required page
                        if(response.status){
                            // Set the alert
                            this.alert = {
                                type   : 'success',
                                message: 'Invite sent successfully.',
                            };
    
                            // Re-enable the form
                            this.shareForm.enable();
    
                            // Reset the form
                            this.shareNgForm.resetForm();
    
                            this.showAlert = true;
                            
                            setTimeout(() => {
                                // Hide the alert
                                this.showAlert = false;
                            }, 1000);
                            this.getMembers();
                            this._analyticsService.trackEvent(analytics_event.PROJECT_SHARE,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});

                        }
                        else{
                            // Re-enable the form
                            this.shareForm.enable();
    
                            // Reset the form
                            this.shareNgForm.resetForm();
    
                            // Set the alert
                            this.alert = {
                                type   : 'error',
                                message: 'Something went wrong, please try again.',
                            };
    
                            // Show the alert
                            this.showAlert = true;
    
                            setTimeout(() => {
                                // Hide the alert
                                this.showAlert = false;
                            }, 1000);
                        }
                        this.loader_invite = false;
                    },
                    (response) =>
                    {
    
                        this.loader_invite = false;
                        // Set the alert
                        this.alert = {
                            type   : 'error',
                            message: (response.error && response.error.message)?response.error.message.message:'Something went wrong, please try again.',
                        };
                        // Show the alert
                        this.showAlert = true;
    
                        setTimeout(() => {
                            // Hide the alert
                            this.showAlert = false;
                        }, 1000);
    
                        // Re-enable the form
                        this.shareForm.enable();
    
                        // Reset the form
                        this.shareNgForm.resetForm();
    
                    }
                );
        }
    }

    invitePlan(): void
    {
        if ( this.shareForm.invalid )
        {
            this.loader_invite = false;
            return;
        }
        else if(this.members.find(el => el.email.toLowerCase()==this.shareForm.get('email').value.toLowerCase())){
            this.loader_invite = false;
            // Set the alert
            this.alert = {
                type   : 'error',
                message: this._page_id +' is already shared with the user.'
            };
            // Show the alert
            this.showAlert = true;

            setTimeout(() => {
                // Hide the alert
                this.showAlert = false;
            }, 1000);

            // Re-enable the form
            this.shareForm.enable();

            // Reset the form
            this.shareNgForm.resetForm();

          
            return;
        }
        else {
            // Disable the form
            this.shareForm.disable();
    
            // Hide the alert
            this.showAlert = false;
    
            this.loader_invite = true;
    
            this._proposalService.inviteUserTP({plan:this.proposal_id,email:this.shareForm.get('email').value.toLowerCase(),category:this._page_id})
                .subscribe(
                    (response) =>
                    {
                        // Navigate to the confirmation required page
                        if(response.status){
                            // Set the alert
                            this.alert = {
                                type   : 'success',
                                message: 'Invite sent successfully.',
                            };
    
                            // Re-enable the form
                            this.shareForm.enable();
    
                            // Reset the form
                            this.shareNgForm.resetForm();
    
                            this.showAlert = true;
                            
                            setTimeout(() => {
                                // Hide the alert
                                this.showAlert = false;
                            }, 1000);
                            this.getMembers();
                            this._analyticsService.trackEvent(analytics_event.PROJECT_SHARE,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});

                        }
                        else{
                            // Re-enable the form
                            this.shareForm.enable();
    
                            // Reset the form
                            this.shareNgForm.resetForm();
    
                            // Set the alert
                            this.alert = {
                                type   : 'error',
                                message: 'Something went wrong, please try again.',
                            };
    
                            // Show the alert
                            this.showAlert = true;
    
                            setTimeout(() => {
                                // Hide the alert
                                this.showAlert = false;
                            }, 1000);
                        }
                        this.loader_invite = false;
                    },
                    (response) =>
                    {
    
                        this.loader_invite = false;
                        // Set the alert
                        this.alert = {
                            type   : 'error',
                            message: (response.error && response.error.message)?response.error.message.message:'Something went wrong, please try again.',
                        };
                        // Show the alert
                        this.showAlert = true;
    
                        setTimeout(() => {
                            // Hide the alert
                            this.showAlert = false;
                        }, 1000);
    
                        // Re-enable the form
                        this.shareForm.enable();
    
                        // Reset the form
                        this.shareNgForm.resetForm();
    
                    }
                );
        }
    }

    deleteMember(user_id): void
    {
        // Open the confirmation dialog
        const dialogRef = this._fuseConfirmationService.open({
            title  : 'Remove Access',
            message: 'Are you sure you want to remove this user access to the '+ this._page_id +'?',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            if(result=='confirmed'){
                this.updatePermission(user_id,'delete');
            }
        });
    }

    updatePermission(user_id,type){
        // console.log(user_id, type);
        let user_permission = {id:this.proposal_id,user_id:user_id,category:this._page_id};
        if(type=='delete'){
            user_permission['write']= false;
            user_permission['read']= false;
            user_permission['admin']= false;
            user_permission['delete']= true;
            user_permission['deactivate']= false;
        }
        else if(type=='view'){
            user_permission['write']= false;
            user_permission['read']= true;
            user_permission['admin']= false;
            user_permission['delete']= false;
            user_permission['deactivate']= false;
        }
        else if(type=='edit' || type=='write'){
            user_permission['write']= true;
            user_permission['read']= false;
            user_permission['admin']= false;
            user_permission['delete']= false;
            user_permission['deactivate']= false;
        }
        else if(type=='admin'){
            user_permission['write']= false;
            user_permission['read']= false;
            user_permission['admin']= true;
            user_permission['delete']= false;
            user_permission['deactivate']= false;
        }
        // console.log(user_permission);return;
        this._proposalService.updatePermission(user_permission)
        .subscribe(
            (response) =>
            {
                // Navigate to the confirmation required page
                if(response.status)this.getMembers();
            },
            (response) =>
            {

            }
        );
    }

    getImageURL(url){
        return environment.file_url_cf+ url + '?token='+this._authService.accessToken
    }

}
