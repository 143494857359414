
<div class="flex flex-col flex-auto min-w-0 relative popup-content overflow-visible">
    <span class="close-dialog" (click)="closeRef()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>

    <div class="chatBox">
        <div class="boxHead" [ngClass]="{'justify-end':(data.type)}">
            @if(!data.type){
            <div class="iconb2 flex items-center gap-3">
                
                <div class="relative inline">                     
                    <mat-icon class="icon-size-12" matPrefix svgIcon="heroicons_outline:document"></mat-icon>
                    <div class="typeText absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white" [ngClass]="getFileClass(data.document['type'])"> {{getFileTypeString(data.document['type'])}} </div>
                </div>
                <h5>{{data.document['title']}}</h5>
            </div>
        }
           
            <div class="docTab ">
                <span [ngClass]="{'active': tabActive == 0 }" (click)="tabActivefunc(0)"> Chat </span>
                <span [ngClass]="{'active': tabActive == 1 }" (click)="tabActivefunc(1)"> Insights </span>
                <span [ngClass]="{'active': tabActive == 2 }" (click)="tabActivefunc(2)"> History </span>
            </div>
        </div>
        <div class="chatScreen" *ngIf="tabActive==0" >
            <div class="loader text-center mt-6"  *ngIf="load_chat_history">
                <mat-progress-spinner *ngIf="load_chat_history" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <div class="defaultScreen" *ngIf="(!chat.messages.length && !load_chat_history)">
                <h3>Hi there, {{user.personal.name.first}} <br> {{data.type=='metadata'?'Get insights from your metadata':'What would you like to know?'}}</h3>
                <p>Use one of the most common prompts below  or use your own to begin</p>
                <div *ngIf="data.type!='metadata'" class="cardList mt-6 flex gap-6">
                    <div class="card border rounded-2xl" *ngFor="let eachPrompt of defaultPrompts;let i = index;" (click)="onPromptSend(i)">
                        <h4>{{eachPrompt.message}}</h4>
                        <mat-icon [svgIcon]="eachPrompt.icon"></mat-icon>
                    </div>
                </div>
                <div *ngIf="data.type=='metadata'" class="cardList mt-6 flex gap-6">
                    <div class="card border rounded-2xl" *ngFor="let eachPrompt of defaultPromptsMetadata;let i = index;" (click)="onPromptSend(i)">
                        <h4>{{eachPrompt.message}}</h4>
                        <mat-icon [svgIcon]="eachPrompt.icon"></mat-icon>
                    </div>
                </div>
            </div>

            <div class="chatData" *ngIf="chat.messages.length"  #scrollableDiv>
                <ng-container *ngFor="let message of chat.messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                   
                        <div class="flex flex-col"
                            [ngClass]="{'items-end': message.isMine,
                                        'items-start  ': !message.isMine,
                                        'mt-0.5': i > 0 && chat.messages[i - 1].isMine === message.isMine,
                                        'mt-3': i > 0 && chat.messages[i - 1].isMine !== message.isMine}">
                            
                            <div
                                class="relative max-w-3/4 rounded-lg"
                                [ngClass]="{'shadow responeMsg': !message.isMine}">
                                <ng-container *ngIf="last || chat.messages[i + 1].isMine !== message.isMine">
                                    <div
                                        class="absolute bottom-0 w-3"
                                        [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !message.isMine}">
                                        <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                    </div>
                                </ng-container>
                                <div *ngIf="(!message.value)" class="min-w-4 leading-5 p-2 flex items-center gap-1"> 
                                    <mat-progress-spinner   [diameter]="14" [mode]="'indeterminate'"></mat-progress-spinner>    
                                    Analyzing document and fetching answers.
                                </div>
                                <div *ngIf="(message.type=='question' && message.value)"
                                    class="min-w-4 leading-5 p-2 messagebox"
                                    [innerHTML]="message.value">
                                </div>

                                <div *ngIf="(message.type=='answer' && message.value)">
                                    <tiptap-editor #messageanswer [id]="'messageanswer-'+i" [editor]="message.editor" >
                                    </tiptap-editor>
                                </div>

                                <ng-container *ngIf="first || last || chat.messages[i + 1].isMine !== message.isMine
                                || chat.messages[i + 1].createdAt !== message.createdAt">
                                    <!-- <div
                                        class="my-0.5 text-sm font-medium text-secondary"
                                        [ngClass]="{'mr-3': message.isMine,
                                                    'ml-3': !message.isMine}">
                                        {{message.createdAt | date:'HH:mm'}}
                                    </div> -->
                                    <span class="block border-b" *ngIf="(!message.isMine && message.value && !message.isStreaming)" ></span>
                                    <div *ngIf="(!message.isMine && message.value && !message.isStreaming)" class="inline-flex gap-2 p-2">
                                        <button (click)="copyMessage(i)" class="viewBt2">
                                            <mat-icon svgIcon="heroicons_outline:document-duplicate"></mat-icon>
                                            <span>Copy</span>
                                        </button>
                                        <button *ngIf="(data['accessStatus']!='view' && (data.type!='metadata' && capabilities_themes.length) || (data['accessStatus']!='view' && data.type=='metadata' && category_types.length))" (click)="addInsight(i)" class="viewBt2" [disabled]="message.loader_add">
                                            <mat-icon *ngIf="!message.loader_add" svgIcon="heroicons_outline:document-arrow-down"></mat-icon>
                                            <mat-progress-spinner *ngIf="message.loader_add" [diameter]="12" [mode]="'indeterminate'"></mat-progress-spinner>
                                            <span>Add to Insights</span>
                                        </button>
                                        <button (click)="regenerate(i)" class="viewBt2" [disabled]="(isAIProcessing)">
                                            <mat-icon svgIcon="heroicons_outline:arrow-path-rounded-square"></mat-icon>
                                            <span>Regenerate Response</span>
                                        </button>
                                    </div>
                                </ng-container>

                            </div>
                            <div class="alertBox" *ngIf="message.copied">
                                <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-duplicate"></mat-icon> 
                                <span> Copied to clipboard!</span>
                           </div>
                           <div class="alertBox" *ngIf="message.added">
                                <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-arrow-down"></mat-icon> 
                                <span> Added to insights</span>
                            </div>
                            
                           
                        </div>
                </ng-container>
            </div>
        </div>

        <div class="inputChat" *ngIf="tabActive==0">
            <textarea name="" id="" class="border shadow" placeholder="Type your question" [disabled]="isAIProcessing" #messageInput (keydown.enter)="(isSendButtonDisabled || isAIProcessing)?'':onEnter($event)"></textarea>
            @if(isSendButtonDisabled && isAIProcessing){
                <span class="subChat" [ngClass]="{'disableSend': typingSubscription==null}"  (click)="onStopAI()">
                    <img src="assets/icons/stop_chat.png" alt="">
                </span>
            }@else{
                <span class="subChat"  [ngClass]="{'disableSend': (isSendButtonDisabled || isAIProcessing)}" (click)="(isSendButtonDisabled || isAIProcessing)?'':onSendMessage()">
                    <img src="assets/icons/chatsend.svg" alt="">
                </span>
            }
            

        </div>


        <div class="insightTab" *ngIf="tabActive==1">
            <div class="loader text-center mt-6"  *ngIf="load_insight">
                <mat-progress-spinner *ngIf="load_insight" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <ng-container *ngIf="(insights.length && !load_insight)">
                <div class="queansTab" *ngFor="let eachinsight of insights; let i = index;">
                    <div class="quehead">
                        <h6 class="font-bold ">Question</h6>
                        <span class="text-gray-400 ">{{format(eachinsight.created,'comma')}}, {{eachinsight.author.personal.name.first}} {{eachinsight.author.personal.name.last}}</span>
                    </div>
                    <h3>{{eachinsight.title}}</h3>
                    <div class="anshead">
                        <h6 class="font-bold">Answer</h6>
                        <!-- <p [innerHTML] = "eachinsight.notes"></p> -->
                        <div class="border mt-2 mb-2 rounded-lg">
                            <tiptap-editor #eachinsightanswer [id]="'eachinsight-'+i" [editor]="eachinsight.editor" >
                            </tiptap-editor>
                        </div>
                    </div>
                    <div class="actionins flex justify-between pr-7 pt-4">
                       <div class="flex gap-2">
                        <button class="border flex items-center p-2 rounded text-xs viewBt2" (click)="copyInsight(i)" > <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-duplicate"></mat-icon> Copy</button>
                        <button *ngIf="(data['accessStatus']!='view')" class="border flex items-center p-2 rounded text-xs viewBt2" [disabled]="eachinsight.loader_delete" (click)="onDeleteInsightQuestion(i)"> 
                            <mat-progress-spinner *ngIf="eachinsight.loader_delete" [diameter]="12" [mode]="'indeterminate'"></mat-progress-spinner>
                            <mat-icon *ngIf="!eachinsight.loader_delete" class="icon-size-3" svgIcon="heroicons_outline:trash"></mat-icon> 
                            Delete
                        </button>
                        
                       </div>

                        <div class="flex flex-wrap items-center -m-1.5">
                            <!-- Tags -->
                            <!-- <ng-container *ngIf="eachinsight.capability">
                                <ng-container>
                                    <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700" (click)="openTagsPanel()">
                                        <span class="text-md font-medium whitespace-nowrap">{{eachinsight.capability}}</span>
                                    </div>
                                </ng-container>
                            </ng-container> -->

                            <div *ngIf="(data['accessStatus']!='view' && data.type!='metadata')" class="selectTheme flex items-center gap-2">
                                <mat-progress-spinner *ngIf="eachinsight.loader_theme" [diameter]="12" [mode]="'indeterminate'"></mat-progress-spinner>
                                <mat-select class="selectOption" [(value)]="eachinsight.capability" (selectionChange)="toggleTaskTag($event, eachinsight,i)">
                                    <mat-option *ngIf="!eachinsight.capability" selected>Select Theme </mat-option>
                                    <mat-option *ngFor="let eachtag of filteredTags" [value]="eachtag.label">{{ eachtag.label }} </mat-option>
                                </mat-select>
                            </div>
                            <div *ngIf="(data['accessStatus']=='view' && data.type!='metadata')" class="selectTheme selectOption">
                                {{eachinsight.capability}}
                            </div>


                            <!-- <div
                                class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                                (click)="openTagsPanel()"
                                #tagsPanelOrigin>
                                <ng-container *ngIf="!eachinsight.capability">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Select Theme</span>
                                </ng-container>
                                <ng-template #tagsPanel>
                                    <div class="w-60 rounded border shadow-md bg-card">
                                        <div class="flex items-center m-3 mr-2">
                                            <div class="flex items-center">
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                <div class="ml-2">
                                                    <input
                                                        class="w-full min-w-0 py-1 border-0"
                                                        type="text"
                                                        placeholder="Search Theme"
                                                        (input)="filterTags($event)"
                                                        (keydown)="filterTagsInputKeyDown($event)"
                                                        [maxLength]="30"
                                                        #newTagInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                            <ng-container *ngIf="!tagsEditMode">
                                                <ng-container *ngFor="let eachtag of filteredTags; trackBy: trackByFn">
                                                    <div
                                                        class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                        (click)="toggleTaskTag(eachtag,eachinsight,i)"
                                                        matRipple>
                                                        <mat-checkbox
                                                            class="flex items-center h-10 min-h-10 pointer-events-none"
                                                            [checked]="eachinsight.capability == eachtag.label"
                                                            [color]="'primary'"
                                                            [disableRipple]="true">
                                                        </mat-checkbox>
                                                        <div class="ml-1">{{eachtag.label}}</div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-template>
                            </div> -->
                        </div>
                    </div>
                   <div class="alertBox" *ngIf="eachinsight.copied">
                        <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-duplicate"></mat-icon> 
                        <span> Copied to clipboard!</span>
                   </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(!insights.length && !load_insight)">
                <div class="notfound">
                    <mat-icon svgIcon="heroicons_outline:document-minus"></mat-icon>
                    <p>No insights are available yet. Start by asking questions in the chat to analyze the document, and save key findings directly from the chat to display them here.</p>
                </div>
            </ng-container>
        </div>


        <div class="historyTab" *ngIf="tabActive ==2">

            <div class="loader text-center mt-6"  *ngIf="load_history">
                <mat-progress-spinner *ngIf="load_history" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <ng-container *ngIf="(chat_history.length && !load_history)">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="text-left" style="padding-left: 40px;">Chat History </th>
                            <th>Insights Count</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let eachchat of chat_history; let i = index;">
                            <td class="text-left" style="padding-left: 40px;">{{formatDate(eachchat._id.date)}}, {{eachchat.authorDetails?eachchat.authorDetails.personal.name.first:''}} {{eachchat.authorDetails?eachchat.authorDetails.personal.name.last:''}}</td>
                            <td class="text-center">{{eachchat.totalInsights}}</td>
                            <td class="text-right">
                                <button class="viewBt" (click)="displaySelectedHistory(eachchat._id.date)">
                                    <mat-icon svgIcon="heroicons_outline:clock"></mat-icon>
                                    <span>View History</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <ng-container *ngIf="(!chat_history.length && !load_history)">
                <div class="notfound">
                    <mat-icon svgIcon="heroicons_outline:document-minus"></mat-icon>
                    <p>There is no conversation history to display at the moment. Begin a new conversation, and your history will appear here for future reference</p>
                </div>
            </ng-container>
        </div>


    </div>

</div>