export default {
    SIGN_UP:"sign_up",
    SIGN_IN:"sign_in",
    LOGOUT:"logout",
    DOWNLOAD_PROPOSAL:"download_proposal",
    START_NEW_PROPOSAL:"start_new_proposal",
    PROPOSAL_COMPLETED:"proposal_completed",
    CONTACT_ENTERPRISE:"contact_enterprise",
    CANCEL_SUBSCRIPTION:"cancel_subscription",
    SUBSCRIBE_MONTHLY_PLAN:"subscribe_monthly_plan",
    SUBSCRIBE_YEARLY_PLAN:"subscribe_yearly_plan",
    UPGRADE_TO_YEARLY_PLAN:"upgrade_to_yearly_plan",
    UPGRADE_TO_MONTHLY_PLAN:"upgrade_to_monthly_plan",
    PAYMENT_FAILED:"payment_failed",

    START_NEW_PROJECT_WITH_AI:"startNewProjectWithAI",
    CREATE_NEW_PROJECT:"createNewProject",
    CREATE_PROJECT_FROM_PROPOSAL:"createProjectFromProposal",

    PROPOSAL_SETTINGS:"proposalSettings",
    /*
    CREATE_NEW_PROJECT_TECHNOLOGY_NAME:"",
    CREATE_NEW_PROJECT_TECHNOLOGY_PRODUCT:"",
    CREATE_NEW_PROJECT_INDUSTRY:"",
    CREATE_NEW_PROJECT_VOICE_RECORDING:"",
    CREATE_NEW_PROJECT_REWRITE_AI:"",
    CREATE_NEW_PROJECT_THEME_NAME:"",
    CREATE_NEW_PROJECT_BUSINESS_OUTCOMES:"",
    CREATE_NEW_PROJECT_PROJECT_NAME:"",
    */
    VIEW_PROJECT:"viewProject",
    VIEW_TEST_PLAN:"viewTestPlan",
    CONNECT_PROJECT_JIRA:"connectProjectJira",
    CONNECT_PROPOSAL_JIRA:"connectProposalJira",
    /*
    PROJECT_WORKSPACE_ADD_THEME:"",
    PROJECT_WORKSPACE_ADD_EPIC:"",
    PROJECT_REQUIREMENTS_ADD_THEME:"",
    PROJECT_REQUIREMENTS_ADD_EPIC:"",
    PROJECT_REQUIREMENTS_ADD_REQUIREMENT:"",
    PROJECT_USER_STORY_ADD_RELEASE:"",
    PROJECT_USER_STORY_ADD_STORY:"",
    PROJECT_SOLUTION_ADD_NEW_SOLUTION:"",
    */

    PROJECT_SHARE:"shareProject",
    PROJECT_SETTINGS:"projectSettings",
    PROJECT_DOCUMENT_AI_FILE_UPLOAD:"projectDocumentAIUploadFile",
    NEW_TEST_PLAN_WITH_AI:"newTestPlanWithAI",
    CREATE_NEW_TEST_PLAN:"createNewTestPlan",
    CREATE_TEST_PLAN_FROM_PROJECT:"createTestPlanFromProject",

    TEST_PLAN_CONNECT_JIRA:"testPlanConnectJira",
    TEST_PLAN_SETTINGS:"testPlanSettings",
    PRODUCT_TOUR:"productTour",
    BUILD_REQUEST_EARLY_ACCESS:"buildRequestEarlyAccess",
    PRICING:"pricing",
    FREE_PROPOSAL_REVIEW:"freeProposalReview"

}