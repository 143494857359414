
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, ElementRef, TemplateRef, ViewContainerRef, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { PricingPlanService } from 'app/core/pricing-plan/pricing-plan.service';
import { User } from 'app/core/user/user.types';
import { catchError, combineLatest, finalize, forkJoin, of, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [NgClass,RouterLink, MatIconModule, MatProgressSpinnerModule, FormsModule,  NgIf, ReactiveFormsModule,  MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,NgFor,AsyncPipe,MatStepperModule],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  @ViewChild('horizontalStepper') private horizontalStepper: MatStepperModule;
  @ViewChild(MatStepper) private stepper: MatStepper;

  horizontalStepperForm: UntypedFormGroup;
  startStepper=false;
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  loader_save: boolean = false;
  selectedIndex = 0;
  fullScreenClick = false;
  constructor(
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _userService: UserService,
    private _pricingPlanService :PricingPlanService,
    private cdr: ChangeDetectorRef,
  ){

  }


  ngOnInit(): void
  {
      
      // Horizontal stepper form
      this.horizontalStepperForm = this._formBuilder.group({
          step0: [''],
          // step1: [''],
          step2: this._formBuilder.group({
            role: ['', Validators.required],
          }),
          step3: this._formBuilder.group({
            organizationType: ['', Validators.required],
          }),
          step4: this._formBuilder.group({
            organizationalLevel: ['', Validators.required],
          }),
          step5: this._formBuilder.group({
            experienceLevel: ['', Validators.required],
          }),
          step6: this._formBuilder.group({
            teamSize: ['', Validators.required],
          }),
          step7: this._formBuilder.group({
            linkedin: ['', [this.linkedinUrlValidator]],
          }),
          step8: [''],
          step9: [''],
          step10: [''],
      });

      // Subscribe to the user service
      this._userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: User) =>
      {
          this.user = user;
      });

  }

  linkedinUrlValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    const linkedInRegex = /^https?:\/\/(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9-_/]+$/i;
    const isValid = linkedInRegex.test(value);
    return isValid ? null : { invalidLinkedInUrl: 'Please enter a valid LinkedIn URL' };
  }

  updateProfile(): void
    {
      if (!this.horizontalStepperForm.valid) {
        return;
      }

      this.loader_save = true;
      // console.log(this.horizontalStepperForm.value);
      // Send the request to the server
      if(!this.user.organisation)this.user.organisation = {role:'',organizationType:'',organizationalLevel:'',experienceLevel:'',teamSize:''};
      this.user.organisation.role = this.horizontalStepperForm.get('step2.role').value;
      this.user.organisation.organizationType = this.horizontalStepperForm.get('step3.organizationType').value;
      this.user.organisation.organizationalLevel = this.horizontalStepperForm.get('step4.organizationalLevel').value;
      this.user.organisation.experienceLevel = this.horizontalStepperForm.get('step5.experienceLevel').value;
      this.user.organisation.teamSize = this.horizontalStepperForm.get('step6.teamSize').value;
      this.user.social.linkedin = this.horizontalStepperForm.get('step7.linkedin').value;
      this.user['email'] = this.user.contact.email;
      this._userService.update(this.user)
          .pipe(
              finalize(() =>
              {
              }),
          )
          .subscribe(
              (response) =>
              {
                if(response.success){
                  this.fetchData();
                }
                else this.loader_save = false;
              },
              (response) =>
              {
                  
              },
          );
  }


  onStepChange(event: StepperSelectionEvent): void {
    if (event.selectedIndex === 7) {
        setTimeout(() => {
            this.selectedIndex = 8;
            this.cdr.detectChanges();
        }, 500);
    }
  }

  fetchData(){
    this._pricingPlanService.fetchStatus().subscribe();
    combineLatest([
      this._userService.fetchAgentStats().pipe(catchError(error => of(null))),
      this._userService.get().pipe(catchError(error => of(null)))
    ]).subscribe(
        ([agentStats, userData]) => {
            this.loader_save = false;
            this._router.navigate(['/agents']);
        }
    );
  
    // forkJoin([
    //     this._userService.fetchAgentStats(),
    //     this._userService.get()
    //   ]).subscribe(
    //     ([agentStats, userData]) => {
    //       this.loader_save = false;
    //       this._router.navigate(['/agents']);
    //     },
    //     (error) => {
    //       this.loader_save = false;
    //       console.error('Error occurred:', error);
    //     }
    //   );
  }

}
