import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { UserService } from 'app/core/user/user.service';

@Component({
  selector: 'app-atlassian-callback',
  standalone: true,
  imports: [NgIf,MatProgressBarModule],
  templateUrl: './atlassian-callback.component.html',
  styleUrl: './atlassian-callback.component.scss'
})
export class AtlassianCallbackComponent {
  isSavingToken:boolean=true;
  success:boolean=false;
  constructor(private _router:Router,private activatedRoute:ActivatedRoute,private atlassianService:AtlassianService,private _userService:UserService){}

  ngOnInit(){
    this.isSavingToken=true;
    this.success=false;
    
    this.activatedRoute.queryParams.subscribe((params)=>{
      
      const accessToken=params.code || "";
      const proposalID=params.state;

      this.atlassianService.saveToken(accessToken,proposalID).subscribe((response)=>{
        console.log("Respnse:",response);
        this.isSavingToken=false;
        if(response.success){
          this.success=true;
          setTimeout(()=>{
            localStorage.setItem("jira_success","1");

            let type=localStorage.getItem("type") || "0";
            if(type && type=="2"){
              let planID=localStorage.getItem("planID");
              this._router.navigate(['/testing/' + planID]);
            }else{
              this._router.navigate(['/project/' + proposalID]);
            }

            
          },1000);
          
        }else{
          this.success=false;
        }
      });
    });
    

  }
}
