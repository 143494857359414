<div class="flex actBt flex-col gap-3 mb-3 ">
    <div class="bg-white mt-6 p-2 rounded searchBar flex justify-end gap-2"> 
      <form class="flex flex-wrap gap-2 justify-end" [formGroup]="headerForm" *ngIf="headerForm">                                                                                      
        <mat-form-field class="fuse-mat-dense searchTab min-w-60">
            <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            <input matInput [readonly]="fetch_meta_data_val" [placeholder]="'Search'" [autocomplete]="'off'" [formControlName]="'search'">
        </mat-form-field>
        <!-- <mat-form-field class="flex-auto gt-xs:pl-3  custom-select">
            <img src="assets/icons/metatype.svg" class="w-5" alt="">
            <mat-select [formControlName]="'selected_type'" [placeholder]="'Type'" (selectionChange)="filterCount()" class="ml-2">
              <mat-option>All</mat-option>
              @for (option of type_list; track option) {
                  <mat-option [value]="option.value">{{option.label}}</mat-option>
              }
            </mat-select>
        </mat-form-field> -->
      </form>
    </div>
</div>



<div class="flex actBt flex-col gap-3 mb-6 ">
    <div class="innerTab bg-white p-2 rounded searchBar flex flex-col justify-end gap-2">
        <!-- <div class="flex self-end sm:mt-0 mt-2  gap-1 sm:gap-5 sm:mr-2 mr-0 ">
            <div (click)="onChangeInsightType(2)" class="flex sm:text-md text-sm   sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 px-2 rounded-full" [ngStyle]="{'background': selectedInsightType==2?'#E2E8F0':'transparent','color':selectedInsightType==2?'#4F45E4':'#64748B'}"><img  *ngIf="selectedInsightType==2" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>All(40)</div></div>
            <div (click)="onChangeInsightType(1)" class="flex sm:text-md text-sm sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 px-2 rounded-full" [ngStyle]="{'background':selectedInsightType==1?'#E2E8F0':'transparent','color':selectedInsightType==1?'#4F45E4':'#64748B'}"><img  *ngIf="selectedInsightType==1" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>My Insights(3)</div></div>
            <div (click)="onChangeInsightType(0)" class="flex sm:text-md text-sm sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 px-2 rounded-full" [ngStyle]="{'background':selectedInsightType==0?'#E2E8F0':'transparent','color':selectedInsightType==0?'#4F45E4':'#64748B'}"><img *ngIf="selectedInsightType==0" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>Open(37)</div></div>
        </div> -->


        <div class="max-w-full flex flex-col mt-6">

            <div class="tabledata " >
                <div class="flex min-w-full justify-between  gap-6 py-5 px-5 tableth border-b">
                    <div class="text-md  text-left  firstcol">QUESTIONS</div>
                    <div class="text-md  text-left  midcol">INSIGHTS</div> 
                    <!-- <div class="text-md  text-left  firstcol">Type</div>   -->
                </div>
                <div *ngIf="(!insights.length && load_insight)" class="shimmer-effect" style="min-height: 250px;"></div>
                <section *ngIf="(insights.length && !load_insight)">
                  <div class="tablebody" >
                    <div  class="relative group   select-none  sm:mt-0 mt-3 " >
                      <div class="tableRow relative" *ngFor="let eachinsight of insights">
                        <div class="flex gap-6 justify-between group w-full items-center py-3 px-5  cursor-pointer tabletd border-b hover:bg-gray-100 dark:hover:bg-hover" (click)="(is_write_allowed?openEditDrawer(eachinsight):'')" >
                          <div class="text-md text-black text-left font-regular firstcol  " >
                            {{eachinsight.title}}
                          </div>
                          <div class="text-md text-black text-left font-regular mr-10 midcol" [innerHTML]="eachinsight.notes">
                          </div>
                          <!-- <div class="text-md text-black text-left font-regular firstcol " >
                            {{eachinsight.category}}
                          </div> -->
                        </div> 
                        <div *ngIf="is_write_allowed" class="flex justify-end absolute right-0 z-999 editBox">
                          <div class="gap-2 mr-2 flex">
                              <mat-icon  class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="openEditDrawer(eachinsight)" ></mat-icon>
                              <mat-icon  class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash" (click)="onDelete(eachinsight)" ></mat-icon>
                          </div>
                        </div>
                      </div> 


                    </div>                                 
                  </div>  
                </section>
              </div>
              <!-- no data found -->
              <div class="flex" *ngIf="(!insights.length && !load_insight)">
                <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                    <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="text-[#000000]"></mat-icon>
                    <h4 class="text-lg font-medium">No Insights Available </h4>
                    <p class="text-secondary font-medium">No insights have been created. Click on <span class="blueText"> Chat with AI</span> to create new discovery sessions</p>
                    <div class="chatBtSec">
                      <button [disabled]="!fetch_vector_val" (click)="openChatWithAI()" mat-flat-button  class="chatAiBt"> <img src="assets/icons/chatai.svg" alt=""> Chat With AI</button>
                  </div>
                </div>
              </div>  
        </div>


    </div>

</div>



  
<fuse-drawer
class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
fixed
[mode]="'over'"
[name]="'viewDrawer'"
[position]="'right'" 
#viewDrawer>

<div  class="flex flex-col w-full overflow-auto bg-card">
    <div class="flex justify-between items-center px-4 py-2" >
        <div class="">
          <span class="text-sm">Insights</span>
          <h4 class="text-lg font-medium">Edit Insights</h4>
        </div>
        <div class="actSide flex  items-center">
            <button class="ml-auto" mat-icon-button (click)="viewDrawer.close()">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <div class="formSec px-4 py-2 flex flex-col gap-4">
      <form class="flex flex-col flex-auto gap-2"  [formGroup]="insightForm">
        <div class="detailBox" >
          <div class="innerDetail mb-2">
            <mat-form-field class="flex-auto  w-full">
              <mat-label>Question </mat-label>
              <input matInput formControlName="title">
            </mat-form-field>
          </div>
          <div class="innerDetail mb-2">
            <h4 class="font-medium mb-1">Description</h4>
            <tiptap-bubble-menu [editor]="editor" id="summary1" >
              <app-editor-controls 
              [editor]="editor" 
              [boxWidth]="'360px'"
              [selectedText]="selectedText"
              (completeQuickAction)="onCompleteQuickAction($event)">
              </app-editor-controls>
            </tiptap-bubble-menu>
            <tiptap-editor [editor]="editor"
                [formControlName]="'notes'"
                (blur)="onTextareaBlur()"
                (mouseenter)="onMouseEnter()" 
                (mouseleave)="onMouseLeave()"
                (mouseup)="onTextSelect($event,'notes')"
                (select)="onSelectionChange($event,'notes')"
                (mousedown)="onMouseDown($event)"
                (keydown)="onSelectionChange($event,'notes')"
                id="editor-with-bubble-menu" class="summarybox min-h-60">
            </tiptap-editor>
            <!-- <textarea name="" id="" class="textArea"></textarea> -->
            <!-- <div class="smallDetail flex justify-end p-2 pb-6">
              <mat-select class="tagSelect">
                  <mat-option value="Dependancy" selected="selected">Dependancy</mat-option>
                  <mat-option value="Improvement">Improvement</mat-option>
                  <mat-option value="Optimization">Optimization</mat-option>
                  <mat-option value="Governance">Governance</mat-option>
                  <mat-option value="Enhancement">Enhancement</mat-option>
                  <mat-option value="Risk Mitigation">Risk Mitigation</mat-option>
                </mat-select>
            </div> -->
          </div>
          
          
          <div class="actionBt text-right">
            <button mat-flat-button [disabled]="edit_extract" [color]='"primary"' class="saveBt" (click)="onEditSave()"> 
              <mat-spinner *ngIf="edit_extract" class="matSpinner" [diameter]="18"></mat-spinner>
              Save 
            </button>
          </div>
          <fuse-alert *ngIf="extractSaved"
            [appearance]="'soft'"
            [showIcon]="false"
            type="success"
            [dismissible]="true" [dismissed]="false">
             Saved successfully!
          </fuse-alert>


        </div>
      </form>
    </div>

</div>
</fuse-drawer>