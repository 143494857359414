
<div class="w-full flex justify-end z-20 mt-1 absolute right-5">
    <button  (click)="onClose()">
        <mat-icon
        class="text-black"
        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>
</div>
    <div class="flex gap-2 mt-4" *ngIf="type == 'refresh'">
        <div class="flex">
            <span class="infoIc"><mat-icon svgIcon="heroicons_outline:information-circle" class="icon-size-7"></mat-icon></span>
        </div>
        <div class="max-w-80">                 
            <div class="font-medium text-2xl text-black">Confirm Refresh?</div>
            <div class="font-normal justify-center mt-1  text-md">Do you want to remove existing insights or retain them while refreshing with new metadata?</div>

            <div class="checkbox">
                <div class="flex gap-2 items-center my-2 option cursor-pointer" [ngClass]="{'checked': (checked == 'refresh_remove_insights')}" (click)="checkoption('refresh_remove_insights')">
                    <mat-icon svgIcon="heroicons_solid:check-circle" class="icon-size-5"></mat-icon>
                    <span>Remove Existing Insights</span>
                </div>
                <div class="flex gap-2 items-center my-2 option cursor-pointer" [ngClass]="{'checked': (checked == 'refresh_keep_insights')}" (click)="checkoption('refresh_keep_insights')">
                    <mat-icon svgIcon="heroicons_solid:check-circle" class="icon-size-5"></mat-icon>
                    <span>Keep Existing Insights</span>
                </div>
            </div>

            <div class="flex gap-2 w-full justify-end mt-8">
                <button (click)="onClose()" class="boldbtn text-black rounded-full py-2 w-16 text-sm font-normal">Cancel</button>
                <button (click)="onClickYes()" class="yesBt bg-primary text-white rounded-full w-16 text-sm font-normal flex items-center justify-center gap-1" [disabled]="(load_process || !checked)">
                    <mat-progress-spinner *ngIf="load_process" mode="indeterminate" [color]="'white'"  ></mat-progress-spinner>
                    Yes
                </button>
            </div>
        </div>
    </div>


    <div class="flex gap-2 mt-4" *ngIf="type == 'disconnect'">
        <div class="flex">
            <span class="infoIc danger"><mat-icon svgIcon="heroicons_outline:exclamation-triangle" class="icon-size-7"></mat-icon></span>
        </div>
        <div class="max-w-80">                 
            <div class="font-medium text-2xl text-black">Confirm Disconnection</div>
            <div class="font-normal justify-center mt-1  text-md">Do you want to keep the metadata and insights</div>

            <div class="checkbox">
                <div class="flex gap-2 items-center my-2 option cursor-pointer" [ngClass]="{'checked': (checked == 'disconnect_remove_none')}" (click)="checkoption('disconnect_remove_none')">
                    <mat-icon svgIcon="heroicons_solid:check-circle" class="icon-size-5"></mat-icon>
                    <span>Keep Metadata and Insights</span>
                </div>
                <div class="flex gap-2 items-center my-2 option cursor-pointer" [ngClass]="{'checked': (checked == 'disconnect_keep_insights')}" (click)="checkoption('disconnect_keep_insights')">
                    <mat-icon svgIcon="heroicons_solid:check-circle" class="icon-size-5"></mat-icon>
                    <span>Only Keep Insights</span>
                </div>
                <div class="flex gap-2 items-center my-2 option cursor-pointer" [ngClass]="{'checked': (checked == 'disconnect_remove_all')}" (click)="checkoption('disconnect_remove_all')">
                    <mat-icon svgIcon="heroicons_solid:check-circle" class="icon-size-5"></mat-icon>
                    <span>Remove Everything</span>
                </div>
            </div>

            <div class="flex gap-2 w-full justify-end mt-8">
                <button (click)="onClose()" class="boldbtn text-black rounded-full py-2 w-16 text-sm font-normal">Cancel</button>
                <button (click)="onClickYes()" class="yesBt bg-[#CA3A31] text-white rounded-full w-16 text-sm font-normal flex justify-center items-center gap-1" [disabled]="(load_process || !checked)">
                    <mat-progress-spinner *ngIf="load_process" mode="indeterminate" [color]="'white'"  ></mat-progress-spinner>
                    Yes
                </button>
            </div>
        </div>
    </div>