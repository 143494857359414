import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { IPricingPlan, IPagination } from './pricing-plan.types';
import { environment } from 'environments/environment';

@Injectable({providedIn: 'root'})
export class PricingPlanService
{
    
    private _httpClient = inject(HttpClient);
    private _pricingPlan: ReplaySubject<IPricingPlan[]> = new ReplaySubject<IPricingPlan[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
    private _payStatus: BehaviorSubject<null> = new BehaviorSubject(null);
    private _planDetails: BehaviorSubject<null> = new BehaviorSubject(null);
    public readonly payList$ = this._payStatus.asObservable();
    public readonly planDetails$ = this._planDetails.asObservable();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for pricing plan
     *
     * @param value
     */
    set pricingPlan(value: IPricingPlan[])
    {
        // Store the value
        this._pricingPlan.next(value);
    }

    get pricingPlan$(): Observable<IPricingPlan[]>
    {
        return this._pricingPlan.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

     /**
     * Getter for status
     */
     get payStatus$(): Observable<any>
     {
         return this._payStatus.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
    get(): Observable<IPricingPlan[]>
    {

        return this._httpClient.get(environment.api_endpoint + 'pay', { headers: { method: 'subscription_plans' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {   console.log(response);
                if(response.data){

                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: 0,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._pricingPlan.next(response.data);

                        return this._pricingPlan.asObservable();


                }else{
                    return of([]);
                }
            }),
        );

    }

    getId(id): Observable<any>
    {

        return this._httpClient.get(environment.api_endpoint + 'pay?id='+id, { headers: { method: 'subscription_plans' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );

    }

    save(plan:IPricingPlan): Observable<any>
    {

        return this._httpClient.post(environment.api_endpoint + 'pay',JSON.stringify(plan), { headers: { method: 'save_subscription_plan' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );

    }

    create(plan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'pay/verify',JSON.stringify(plan), { headers: { method: 'create_subscription_link' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    cancel(plan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'pay/verify',JSON.stringify(plan), { headers: { method: 'cancel_subscription' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    verify(plan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'pay',JSON.stringify(plan), { headers: { method: 'verify_status' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    history(): Observable<any[]>
    {
        return this._httpClient.get(environment.api_endpoint + 'pay', { headers: { method: 'subscription_history' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    fetchStatus(): Observable<any> {
        return this._httpClient.post(environment.api_endpoint + 'user/auth',JSON.stringify({}), { headers: { method: 'user_subscription_status' } })
            .pipe(
                tap((newPayment) => {
                    this._payStatus.next(newPayment);
                })
            );
    }
    
    getStatus(): Observable<any> {
        if (this._payStatus.value) {
            return this.payList$;
        } else {
            const fetchAllBatches = (): Observable<any> => {
                return this.fetchStatus().pipe(
                    switchMap((newPayment) => {
                        if (newPayment) 
                            return this.payList$;
                    })
                );
            };
            return fetchAllBatches();
        }
    }

    clearCacheAndFetchStatus(): Observable<any> {
        this._payStatus.next(null);
    
        const fetchAllBatches = (): Observable<any> => {
            return this.fetchStatus().pipe(
                switchMap((newPayment) => {
                    if (newPayment) 
                        return this.payList$;
                })
            );
        };
        return fetchAllBatches();
    }

    clearFetchStatus() {
        this._payStatus.next(null);
    }

    fetchPlan(): Observable<any> {
        return this._httpClient.get(environment.api_endpoint + 'pay', { headers: { method: 'subscription_plans' } })
            .pipe(
                tap((newPayment) => {
                    this._planDetails.next(newPayment);
                })
            );
    }
    
    getPlan(): Observable<any> {
        if (this._planDetails.value) {
            return this.planDetails$;
        } else {
            const fetchAllBatches = (): Observable<any> => {
                return this.fetchPlan().pipe(
                    switchMap((newPayment) => {
                        if (newPayment) 
                            return this.planDetails$;
                    })
                );
            };
            return fetchAllBatches();
        }
    }

    clearCacheAndFetchPlan(): Observable<any> {
        this._planDetails.next(null);
    
        const fetchAllBatches = (): Observable<any> => {
            return this.fetchPlan().pipe(
                switchMap((newPayment) => {
                    if (newPayment) 
                        return this.planDetails$;
                })
            );
        };
        return fetchAllBatches();
    }

    clearFetchPlan() {
        this._planDetails.next(null);
    }

}
