import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, of, ReplaySubject, tap, takeUntil, Subject } from 'rxjs';
import { UserService } from '../user/user.service';
import { User } from 'app/core/user/user.types';

@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _httpClient = inject(HttpClient);
    private _userService=inject(UserService);
    private _defaultNavigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get defaultNavigation$(): Observable<Navigation>
    {
        return this._defaultNavigation.asObservable();
    }
    get navigation$(): Observable<Navigation>
    {
        return this._defaultNavigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        let navigation:Navigation={
            compact: [],
            default: [],
            futuristic: [],
            horizontal:[]
        }

        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) =>
            {
                this.user = user;
                navigation.default = [];
                if(this.user.is_system_admin){
                    navigation.default.push({
                        id   : 'dashboard',
                        title: 'Dashboard',
                        type : 'basic',
                        icon : 'heroicons_outline:home',
                        link : '/admin/dashboard'
                    });
                    navigation.default.push({
                        id   : 'knowledge-base',
                        title: 'Knowledge Base',
                        type : 'basic',
                        icon : 'heroicons_outline:book-open',
                        link : '/admin/knowledge-base'
                    });
                    navigation.default.push({
                        id   : 'master-features',
                        title: 'Master Features',
                        type : 'basic',
                        icon : 'heroicons_outline:star',
                        link : '/admin/master-features'
                    });
                    navigation.default.push({
                        id   : 'Resources',
                        title: 'Resources',
                        type : 'basic',
                        icon : 'heroicons_outline:square-3-stack-3d',
                        link : '/admin/resources'
                    });
                    navigation.default.push({
                        id   : 'phases',
                        title: 'Phases',
                        type : 'basic',
                        icon : 'heroicons_outline:square-2-stack',
                        link : '/admin/phases'
                    });
                    navigation.default.push({
                        id   : 'metadata',
                        title: 'Metadata',
                        type : 'basic',
                        link : '/admin/metadata',
                        imgicon : 'assets/icons/metadata-gray.svg',
                        hoverimgicon : 'assets/icons/metadata-white.svg',
                    });
                    navigation.default.push({
                        id   : 'technology-domains',
                        title: 'Technology Domains',
                        type : 'basic',
                        icon : 'heroicons_outline:cloud',
                        link : '/admin/technology-domains'
                    });
                    navigation.default.push({
                        id   : 'epics',
                        title: 'Epics',
                        type : 'basic',
                        icon : 'heroicons_outline:tag',
                        link : '/admin/epics'
                    });
                    navigation.default.push({
                        id   : 'nps',
                        title: 'Nps',
                        type : 'basic',
                        icon : 'heroicons_outline:chat-bubble-left-right',
                        link : '/admin/nps'
                    });
                    navigation.default.push({
                        id   : 'prompts',
                        title: 'Prompts',
                        type : 'basic',
                        icon : 'heroicons_outline:light-bulb',
                        link : '/admin/prompts'
                    });
                    navigation.default.push({
                        id   : 'pricing-plan',
                        title: 'Pricing Plans',
                        type : 'basic',
                        icon : 'heroicons_outline:banknotes',
                        link : '/admin/pricing-plan'
                    });
                    navigation.default.push({
                        id   : 'feedback',
                        title: 'Feedback',
                        type : 'basic',
                        icon : 'heroicons_outline:pencil-square',
                        link : '/admin/feedback'
                    });
                    navigation.default.push({
                        id   : 'suggestions',
                        title: 'Suggestions',
                        type : 'basic',
                        icon : 'heroicons_outline:newspaper',
                        link : '/admin/suggestions'
                    });
                    navigation.default.push({
                        id   : 'knowledge-center',
                        title: 'Playground Knowledge Center',
                        type : 'basic',
                        icon : 'heroicons_outline:newspaper',
                        link : '/admin/knowledge-center'
                    });
                    navigation.default.push({
                        id   : 'playground',
                        title: 'Playground',
                        type : 'basic',
                        icon : 'heroicons_outline:newspaper',
                        link : '/admin/playground'
                    });
                    navigation.default.push({
                        id   : 'settings',
                        title: 'Settings',
                        type : 'basic',
                        icon : 'heroicons_outline:cog-6-tooth',
                        link : '/admin/settings'
                    });
                   
                }
                else if(this.user.roles.indexOf('ad1')>-1){
                    navigation.default.push({
                        id   : 'dashboard',
                        title: 'Dashboard',
                        type : 'basic',
                        icon : 'heroicons_outline:home',
                        link : '/sme/dashboard'
                    });
                    navigation.default.push({
                        id   : 'knowledge-base',
                        title: 'Knowledge Base',
                        type : 'basic',
                        icon : 'heroicons_outline:book-open',
                        link : '/sme/knowledge-base'
                    });
                }
                else if(this.user.roles.indexOf('pr1')>-1) {
                   
                    navigation.default.push({
                        id   : 'agents',
                        title: 'Agents ',
                        type : 'basic',
                        // icon : 'heroicons_outline:rocket-launch',
                        link : '/agents',
                        imgicon : 'assets/icons/agents/agentnew-gray.svg',
                        hoverimgicon : 'assets/icons/agents/agentnew-white.svg',
                        // link : '/proposal/'+this.user.last_proposal_state
                    });
                    navigation.default.push({
                        id   : 'proposal',
                        title: 'Pre-Sales Agent ',
                        type : 'basic',
                        // icon : 'heroicons_outline:rocket-launch',
                        link : '/proposal',
                        imgicon : 'assets/icons/pre-sales-agent-gray.svg',
                        hoverimgicon : 'assets/icons/pre-sales-agent-white.svg',
                        // link : '/proposal/'+this.user.last_proposal_state
                    });
                    navigation.default.push({
                        id   : 'project',
                        title: 'Design Agent',
                        type : 'basic',
                        // icon : 'heroicons_outline:cube-transparent',
                        link : '/project',
                        imgicon : 'assets/icons/design-agent-gray.svg',
                        hoverimgicon : 'assets/icons/design-agent-white.svg',
                    });
                    navigation.default.push({
                        id   : 'testing-agent',
                        title: 'Test Agent',
                        type : 'basic',
                        // icon : 'heroicons_outline:beaker',
                        link : '/testing',
                       
                        imgicon : 'assets/icons/build-agent-gray.svg',
                        hoverimgicon : 'assets/icons/build-agent-white.svg',
                    });
                    navigation.default.push({
                        id   : 'build',
                        title: 'Build Agent',
                        type : 'basic',
                        // icon : 'heroicons_outline:code-bracket',
                        link : '/build',
                        imgicon : 'assets/icons/test-agent-gray.svg',
                        hoverimgicon : 'assets/icons/test-agent-white.svg',
                    });
                    navigation.default.push({
                        id   : 'talk_to_expert',
                        title: 'Book a Demo',
                        type : 'basic',
                        target:'_blank',
                        icon : 'heroicons_outline:chat-bubble-bottom-center-text',
                        link : 'https://calendly.com/amit-bwf4/30min',
                        externalLink: true
                    });
                    // navigation.default.push({
                    //     id   : 'team',
                    //     title: 'Team',
                    //     type : 'basic',
                    //     icon : 'heroicons_outline:user-group',
                    //     link : '/team',
                    //     externalLink: true
                    // });
                    // navigation.default.push({
                    //     id   : 'templates',
                    //     title: 'Proposal Templates',
                    //     type : 'basic',
                    //     icon : 'heroicons_outline:rectangle-stack',
                    //     link : '/template'
                    // });
                    // navigation.default.push({
                    //     id   : 'pricing',
                    //     title: 'Pricing',
                    //     type : 'basic',
                    //     icon : 'heroicons_outline:banknotes',
                    //     link : '/settings/pricing'
                    // });
                    navigation.default.push({
                        id   : 'settings',
                        title: 'Settings',
                        type : 'basic',
                        icon : 'heroicons_outline:cog-6-tooth',
                        link : '/settings'
                    });
                    navigation.default.push({
                        id   : 'refer',
                        title: 'Referral',
                        type : 'basic',
                        icon : 'heroicons_outline:megaphone',
                        link : '/refer'
                    });
                    // navigation.default.push({
                    //     id   : 'help-center',
                    //     title: 'Help Center',
                    //     type : 'basic',
                    //     icon : 'heroicons_outline:information-circle',
                    //     link : '/help-center'
                    // });
                    // navigation.default.push({
                    //     id   : 'company_info',
                    //     title: 'Company Information',
                    //     type : 'basic',
                    //     icon : 'heroicons_outline:building-office',
                    //     link : '/settings/company'
                    // });
                }
            });       

        // return of(navigation).pipe(
        //     tap((navigation) => {
        //         this._defaultNavigation.next(navigation);
        //     })
        // );
        return new Observable<Navigation>((observer) => {
            observer.next(navigation);
            observer.complete();
        }).pipe(
            tap((navigation) => {
                this._defaultNavigation.next(navigation);
            })
        );
    }
}
