import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { EventService } from 'app/core/common/event.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { debounceTime, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChatBoxComponent } from '../documented-ai/chat-box/chat-box.component';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { Transaction } from '@tiptap/pm/state';


@Component({
  selector: 'app-technical-insight',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, FuseDrawerComponent,MatSelectModule, MatProgressSpinnerModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule, NgxTiptapModule, EditorControlsComponent],
  templateUrl: './technical-insight.component.html',
  styleUrl: './technical-insight.component.scss'
})

export class TechnicalInsightComponent {
  @ViewChild(FuseDrawerComponent) viewDrawer: FuseDrawerComponent;
  headerForm:UntypedFormGroup;
  insightForm:UntypedFormGroup;
  _proposalDetail: any = {};
  _userDetail: any = {};
  findMetadataDetail: any = {};
  describeMetadataList: any [] =[];
  originaldescribeMetadataList: any [] =[];
  selectedInsightType=2;
  load_insight: boolean = true;
  fetch_vector_val: boolean = false;
  edit_extract: boolean = false;
  extractSaved: boolean = false;
  type_list: any[]=[];
  insights: any[]=[];
  originalinsights: any[]=[];
  selectedInsight: any = {};
  showQuickPromptMenu:boolean=false;
  insideTextArea=false;
  isQuickMenuHighlighted:boolean=false;
  mouseInitialPosition=null;
  mouseDownPos=0;
  menuPosition = { top: '500px', left: '80px' };
  quickSelectedText="";
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  overflowbubble:boolean=false;
  private selectedFeatureData={
    type:"",
    index:0
  };

  @Input() set fetch_vector_data(value: any) {
    this.fetch_vector_val = value;
  }

  @Input() set proposal(value: any) {
    if (value) {
      // Use a setter to detect changes in the Input property
      this._proposalDetail = value;
      this.ngOnInit();
      this.cdr.detectChanges(); // Optionally force change detection
    }
  }
  
  @Input() set user(value: any) {
    if (value) {
      this._userDetail = value;
    }
  }

  @Input() set findMetadata(value: any) {
    if (value) {
      this.findMetadataDetail = value;
    }
  }

  @Input() set describeMetadata(value: any) {
    if (value) {
      this.describeMetadataList = value;
      this.originaldescribeMetadataList = value;
    }
  }

  @Input() accessStatus:any;
  @Input() proposalID:any;
  @Input() is_write_allowed:any;

  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Answer the above question to enhance the input quality score.",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.overflowbubble = true;
      this.updateSelectedText(editor);
    },
  });

  constructor(private overlayContainer: OverlayContainer,private cdr: ChangeDetectorRef,private _websocketService: WebsocketService,private _formBuilder: UntypedFormBuilder,public dialog: MatDialog,private eventService: EventService, private proposalService:ProposalService){
    this.eventService.event$.subscribe((data) => {
      if(data.type=='insight'){
        this.proposalService.resetInsightsCache();
        this.getInsights();
      }
    });
  }

  ngOnInit(): void {  
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_type: ['']
    });
    this.insightForm = this._formBuilder.group({
      _id : [''],
      title: [''],
      notes: [''],
      category_type: [''],
      insightID: [''],
      metadataID: [''],
      proposalID: ['']
    });
    this.setupSearchListener();
    if(this._proposalDetail && this._proposalDetail._id && this.findMetadataDetail && this.findMetadataDetail._id)this.getInsights();
  }

  setupSearchListener(): void {
    this.headerForm.get('search')?.valueChanges
    .pipe(
      debounceTime(500),
      map(() => this.filterCount())
    )
    .subscribe(filteredResults => {
      this.insights = filteredResults;
    });
    this.headerForm.get('selected_type')?.valueChanges
    .pipe(
      map(() => this.filterCount())
    )
    .subscribe(filteredResults => {
      // this.valueSet(filteredResults);
    });
  }

  getInsights(){
    this.insights = [];
    this.load_insight = true;
    let objectInteraction = {proposalID:this.proposalID,metadataID:this.findMetadataDetail._id};
    // console.log(objectInteraction);
    this.proposalService.getDocInsights(objectInteraction).subscribe(
      (value) => {console.log(value);
        this.insights = [];
        if(value.success && value.data.length){
          this.fetch_vector_val = true;
          value.data.forEach(element => {
            element.questions.forEach(eachquestion => {
              eachquestion['category_type'] = element.category;
              eachquestion['insightID'] = element._id;
              
              this.insights.push(eachquestion);
            });
          });
        }
        this.load_insight = false;
        this.cdr.detectChanges();
      },
      (error) => {
          console.error('Error fetching insights:', error);
      }
    ); 
  }

  filterCount(): any[] {
    const searchValue = this.headerForm.get('search')?.value?.toLowerCase() || '';
    const selectedType = this.headerForm.get('selected_type')?.value?.toLowerCase() || '';
    // console.log(searchValue);//console.log(selectedSubcategory);
    let insightList = JSON.parse(JSON.stringify(this.originalinsights));
    if (!searchValue && !selectedType) {
      return insightList;
    }

    const filteredInsights = insightList.filter(insight => {
      return (
          (insight.title && insight.title.toLowerCase().includes(searchValue)) ||
          (insight.notes && insight.notes.toLowerCase().includes(searchValue))
      );
    });

    return filteredInsights;
  }

  openChatWithAI(){
    const dialogRef = this.dialog.open(ChatBoxComponent,
      { 
        panelClass: 'add-feature-dialog',
        disableClose: true,
        autoFocus: false,  
        restoreFocus: false,
        data : {
          proposalID: this._proposalDetail._id,
          proposal : this._proposalDetail,
          document: null,
          type:"metadata",
          metadataID:this.findMetadataDetail._id,
          accessStatus: this.accessStatus
        }
      }
    );
    dialogRef.beforeClosed().subscribe(result => {
      // console.log(result);
      // this.onKCDocUpload.emit({refresh:true});
      const overlayContainerElement = this.overlayContainer.getContainerElement();
      if (overlayContainerElement) {
        const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
        overlayContainers.forEach(overlayPane => {
          overlayPane.remove();  // Only removes the specific dialog content
        });
        // overlayContainerElement.innerHTML = '';  // Clear the contents of the overlay container
      }
      this.proposalService.resetInsightsCache();
      this.getInsights();
      this.cdr.detectChanges();
    });

  }

  onChangeInsightType(type){
    this.selectedInsightType=type;
  }

  openEditDrawer(eachinsight){
    this.insightForm.get('metadataID').setValue(this.findMetadataDetail._id);
    this.insightForm.get('proposalID').setValue(this._proposalDetail._id);
    this.insightForm.get('insightID').setValue(eachinsight.insightID);
    this.insightForm.get('_id').setValue(eachinsight._id);
    this.insightForm.get('title').setValue(eachinsight.title);
    this.insightForm.get('notes').setValue(eachinsight.notes);
    this.insightForm.get('category_type').setValue(eachinsight.category_type);
    this.selectedInsight = this.insightForm.value;
    this.viewDrawer.open();
  }

  onEditSave(){
    this.edit_extract = true;
    this.proposalService.createMetadataInsight(this.insightForm.value).subscribe((response)=>{
      if(response.success){
        this.edit_extract = false;
        this.extractSaved = true;
        this.proposalService.resetInsightsCache();
        this.getInsights();
        setTimeout(() => {
          this.extractSaved = false;
          this.clear();
        }, 1000);
      }
      else{
        this.edit_extract = false;
      }
    });
  }

  onDelete(selectedInsight){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {
        message:"Are you sure you want to delete this inisght?",
        title:"Confirm Delete",
        buttonTitle:"Delete"
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.yes===true){
        this.proposalService.deleteInsightQuestion(selectedInsight.insightID,selectedInsight._id).subscribe((response)=>{
          if(response.success==true){
            this.proposalService.resetInsightsCache();
            this.getInsights();
          }else{
          }
        });
      }
    });
  }

  clear(){
    this.selectedInsight = {};
    this.viewDrawer.close();
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){ 
    this.insideTextArea=true;
  }

  onMouseLeave(){ 
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onTextSelect(event,targetId){
    // console.log("Event:",event);
    if(!this.is_write_allowed){
     return;
    }
     // const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);
 
     if(targetId.includes("description")||targetId.includes("solution")){
       let data=targetId.split("-");
       this.selectedFeatureData={
         type:data[0],
         index:parseInt(data[1])
       };
     }else{
       this.selectedFeatureData={
         type:"",
         index:0
       };
     }
     // console.log('selectedFeatureData', this.selectedFeatureData);
     this.mouseInitialPosition=event.clientY;
     //This code place menu at bottom of selection
     const textarea = event.target as HTMLTextAreaElement;
 
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
         let last=event.pageY-120;
         if(last<this.mouseDownPos){
           const screenWidth = window.innerWidth;
           const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;
 
         this.menuPosition = {
             top: `${last}px`,
             left: `${xPosition}px`
         };
 
         }
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
  }

  onMouseDown(event){
    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
    }
    // console.log("On Selection Change:",event.clientX,event.clientY);
    const textarea = event.target as HTMLTextAreaElement;
    // const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    //  const targetId = (event.target as HTMLElement).id;
   // console.log('Target element ID:', targetId);

    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    // this.quickSelectedText=selectedText;
    if (this.quickSelectedText.trim().length > 0) {
      
     /*
      if(event.pageY<this.mouseDownPos){

      
      this.menuPosition = {
          top: `${event.pageY-90}px`,
          left: this.menuPosition.left
      };

      }
      */

      /*
        this.menuPosition = {
            top: `${clientY + window.scrollY -30}px`,
            left: `${90+window.scrollX}px`
        };
*/
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    } 
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');

    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
    if(!this.selectedText){
      this.overflowbubble = false;
    }
  }

  onCompleteQuickAction(newValue){
    const { from, to } = this.editor.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor.view.dispatch(tr);
    this.editor.chain().focus().setTextSelection({ from, to: newTo }).run();
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();
      
  }


}
