import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User, IAgentDashboard, IAgentListDashboard } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap, of, switchMap, catchError, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _agentStats = new BehaviorSubject<IAgentDashboard | null>(null);
    private _agentList = new BehaviorSubject<IAgentListDashboard[] | null>(null); 
    public readonly agentStats$ = this._agentStats.asObservable();
    public readonly agentList$ = this._agentList.asObservable();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>(environment.api_endpoint + 'user', { headers: { method: 'profile' } }).pipe(
            tap((user) =>
            {   
                this._user.next(user);
                return of(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user/auth', user, { headers: { method: 'save_profile' } }).pipe(
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    postUserImage(data): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'upload/save',JSON.stringify(data), { headers: { method: 'test' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    fetchAgentStats(): Observable<IAgentDashboard>
    {
        return this._httpClient.get<IAgentDashboard>(environment.api_endpoint + 'user', { headers: { method: 'agent_stats' } }).pipe(
            tap((agent_stats) =>
            {   
                this._agentStats.next(agent_stats);
                return of(agent_stats);
            }),
        );
    }

    getAgentStats(): Observable<IAgentDashboard> {
        if (this._agentStats.value) {
          return this.agentStats$;
        } else {
          return this.fetchAgentStats();
        }
    }

    clearAgentStats() {
        this._agentStats.next(null);
    }

    fetchAgentList(offset: number, limit: number = 30): Observable<IAgentListDashboard[]> {
        return this._httpClient
            .get<IAgentListDashboard[]>(`${environment.api_endpoint}user`, { 
                headers: { method: 'agents' },
                params: { offset: offset, limit: limit }
            })
            .pipe(
                tap((newAgentList) => {
                    const currentAgentList = this._agentList.value || [];
                    const updatedAgentList = [...currentAgentList, ...newAgentList['data']];
                    this._agentList.next(updatedAgentList);
                })
            );
    }
    
    getAgentList(): Observable<IAgentListDashboard[]> {
        if (this._agentList.value && this._agentList.value.length > 0) {
            return this.agentList$;
        } else {
            const limit = 30;
            let offset = 0;

            const fetchAllBatches = (): Observable<IAgentListDashboard[]> => {
                return this.fetchAgentList(offset, limit).pipe(
                    switchMap((newAgents) => {
                        if (newAgents && newAgents['data'].length < limit) {
                            return this.agentList$;
                        } else {
                            offset += limit;
                            return fetchAllBatches();
                        }
                    })
                );
            };

            return fetchAllBatches();
        }
    }

    clearCacheAndFetch(): Observable<IAgentListDashboard[]> {
        this._agentList.next(null);
    
        const limit = 30;
        let offset = 0;
    
        const fetchAllBatches = (): Observable<IAgentListDashboard[]> => {
            return this.fetchAgentList(offset, limit).pipe(
                switchMap((newAgents) => {
                    if (newAgents && newAgents['data'].length < limit) {
                        return this.agentList$;
                    } else {
                        offset += limit;
                        return fetchAllBatches();
                    }
                })
            );
        };
    
        return fetchAllBatches();
    }

    clearAgentList() {
        this._agentList.next([]);
    }
    
}
