<app-add-feature #addFeature (onDeleteFeature)="onDeleteFeatureCallback($event)"  (onSaveFeature)="onSaveFeature($event)" [themes]="themeTypes" [epics]="_proposalDetail.epics" [releases_list]="releases_list"></app-add-feature>
<app-add-epic (onSaveEpic)="onSaveEpic($event)" [themeTypes]="themeTypes" #addEpic></app-add-epic>
<app-add-theme (onSaveTheme)="onSaveTheme($event)"  #addTheme></app-add-theme>
<app-regenerate-insights-view (onRegenerateEpic)="onRegenerateEpicInitiated($event)" (onRegenerateTheme)="onRegenerateThemeInitiated($event)"  #regenDrawer></app-regenerate-insights-view>

@if(isJiraError){
  <fuse-alert   type="error" [showIcon]="true" [appearance]="'soft'" [dismissible]="'true'"> {{jiraErrorMessage}}</fuse-alert>
}


<div class="flex actBt flex-wrap items-center gap-3 mt-8 mb-8 justify-between">
  <div class="subHeadingCont" *ngIf="_page_id != 'testing'">                                                
      <div class="text-black font-medium text-lg">Solution Workspace</div>
      <div class="text-[#94A3B8] text-md font-normal">AI-enhanced platform to finalize and manage project requirements and solutions.</div> 
  </div>
  <div class="subHeadingCont" *ngIf="_page_id === 'testing'">                                                
    <div class="text-black font-medium text-lg">Testing Workspace</div>
    <div class="text-[#94A3B8] text-md font-normal">AI-enhanced workspace to manage project requirements and test cases.</div> 
</div>
      <!-- Add product button -->
  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="add-get-ai-bt flex flex-wrap gap-3">


    @if(getIntegrationStatus()){

      <button [ngClass]="{ 'syncBt': getNotSyncedCount()>0, 'disableBtn': getNotSyncedCount()==0 }"  mat-flat-button [disabled]="getNotSyncedCount()==0" (click)="onSyncProjectWithJira()" >
            @if(isSyncingProject || (_proposalDetail.integrations.jira.status && _proposalDetail.integrations.jira.status==1)){
              <img
              [ngClass]="'rotate-animation'"
              src="assets/icons/sync_icon_white.svg"
              class="w-6 h-6"
              />
              <img src="assets/icons/jirawhite.svg"  alt="">
              <span class="">Syncing {{getSyncLabel()}}</span>
            }@else{
              <img [src]="getNotSyncedCount()==0?'assets/icons/jira_disabled.svg':'assets/icons/jirawhite.svg'"  alt="">
              <span class="" *ngIf="getNotSyncedCount()>0">Sync {{getNotSyncedCount()}} {{getSyncLabel()}}</span>
              <span class="" *ngIf="getNotSyncedCount()<=0">All Synced</span>
            }
    </button>
    }

      <button class="addThemeBt" mat-flat-button [color]="'primary'" (click)="onClickAddTheme()"  >
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="">Add Theme </span>
      </button>
      <button class="bg-[#2C3344] text-white addEpicBt" mat-flat-button  (click)="onClickAddEpic()"  >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="">Add Epic </span>
    </button>
  </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6">
  <!-- Header Section -->
  <section class="flex flex-row flex-wrap justify-between px-3">
      <div class="flex expandBox border" >
        <mat-icon svgIcon="heroicons_outline:bars-4" (click)="onToggleAccordion(0)" [ngClass]="{'active':expandState==0 }"></mat-icon>
        <mat-icon svgIcon="heroicons_outline:bars-3" (click)="onToggleAccordion(1)" [ngClass]="{'active':expandState==1 }"></mat-icon>
        <mat-icon svgIcon="heroicons_outline:bars-2" (click)="onToggleAccordion(2)" [ngClass]="{'active':expandState==2 }"></mat-icon>
          <!-- <mat-icon *ngIf="expandState==0 || expandState==1" class="expandIc" svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
          <mat-icon *ngIf="expandState==2" class="collapseIc" svgIcon="heroicons_outline:arrows-pointing-in"></mat-icon> -->
      </div>
      <form class="flex flex-wrap gap-2 justify-center " [formGroup]="inputFormGroup">
      <mat-form-field class="fuse-mat-dense searchTab" *ngIf="(_page_id != 'testing')">
        <mat-icon
            class="icon-size-5 mr-1"
            matPrefix
            [svgIcon]="'heroicons_solid:magnifying-glass'"
            
            ></mat-icon>
        <input
            matInput formControlName="search"
            [autocomplete]="'off'"
            [placeholder]="'Search'" (input)="search($event)">
      </mat-form-field>
      <!-- Add filters -->
      <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
          <mat-select
              [formControlName]="'theme'"
              [placeholder]="'Theme'" multiple (selectionChange)="onChangeTheme($event)">
              <!-- <mat-option #allSelected [value]="0">All</mat-option> -->
              @for (option of themeTypes; track option) {
                  <mat-option [value]="option.value">{{option.label}}</mat-option>
              }
          </mat-select>
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
          <!-- <mat-icon class="icon-size-5" matPrefix svgIcon="assets/icons/theme_filter_icon.svg"></mat-icon> -->
          <!-- <img src="assets/icons/theme_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
      </mat-form-field>
      <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
          <mat-select
              [formControlName]="'epic_name'"
              [placeholder]="'Epic'" multiple (selectionChange)="onChangeEpic($event)">
              @for (option of epicTypes; track option) {
                  <mat-option [value]="option.value">{{option.label}}</mat-option>
              }
          </mat-select>
          <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="(_page_id != 'testing')" class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
        <mat-select
            [formControlName]="'release'"
            [placeholder]="'Release'" multiple (selectionChange)="onChangeEpic($event)">
            @for (option of releases_list; track option) {
                <mat-option [value]="option._id">{{option.name}}</mat-option>
            }
        </mat-select>
        <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
      </mat-form-field>
  </form>

  </section>
  <!-- Requirements Section -->
  <ng-container>
      <div class="max-w-full flex flex-col">
            <mat-accordion  class="max-w-full mt-6" [multi]="true" [displayMode]="'flat'"  cdkDropList (cdkDropListDropped)="dropTheme($event)" [cdkDropListData]="requirements">
              <ng-container *ngFor="let requirement of requirements; let themeIndex=index; trackBy: trackByFn">
                  <mat-expansion-panel
                      class="border-none w-full parentTab"
                      [(expanded)]="themesExpanded[themeIndex]"
                      [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                      [cdkDragLockAxis]="'y'" >
                      <mat-expansion-panel-header class="border-t border-b group w-full select-none h-15 font-semibold ">
                          <mat-panel-title class="flex items-center w-full pl-5">
                              <div class="flex-auto mr-2 truncate text-lg">
                                  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                          class="md:hidden absolute ml-2 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                          cdkDragHandle>
                                          <mat-icon
                                              class="icon-size-5 text-hint"
                                              [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                      </div>
                                      <span>{{themeIndex + 1}}. {{requirement.theme}}</span>
                                    </div>
                              <!-- quickActTab -->
                              <div class="mr-2 quickActTab" >
                                <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="smallIc mr-2" svgIcon="heroicons_outline:pencil-square" (click)="onEditTheme(requirement);$event.stopPropagation()"></mat-icon>
                                <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="smallIc" svgIcon="heroicons_outline:trash" (click)="onDeleteTheme(requirement);$event.stopPropagation()"></mat-icon>

                              </div>
                              <button (click)="onRegenerateTheme(requirement,themeIndex);$event.stopPropagation()" class="mr-2" (mouseover)="requirement['isHovered'] = true" 
                              (mouseout)="requirement['isHovered'] = false" ><img [src]="requirement['isHovered'] ? 'assets/icons/regenerator_icon.svg' : 'assets/icons/regen_disabled_icon.svg'"  class="w-5 h-5"></button>
                              
                              <button (click)="onSelectInsight(requirement.theme,null);$event.stopPropagation()" (mouseover)="requirement['isInsightHovered'] = true" 
                              (mouseout)="requirement['isInsightHovered'] = false" ><img [src]="requirement['isInsightHovered'] ? 'assets/icons/insights_enabled_icon.svg' : 'assets/icons/insights_disabled_icon.svg'"  class="w-5 h-5"></button>
                              
                              
                              <!-- <mat-icon class="smallIc" (click)="onSelectInsight(requirement.theme);$event.stopPropagation()" svgIcon="heroicons_outline:sparkles"></mat-icon> -->

                          </mat-panel-title>
                      </mat-expansion-panel-header>
          
                      <!-- Theme Requirements inside each accordion item -->
                      <div class="questions-container  max-w-full">
                        <div class="questions-container  max-w-full ">                
                          <mat-accordion [multi]="true" [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" (cdkDropListDropped)="dropEpic($event,themeIndex)" [cdkDropListData]="requirement.epics">
                            @if(!requirement.isRegenerating){
                            <ng-container *ngFor="let epic of requirement.epics;let epicIndex=index;">


                              <mat-expansion-panel  [(expanded)]="epicsExpanded[getEpicKey(themeIndex,epicIndex)]"  class="childTab"  [cdkDrag]="(_page_id === 'proposal' && is_write_allowed)"
                                [cdkDragLockAxis]="'y'">
                                  

                                   
                                  <mat-expansion-panel-header class="pl-14 textTab border-b group">
                                    <mat-panel-title class="flex justify-between items-center">
                                      <div class="flex-auto mr-2 truncate text-lg">
                                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                                class="md:hidden absolute ml-8 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                                cdkDragHandle>
                                                <mat-icon
                                                    class="icon-size-5 text-hint"
                                                    [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                            </div>
                                        <span class="ml-2">{{(themeIndex+1)}}.{{(epicIndex+1)}}. {{epic.name}}</span>
                                    </div>
                                    
                                    <!-- quickActTab -->

                                      <div class="mr-2 quickActTab" >
                                        <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="smallIc mr-2" svgIcon="heroicons_outline:pencil-square" (click)="onEditEpic(requirement.theme,epic.name);$event.stopPropagation()"></mat-icon>
                                        <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="smallIc" svgIcon="heroicons_outline:trash" (click)="onDeleteEpic(requirement.theme,epic.name);$event.stopPropagation()"></mat-icon>
                                      </div>

                                      <button (click)="onRegenerateEpic(requirement,epic,themeIndex,epicIndex);$event.stopPropagation()" class="mr-2" (mouseover)="epic['isHovered'] = true" 
                                      (mouseout)="epic['isHovered'] = false" ><img [src]="epic['isHovered'] ? 'assets/icons/regenerator_icon.svg' : 'assets/icons/regen_disabled_icon.svg'"  class="w-5 h-5"></button>
                                      
                                      <button (click)="onSelectInsight(requirement.theme,epic);$event.stopPropagation()" (mouseover)="epic['isInsightHovered'] = true" 
                                      (mouseout)="epic['isInsightHovered'] = false" ><img [src]="epic['isInsightHovered'] ? 'assets/icons/insights_enabled_icon.svg' : 'assets/icons/insights_disabled_icon.svg'"  class="w-5 h-5"></button>
                                      
                                    
                                    
                                    
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>

                                  @if(epic.isRegenerating){
                                    <ng-container>
                                
                                      <div class="ml-16 mr-10 mt-10">
                                      <app-regenerating-progress-bar [restart]="isRegenerating" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                                    </div>
                                    <div class="shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>
                                    <div class=" shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>
      
      
      
                                    </ng-container>
                                  }@else{
                                  <ng-container>
                                    <section *ngIf="(_page_id === 'proposal' || _page_id === 'project')">
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10">
                                          <div class="flex justify-between items-center w-full px-5 py-5">
                                            <div class="text-lg font-semibold text-black">Requirements</div>
                                            <img *ngIf="(requirement.requirementsVisible && (_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" (click)="requirement.requirementsVisible==undefined?requirement.requirementsVisible=false:requirement.requirementsVisible=!requirement.requirementsVisible" class="w-16 h-8 cursor-pointer" src="assets/icons/hide_icon.svg">
                                            <img *ngIf="(!requirement.requirementsVisible && (_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)"  (click)="requirement.requirementsVisible==undefined?requirement.requirementsVisible=false:requirement.requirementsVisible=!requirement.requirementsVisible" class="w-16 h-8 cursor-pointer" src="assets/icons/showic.svg">
                                          </div>
                                          <section  *ngIf="requirement.requirementsVisible==undefined || requirement.requirementsVisible">
                                          <div
                                          [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)"
                                          (cdkDropListDropped)="dropRequirement($event,themeIndex,epicIndex)"
                                          class="w-full"
                                          [cdkDropListData]="epic.features">
                                          <ng-container *ngFor="let feature of epic.features; let i = index">
                                            <div
                                              [id]="'requirement-' + i"
                                              class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB"
                                              [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)"
                                            >
                                              <!-- Insight content -->
                                              <div class="relative flex items-center h-full py-2 tabBorder">
                                                <div class="flex justify-between pl-12 gap-1 min-w-full items-center h-full cursor-pointer">
                                                  <div class="flex w-full sm:mr-2 mx-2 sm:text-md text-md text-black font-regular" (click)="onEditFeature(requirement.theme,epic.name,feature,i)" *ngIf="is_write_allowed"  >
                                                    {{feature.title}}
                                                    <div class="font-medium ml-5 text-sm">{{getSolutionCategoryForRequirement(feature._id)}}</div>
                                                  </div>
                                                  <div class="flex w-full sm:mr-2 mx-2 sm:text-md text-md text-black font-regular" *ngIf="!is_write_allowed"  >
                                                    {{feature.title}}
                                                    <div class="font-medium ml-5 text-sm">{{getSolutionCategoryForRequirement(feature._id)}}</div>
                                                  </div>
                                        
                                                  <!-- Drag handle, visible only on hover -->
                                                  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="absolute left-0 pl-6 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                                                    <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                                                  </div>
                                                  

                                                  

                                                  <!-- Edit and Delete Buttons -->
                                                  <div class="flex justify-end mr-8 gap-2 group">

                                                    <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="flex group pr-2">
                                                      <mat-select [value]="feature.release" class="selectOption" (selectionChange)="onRequirementReleaseSelection($event,requirement.theme,epic.name,feature,i)">
                                                        @for (release of releases_list; track release) {
                                                          <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                                        }
                                                      </mat-select>
                                                    </div>

                                                    <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="gap-2 opacity-0 group-hover:opacity-100 flex gap-2" >
                                                      <mat-icon class="smallIc mr-1" (click)="onEditFeature(requirement.theme,epic.name,feature,i)"  svgIcon="heroicons_outline:pencil-square"></mat-icon>
                                                      <mat-icon class="smallIc"  (click)="onDeleteFeature(requirement.theme,epic.name,feature,i)" svgIcon="heroicons_outline:trash"></mat-icon>
                                                    </div>

                                                    <!-- <button class="leading-none -mr-2 opacity-0 group-hover:opacity-100" mat-icon-button (click)="onEditFeature(requirement.theme,epic.name,feature,i)">
                                                      <img class="max-w-4 max-h-4" src="assets/icons/edit_req_icon.svg" />
                                                    </button> -->
                                                    <!-- <button class="leading-none opacity-0 group-hover:opacity-100" mat-icon-button (click)="onDeleteFeature(requirement.theme,epic.name,feature,i)">
                                                      <img class="max-w-4 max-h-4" src="assets/icons/trash_req_icon.svg" />
                                                    </button> -->
                                                  </div>

                                                  

                                                </div>
                                        
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <section *ngIf="!epic.features.length">
                                            <p class="noSolution">AI couldn’t find a requirement. Time for some human critical thinking! 
                                              Feel free to add your requirement.</p>
                                        </section>
                                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="min-w-full flex pl-10 py-5 items-center justify-start border-t  relative cursor-pointer" (click)="onClickAddFeature(requirement.theme,epic.name)">
                                          <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                          <div  class="pl-2 text-md text-[#9AA6B8]">Add Requirement</div>
                                        </div>
                                      </section>
                                      </div>
                                    </section>
                                    <app-story-table *ngIf="(_page_id === 'project' || _page_id === 'testing')" [(proposal)]="_proposalDetail" [themeTypes]="themeTypes" [epicTypes]="epicTypes" [stories]="epic.stories" (onUpdateStories)="onUpdateStories($event)" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [releases_list]="releases_list"></app-story-table>
                                    <app-test-case-table [myIndex]="epic.index"  *ngIf="(_page_id === 'testing')" [(proposal)]="_proposalDetail" [(testplan)]="_testplanDetail" [themeTypes]="themeTypes" [filterApplied]="filterApplied" [epicTypes]="epicTypes" [stories]="epic.stories" (onUpdateStories)="onUpdateStories($event)" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [releases_list]="releases_list" [all_categories]="all_categories" [(epicName)]="epic.name" [(themeName)]="requirement.theme"></app-test-case-table>

                                    <section *ngIf="(_page_id === 'proposal' || _page_id === 'project')">

                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10 solutionCard">
                                            <div class="grid grid-cols-5 min-w-full px-5 gap-3 py-5 justify-center tabBorder">
                                                <div class="flex justify-start col-span-1 text-md font-medium uppercase  ml-12">Solution Module</div>
                                                <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Sub Module</div>
                                                <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Technology</div>
                                                <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Complexity</div>
                                                <div class="flex pl-4 justify-start col-span-1 text-md font-medium uppercase ">Effort</div>
                                              </div>
                                              
                                              <section *ngIf="epic.solutions.length">
                                                <div
                                                [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                                  (cdkDropListDropped)="dropSolution($event,themeIndex,epicIndex)"
                                                  class="w-full"
                                                  [cdkDropListData]="epic.solutions"
                                                >
                                                <ng-container *ngFor="let solution of epic.solutions; let i = index">
                                                  <div [id]="'requirement-' + i" class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0  customB" [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)">
                                                    <!-- Content for each section -->
                                                    <div class="grid grid-cols-5 gap-3 items-center py-3 px-5 tabBorder cursor-pointer">
                                                      <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="absolute left-0 pl-6 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                                                        <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                                                      </div>
                                                      <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular  ml-12"  (click)="is_write_allowed && toggleDetails(solution._id, epic.solutions,i)">
                                                        {{ solution.epicName }}
                                                      </div>
                                                      <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution._id, epic.solutions,i)">
                                                        {{ solution.name }}
                                                      </div>
                                                      <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution._id, epic.solutions,i)">
                                                        {{ solution.cloud || 'Salesforce' }}
                                                      </div>
                                                      <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution._id, epic.solutions,i)">
                                                        {{ solution.complexity }}
                                                      </div>
                                                      <div class="flex justify-between pl-4 items-center col-span-1 text-md text-black font-regular ">
                                                        <span (click)="is_write_allowed && toggleDetails(solution._id, epic.solutions,i)" class="break-all" style="min-width: 45px;">{{ solution.effort }}</span>

                                                        <div  class="flex justify-between pl-4 gap-1 group" style="min-width: 120px;">
                                                          
                                                          <div *ngIf="(_page_id === 'template' || !is_write_allowed)" class="flex justify-center col-span-1 group selectOption" style="width: 60px;">
                                                            {{solution.release_shortname}}
                                                        </div>

                                                          <mat-select [value]="solution.release" *ngIf="((_page_id === 'project' || _page_id === 'proposal') && is_write_allowed)" class="selectOption" (selectionChange)="onSolutionReleaseSelection($event,solution,i)">
                                                            @for (release of releases_list; track release) {
                                                              <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                                            }
                                                          </mat-select>

                                                          
                                                          <div class="flex justify-end group" *ngIf="((_page_id === 'project' || _page_id === 'proposal') && is_write_allowed)">
                                                            <div class="flex gap-1 opacity-0 group-hover:opacity-100">
                                                              <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="toggleDetails(solution._id, epic.solutions,i)"></mat-icon>
                                                              <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash" (click)="onClickDeleteSolution(solution._id,requirement.theme,epic.name)"></mat-icon>
                                                            </div>
                                                          </div>

                                                          

                                                        </div>

                                                      </div>




                                                    </div>
                                                    </div>
                                                    <!-- Expanded details section -->
                                                    <div class="grid" *ngIf="(selectedFeature?._id === solution._id && selectedFeature?.index === i)">
                                                      <div class="shadow-lg overflow-hidden">
                                                        <div class="flex border-b border-t">
                                                          <!-- Selected feature form -->
                                                          <form class="flex flex-col w-full">
                                                            <div class="flex flex-col sm:flex-row p-8">
                                                              <div class="flex flex-auto flex-wrap">
                                                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                                  <!-- Epic Name -->
                                                                  <div class="sm:col-span-2">
                                                                    <mat-form-field class="w-full">
                                                                      <mat-label>Module</mat-label>
                                                                      <input matInput [(ngModel)]="solution.epicName" name="epicName" [readonly]="_page_id === 'template' || !is_write_allowed">
                                                                    </mat-form-field>
                                                                  </div>
                                                                  <!-- Feature Name -->
                                                                  <div class="sm:col-span-2">
                                                                    <mat-form-field class="w-full">
                                                                      <mat-label>Sub-Module</mat-label>
                                                                      <input matInput [(ngModel)]="solution.name" name="name" [readonly]="_page_id === 'template' || !is_write_allowed">
                                                                    </mat-form-field>
                                                                  </div>

                                                                  <!-- Themes -->
                                                                  <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                                    <mat-form-field class="w-full">
                                                                        <mat-label>Theme</mat-label>
                                                                        <mat-select [(ngModel)]="solution.theme" name="theme" [placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                                                          @for (option of themeListTypes; track option) {
                                                                              <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                          }
                                                                          </mat-select>
                                                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                                                                        <!-- <input matInput [(ngModel)]="solution.theme" name="theme" readonly> -->
                                                                    </mat-form-field>
                                                                  </div>
                                                                  <!-- Epic -->
                                                                  <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                                      <mat-form-field class="w-full">
                                                                          <mat-label>Epic</mat-label>
                                                                          <mat-select [(ngModel)]="solution.epic" name="epic"[placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                                                            @for (option of themeEpicTypes; track option) {
                                                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                            }
                                                                            </mat-select>
                                                                          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                                                          <!-- <input matInput [(ngModel)]="solution.epic" name="epic" readonly> -->
                                                                      </mat-form-field>
                                                                  </div>


                                                                  <!-- Complexity -->
                                                                  <div class="sm:col-span-2">
                                                                  <mat-form-field class="w-full">
                                                                    <mat-label>Complexity</mat-label>
                                                                    <mat-select *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" [(ngModel)]="solution.complexity" name="complexity" placeholder="Select Option" required (selectionChange)="featureComplexityChange($event,solution)">
                                                                      <mat-option *ngFor="let option of complexities" [value]="option.value">
                                                                        {{ option.label }}
                                                                      </mat-option>
                                                                    </mat-select>
                                                                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
                                                                    <input matInput *ngIf="_page_id === 'template' || !is_write_allowed" [(ngModel)]="solution.complexity" name="complexity" [readonly]="_page_id === 'template' || !is_write_allowed">
                                                                  </mat-form-field>
                                                                  </div>

                                                                  <!-- Release -->
                                                                  <div class="sm:col-span-2">
                                                                    <mat-form-field class="w-full">
                                                                      <mat-label>Release</mat-label>
                                                                      <mat-select *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" [(ngModel)]="solution.release" name="release" placeholder="Select Option" required (selectionChange)="featureComplexityChange($event,solution)">
                                                                        <mat-option *ngFor="let option of releases_list" [value]="option._id">
                                                                          {{ option.name }}
                                                                        </mat-option>
                                                                      </mat-select>
                                                                      <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:cube-transparent"></mat-icon>
                                                                      <input matInput *ngIf="_page_id === 'template' || !is_write_allowed" [(ngModel)]="solution.complexity" name="complexity" [readonly]="_page_id === 'template' || !is_write_allowed">
                                                                    </mat-form-field>
                                                                    </div>

                                                                </div>
                                                
                                                                <!-- More fields -->
                                                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                                  <!-- Components Count -->
                                                                  <div class="sm:col-span-2">
                                                                    <mat-form-field class="w-full">
                                                                      <mat-label>Components Count</mat-label>
                                                                      <input type="number" matInput [(ngModel)]="solution.componentsCount" name="componentsCount" (change)="onComponentsCountChange($event,solution)" [readonly]="_page_id === 'template' || !is_write_allowed">
                                                                    </mat-form-field>
                                                                  </div>
                                                
                                                                  <!-- Effort -->
                                                                  <div class="sm:col-span-2">
                                                                    <mat-form-field class="w-full">
                                                                      <mat-label>Effort</mat-label>
                                                                      <input type="number" matInput required [(ngModel)]="solution.effort" name="effort" [readonly]="_page_id === 'template' || !is_write_allowed" min="0" >
                                                                    </mat-form-field>
                                                                  </div>
                                                                </div>
                                                
                                                                <!-- Descriptions -->
                                                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                                  <div class="sm:col-span-4">
                                                                    <div *ngIf="((_page_id=='proposal' || _page_id=='project') && is_write_allowed); else disabledInput">
                                                                        <span class="font-medium block mb-2 text-black">Solution Description</span>
                                                                        <!-- <tiptap-bubble-menu [editor]="editor2" [id]="'description1-'+solution._id">
                                                                            <app-editor-controls 
                                                                            [editor]="editor2" 
                                                                            [selectedText]="selectedText"
                                                                            (completeQuickAction)="onCompleteQuickAction($event)">
                                                                            </app-editor-controls>
                                                                        </tiptap-bubble-menu>
                                                                        
                                                                        <tiptap-editor 
                                                                        [id]="'description-'+solution._id"
                                                                        [editor]="editor2" 
                                                                        [(ngModel)]="solution.featureDescription" name="featureDescription"
                                                                        (blur)="onTextareaBlur()"
                                                                        (mouseenter)="onMouseEnter()"
                                                                        (mouseleave)="onMouseLeave()"
                                                                        (mousedown)="onMouseDown($event, 'description-'+solution._id)"
                                                                        (mouseup)="onTextSelect($event, 'description-'+solution._id)"
                                                                        (select)="onSelectionChange($event, 'description-'+solution._id)"
                                                                        (keydown)="onSelectionChange($event, 'description-'+solution._id)" >
                                                                        </tiptap-editor> -->

                                                                        <textarea 
                                                                        class="w-full border rounded p-2 min-h-30"
                                                                        [id]="'description-'+solution._id"
                                                                        
                                                                        [(ngModel)]="solution.featureDescription" name="featureDescription"
                                                                      >
                                                                        </textarea>

                                                                    </div>
                                                                    <ng-template #disabledInput>
                                                                        <span class="font-medium block mb-2 text-black">Solution Description</span>
                                                                        <tiptap-editor #featureEditor [id]="'descriptionreadonly-'+solution._id" [editor]="editor_feature_readonly"  [(ngModel)]="solution.featureDescription" name="featureDescription" >
                                                                        </tiptap-editor>
                                                                    </ng-template>
                                                                  </div>
                                                                  <!-- <div class="sm:col-span-2">
                                                                    <div *ngIf="(_page_id=='proposal' && is_write_allowed); else disabledInput">
                                                                        <span class="font-medium block mb-2">Solution Description</span>
                                                                        <tiptap-bubble-menu [editor]="editor3" [id]="'solution1-'+solution._id">
                                                                            <app-editor-controls 
                                                                            [editor]="editor3" 
                                                                            [selectedText]="selectedText"
                                                                            (completeQuickAction)="onCompleteQuickAction($event)">
                                                                            </app-editor-controls>
                                                                        </tiptap-bubble-menu>
                                                                        
                                                                        <tiptap-editor 
                                                                        [id]="'solution-'+solution._id"
                                                                        [editor]="editor3" 
                                                                        [(ngModel)]="solution.solutionAssumption" name="solutionAssumption"
                                                                        (blur)="onTextareaBlur()"
                                                                        (mouseenter)="onMouseEnter()"
                                                                        (mouseleave)="onMouseLeave()"
                                                                        (mousedown)="onMouseDown($event, 'solution-'+solution._id)"
                                                                        (mouseup)="onTextSelect($event, 'solution-'+solution._id)"
                                                                        (select)="onSelectionChange($event, 'solution-'+solution._id)"
                                                                        (keydown)="onSelectionChange($event, 'solution-'+solution._id)" >
                                                                        </tiptap-editor>
                                                                    </div>
                                                                    <ng-template #disabledInput>
                                                                        <span class="font-medium block mb-2">Solution Description</span>
                                                                        <tiptap-editor #featureEditor [id]="'solutionreadonly-'+solution._id" [editor]="editor_solution_readonly"  [(ngModel)]="solution.solutionAssumption" name="solutionAssumption" >
                                                                        </tiptap-editor>
                                                                    </ng-template>
                                                                  </div> -->
                                                                </div>
                                                                 

                                                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-2" *ngIf="solution.explanation">
                                                                  <div class="sm:col-span-4">
                                                                    <div *ngIf="(_page_id=='proposal' || _page_id=='project')">
                                                                        <span class="font-medium block mb-2 text-black">Solution Explanation</span>
                                                                        <textarea 
                                                                        class="w-full"
                                                                        [id]="'description-'+solution._id"
                                                                        
                                                                        [(ngModel)]="solution.explanation" name="solutionExplanation"
                                                                      >
                                                                        </textarea>
                                                                    </div>
                                                                    
                                                                  </div>
                                                                  
                                                                </div>


                                                                <!-- <div class="grid sm:grid-cols-4 gap-6 w-full mt-8" *ngIf="solution.solutionAssumption">
                                                                  <div class="sm:col-span-4">
                                                                    <div *ngIf="(_page_id=='proposal' || _page_id=='project')">
                                                                        <span class="font-medium block mb-2 text-black">Solution Reason</span>
                                                                        <textarea 
                                                                        class="w-full"
                                                                        [id]="'description-'+solution._id"
                                                                        
                                                                        [(ngModel)]="solution.solutionAssumption" name="solutionAssumption"
                                                                      >
                                                                        </textarea>
                                                                    </div>
                                                                    
                                                                  </div>
                                                                  
                                                                </div> -->


                                                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-2">
                                                                  <div class="sm:col-span-4">
                                                                    <div *ngIf="((_page_id=='proposal' || _page_id=='project') && is_write_allowed); else disabledInput">
                                                                        <span class="font-medium block mb-2 text-black">Solution Reason</span>
                                                                        <!-- <tiptap-bubble-menu [editor]="editor2" [id]="'llmdescription1-'+solution._id">
                                                                            <app-editor-controls 
                                                                            [editor]="editor4" 
                                                                            [selectedText]="selectedText"
                                                                            (completeQuickAction)="onCompleteQuickAction($event)">
                                                                            </app-editor-controls>
                                                                        </tiptap-bubble-menu>
                                                                        
                                                                        <tiptap-editor 
                                                                        [id]="'lldescription-'+solution._id"
                                                                        [editor]="editor4" 
                                                                        [(ngModel)]="solution.solutionAssumption" name="solutionAssumption"
                                                                        class="red-editor"
                                                                        (blur)="onTextareaBlur()"
                                                                        (mouseenter)="onMouseEnter()"
                                                                        (mouseleave)="onMouseLeave()"
                                                                        (mousedown)="onMouseDown($event, 'llmdescription-'+solution._id)"
                                                                        (mouseup)="onTextSelect($event, 'llmdescription-'+solution._id)"
                                                                        (select)="onSelectionChange($event, 'llmdescription-'+solution._id)"
                                                                        (keydown)="onSelectionChange($event, 'llmdescription-'+solution._id)" >
                                                                        </tiptap-editor> -->

                                                                        <div class="red-editor">
                                                                          <div class="tiptap" [innerHtml]="solution.solutionAssumption">

                                                                          </div>
                                                                        </div>

                                                                    </div>
                                                                    <ng-template #disabledInput>
                                                                        <span class="font-medium block mb-2 text-black">Solution Reason</span>
                                                                        <tiptap-editor #featureEditor [id]="'llmdescriptionreadonly-'+solution._id" [editor]="editor_feature_readonly"  [(ngModel)]="solution.solutionAssumption" name="solutionAssumption">
                                                                        </tiptap-editor>
                                                                    </ng-template>
                                                                  </div>
                                                                  </div>







                                              
                                                              </div>
                                                            </div>
                                              
                                                          <!-- Buttons -->
                                                          <div class="flex items-center  w-full border-t px-8 py-4 gap-2" *ngIf="((_page_id=='proposal' || _page_id=='project') && is_write_allowed)" [ngClass]="flashMessageType?'justify-between':'justify-end' ">

                                                            <fuse-alert  *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'"> {{flashMessage}}</fuse-alert>

                                                            <fuse-alert *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'"> An error occurred, try again!</fuse-alert>

                                                            
                                                            <div class="flex items-center gap-3">
                                                                <button class="-ml-4 dark" mat-button [color]="'black'" (click)="toggleDetails(solution._id, epic.solutions,i)">Close</button>
                                                                
                                                                <button mat-flat-button [color]="'primary'" [ngClass]="{'btdisabled':!(solution.effort >= 0)}" (click)="((solution.effort >=0 )?(updateSelectedFeature(solution)):'')">Update</button>
                                                            </div>
                                                          </div>
                                                          <!-- <div class="flex items-center w-full border-t px-8 py-4 gap-2" *ngIf="_page_id === 'proposal' && is_write_allowed">
                                                            <button mat-flat-button color="primary" (click)="updateSelectedFeature(solution)">Update</button>
                                                          </div> -->
                                                          </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </ng-container>
                                                
                                                </div>
                                              
                                              </section>
                                              
                                              <section *ngIf="!epic.solutions.length">
                                                  <p class="noSolution">AI couldn’t find a solution for your requirement. Time for some human critical thinking! 
                                                    Feel free to add your solution from our master list.</p>
                                              </section>

                                              
                                                <!-- Add Feature Button -->
                                                <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer" (click)="serchSol($event)" >
                                                  <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                  <div class="pl-2 text-md text-[#9AA6B8]">Add Solution</div>
                                                  
                                                </div>


                                                <div class="searchBox flex flex-col" *ngIf="searchSolAct" (click)="onDivClick($event)">
                                                  <div class="searchinput relative">
                                                    <mat-form-field class="fuse-mat-dense solutionSearch w-full" >
                                                      <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'" ></mat-icon>
                                                      <input matInput  [autocomplete]="'off'"
                                                          [placeholder]="'Search for Solution module, sub module or technology'" >
                                                    </mat-form-field>

                                                    <button class="solBt" (click)="onClickAddSolution(requirement.theme,epic.name)"><mat-icon svgIcon="heroicons_outline:arrow-right-circle" class="icon-size-4"></mat-icon> Get More Solution</button>

                                                  </div>
                                                  <div class="searchList">
                                                    <div class="serchRow flex justify-between gap-2 items-center p-2">
                                                      <div class="searchDetail">
                                                        <span class="searchTag">Service Cloud</span>
                                                        <h4 class="font-medium searchTitle text-[#000000] text-lg ">Service Channels / <span class="text-secondary">Set Up Channel-Object Linking </span>  </h4>
                                                        <p class="text-sm">Lorem ipsum dolor sit amet consectetur. Malesuada sapien facilisis amet hendrerit sed. Nunc malesuada morbi eu enim id. </p>
                                                      </div>
                                                      <div class="searchAction">
                                                        <mat-icon svgIcon="heroicons_outline:plus-circle" class=" text-[#4F45E4] "></mat-icon>
                                                      </div>
                                                    </div>
                                                    <div class="serchRow flex justify-between gap-2 items-center p-2">
                                                      <div class="searchDetail">
                                                        <span class="searchTag">Service Cloud</span>
                                                        <h4 class="font-medium searchTitle text-[#000000] text-lg ">Service Channels / <span class="text-secondary">Set Up Channel-Object Linking </span>  </h4>
                                                        <p class="text-sm">Lorem ipsum dolor sit amet consectetur. Malesuada sapien facilisis amet hendrerit sed. Nunc malesuada morbi eu enim id. </p>
                                                      </div>
                                                      <div class="searchAction">
                                                        <mat-icon svgIcon="heroicons_outline:plus-circle" class=" text-[#4F45E4] "></mat-icon>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>


                                                
            
                                        </div>
                                        <div class="w-full mb-5 -mt-6" >
                                          <button [ngClass]="{'opacity-50 cursor-not-allowed': !epic.features.length}"   [disabled]="!epic.features.length" class="bg-white ml-16 flex items-center justify-center gap-1 regen-btn text-black px-2 py-1 text-md rounded-md" (click)="onRegenerateSolutions(_proposalDetail._id,requirement._id,epic._id,epic)">
                                          <img [ngClass]="{ 'spin': epic.isRegenerating }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                          @if(!epic.isRegenerating){
                                          <span>Regenerate Solutions</span>
                                          }@else {
                                            <span>Regenerating Solutions</span>
                                          }
                                          </button>
                                        </div>
                                    </section>
                                  </ng-container>
                                }
                                   
                                </mat-expansion-panel>
                             
                              </ng-container>
                            }@else {


                              <ng-container>
                                
                                <div class="ml-16 mr-10 mt-10">
                                <app-regenerating-progress-bar [restart]="isRegenerating" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                              </div>
                              <div class="shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>



                              <div class=" shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>


                              </ng-container>








                            }
                          </mat-accordion>          
                        </div>
                      </div>
                  </mat-expansion-panel>
              </ng-container>
          </mat-accordion>
      </div>
  </ng-container>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #insightDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex items-center justify-between mt-5 ml-3 mr-3">
            <ng-container>
                <div class="flex flex-col">
                    <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->
                    <span class="ml-2 font-normal text-sm text-gray-400 d-block">Insights</span>
                    <span *ngIf="selectedInsight" class="ml-2 font-semibold">{{selectedInsight.capability}}</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- Close button -->
                <button mat-icon-button (click)="onCloseDrawer()" >
                  <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
       
        <form class="flex flex-col flex-auto" [formGroup]="insightquestionForm">
            <div *ngIf="questionsArray?.controls?.length > 0" formArrayName="questions">
                <div *ngFor="let eachQuestion of questionsArray.controls; let i = index" [formGroupName]="i" class="p-6 border-b">            
                    <!-- Title -->
                    <div>
                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-label class="font-semibold">Question {{ i + 1 }}</mat-label>
                            <textarea readonly 
                                matInput 
                                formControlName="title"
                                autosize>
                            </textarea>
                        </mat-form-field>
                    </div>
        
                    <!-- Notes -->
                    <div>
                        <div class="w-full mt-4">
                            <mat-label class="font-semibold">Answer {{ i + 1 }}</mat-label>
                            <textarea readonly
                                  matInput 
                                  formControlName="notes"
                                  autosize class="answerText">
                              </textarea>
                           <!--  <div class="relative summaryTextEd">
                                <tiptap-editor 
                                    id="editor-with-bubble-menu-{{i}}"
                                    class="summarybox min-h-60"
                                    [editor]="answerEditors_readonly[i]" 
                                    formControlName="notes" 
                                    ngDefaultControl
                                >
                                </tiptap-editor>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!questionsArray?.controls?.length">
              There are no questions and answers in this theme. Please add from insights to view them.
            </div>
        </form>
        
    </div>
</fuse-drawer>


