import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgTemplateOutlet,NgClass, JsonPipe, NgStyle} from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input,Output,SimpleChanges,ViewChild,viewChild,ElementRef, Renderer2 } from '@angular/core';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor, ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { marked } from 'marked';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

import { ProposalService } from 'app/core/proposal/proposal.service';
import { AddEpicComponent } from '../dialogs/add-epic/add-epic.component';
import { AddThemeComponent } from '../dialogs/add-theme/add-theme.component';
import { AddFeatureComponent } from '../dialogs/add-feature/add-feature.component';
import { CommonService } from 'app/core/common/common.service';
import { filter, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { FeatureComponent } from '../feature/feature.component';
import { NpsComponent } from '../../nps/nps.component';
import { DOMSerializer } from '@tiptap/pm/model';
import { StoryTableComponent } from 'app/modules/user/common/story-table/story-table.component';
import { TestCaseTableComponent } from 'app/modules/user/common/test-case-table/test-case-table.component';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { JiraIntegrationComponent } from 'app/modules/common/jira-integration/jira-integration.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RegenerateInsightsViewComponent } from "../dialogs/regenerate-insights-view/regenerate-insights-view.component";
import { RegeneratingProgressBarComponent } from "../../../common/regenerating-progress-bar/regenerating-progress-bar.component";

@Component({
  selector: 'app-workspace-view',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf, FormsModule, NgxTiptapModule, AddThemeComponent, StoryTableComponent, TestCaseTableComponent, EditorControlsComponent, NgClass, AddFeatureComponent, JsonPipe, FuseDrawerComponent, NgTemplateOutlet, AddEpicComponent, MatFormFieldModule, NgFor, MatSelectModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule, AddThemeComponent, FuseAlertComponent, RegenerateInsightsViewComponent, RegeneratingProgressBarComponent],
  templateUrl: './workspace-view.component.html',
  styleUrl: './workspace-view.component.scss'
})
export class WorkspaceViewComponent {
  
  @ViewChild(FuseDrawerComponent) insightDrawer: FuseDrawerComponent;
  private unlistenClickOutside: () => void;
  accordion = viewChild.required(MatAccordion);
  //expandAll:boolean=true;
  expandState:number=0
  //expandChild:boolean = true;
  isSyncingProject:boolean=false;
  private selectedFeatureData={
    type:"",
    index:0
  }
  _proposalDetail: any = {};
  _testplanDetail: any = {};
  notSyncedTestCases=0;
  isRegenerating=false;

  @ViewChild('addFeature') addFeature: AddFeatureComponent;
  @ViewChild('addTheme') addThemeDrawer: AddThemeComponent;
  @ViewChild('addEpic') addEpicDrawer: AddEpicComponent;
  @ViewChild("regenDrawer") regenDrawer:RegenerateInsightsViewComponent;
 
  @Input() set proposal(value: any) {
    if (value) {
      this._proposalDetail = value;
      this.ngOnInit();
      this._changeDetectorRef.detectChanges();
    }
  }

  @Input() set testplan(value: any) {
    if (value) {
      this._testplanDetail = value;
      this.ngOnInit();
      this._changeDetectorRef.detectChanges();
    }
  }

  @Input()
  themeTypes:any=[];


  @Input()
  all_categories:any[]=[];

  @Input()
  _page_id:any;

  @Input()
  is_write_allowed:any;




  flashMessage: string;
  showModal;
  flashMessageType: 'success' | 'error' | null = null;
  epicTypes:any=[];
  proposalInsights:any[]=[];
  answerEditors_readonly: Editor[] = []; 
  selectedInsight=null;
  complexities: any[] = [];
  filterApplied: boolean = false;
  searchSolAct: boolean =false;
  questionsArray: FormArray<any>;

  paymentDetails$: Observable<any>;
  inputFormGroup:UntypedFormGroup;
  insightquestionForm:UntypedFormGroup;
  selectedFeature: any;

  requirements:any=[];
  themeListTypes: any [] = [];
  themeEpicTypes: any[] = [];
  themesExpanded:boolean[]=[];
  releases_list:any=[];
  all_original_release_list:any=[];
  epicsExpanded:any=[];
  testcaseList:any[] = [];
  filterselectedEpics: any[]=[];
  filterselectedThemes: any[]=[];

  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  insideTextArea=false;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  quickSelectedText="";
  menuPosition = { top: '500px', left: '80px' };
  mouseDownPos=0;
  mouseInitialPosition=null;
  selectedTargetIdEditor: string = '';
  
  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {console.log(editor);
      this.updateSelectedText(editor);
    },
  });

  editor3 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });


  editor4 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });


  editor_feature_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_solution_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });










  isJiraError:boolean=false;
  jiraErrorMessage="";

  @Output()
  onRefreshProposal=new EventEmitter();
  
  @Output()
  onSaveEpics=new EventEmitter<any>();

  @Input() receiveData: string;

  @Output()
  onSaveThemeAndEpics=new EventEmitter<any>();

  @Output() onFeatureUpdate=new EventEmitter<any>();

  readonly dialog = inject(MatDialog);
  constructor(private websocketService:WebsocketService,private _formBuilder: UntypedFormBuilder,private atlassianService:AtlassianService,
    private _commonService: CommonService, private _proposalService:ProposalService,private _changeDetectorRef: ChangeDetectorRef,private fuseDrawer:FuseDrawerService,private renderer: Renderer2, private el: ElementRef){


      

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "sync_project_with_jira")
      ).subscribe((message:any) => {
        this.isSyncingProject=false;
       this.initProjectStories();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "epic_solutions_regenerated")
      ).subscribe((message:any) => {
        this.onRefreshProposal.emit();
      });
    

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "epic_regenerated")
      ).subscribe((message:any) => {
        console.log("Epic Regenerated:",message);
       this.onRefreshProposal.emit();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "theme_regenerated")
      ).subscribe((message:any) => {
        console.log("Theme Regenerated:",message);
       this.onRefreshProposal.emit();
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "jira_sync_error")
      ).subscribe((message:any) => {
        this.isSyncingProject=false;
        this.isJiraError=true;
        
        const errorValues = Object.values(message.data.errors)
        .map((value, index) => `${index + 1}. ${value} `);
        this.jiraErrorMessage=`Jira sync failed: ${errorValues}`;
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "story_synced")
      ).subscribe((message:any) => {
        console.log("Story Synced:",message);
        this.updateSyncedStory(message.data);


      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "test_case_synced")
      ).subscribe((message:any) => {
        console.log("Workspace Test Case Synced:",message);
  
        this.updateSyncedTestCase(message.data);
        this.initTestCasesSyncStats();
  
      });


    }
    allStories=[];
  ngOnInit(){
    this.resetRequirements();
    this.resetToAllEpics();
    this.initProposalInsights();
    this.complexities = this._commonService.complexities;
    this.inputFormGroup = this._formBuilder.group({
      theme: [[]],
      search: [''],
      epic_name: [[]],
      release: [[]],
    });
    this.insightquestionForm = this._formBuilder.group({
      questions: this._formBuilder.array([]) // Empty FormArray initially
    });
    this.questionsArray = this.insightquestionForm.get('questions') as FormArray;
    if(this._proposalDetail['epics'] && this._proposalDetail['epics'].length){
      this.themeListTypes = [];
      this._proposalDetail['epics'].forEach(element => {
        let current = {label:element.theme,value:element.theme,epics:[]};
        if(element.epics && element.epics.length){
          element.epics.forEach(epicelement => {
            current['epics'].push({label:epicelement.name,value:epicelement.name})
          });
        }
        this.themeListTypes.push(current);
      });
    }
    this.releases_list = JSON.parse(JSON.stringify(this._proposalDetail.releases_list));
    this.releases_list.forEach(release => {
      release.requirements = release.requirements || [];
    });
    this._proposalDetail.epics.forEach(epic => {
      epic.epics.forEach(subEpic => {
        subEpic.features.forEach(requirement => {
          if (requirement.release) {
            let release = this.releases_list.findIndex(release => release._id === requirement.release._id);
            if (release>-1) {
              requirement['theme'] = {_id: epic._id, title:epic.theme};
              requirement['epic'] = {_id: subEpic._id, title:subEpic.name};
              this.releases_list[release].requirements.push(requirement);
            }
          }
        });
      });
    });
    this.all_original_release_list = JSON.parse(JSON.stringify(this.releases_list));
    this.initProjectStories();

    if(this._page_id=="testing"){
      this.initTestCasesSyncStats();
    }

    
  }
  initTestCasesSyncStats(){
    if(this._testplanDetail && this._testplanDetail._id){
    this.atlassianService.getTestCaseNotSyncedCount(this._testplanDetail._id).subscribe((response)=>{
      if(response && response.success){
        this.notSyncedTestCases=response.data;
      }
    });
  }
  }

  initProjectStories(){
    this.allStories=[];
    this.atlassianService.getProjectStories(this._proposalDetail._id).subscribe((response)=>{
      if(response.success){
      this.allStories=response.data;
      this.initStories();
      }
    });
  }
  updateSyncedTestCase(data){
    for(let testcase of this.testcaseList){
      if(testcase._id==data.testCase){
        
        testcase.jiraID=data.jiraID;
        testcase.jiraKey=data.jiraKey;
        testcase.jiraSynced=true;
        testcase.isSyncing=false;

      }
    }
  }
  updateSyncedStory(data){


    for(let story of this.allStories){
      if(story._id==data.story){
        
        story.jiraIssueID=data.jiraID;
        story.jiraKey=data.jiraKey;
        story.jiraSynced=true;
        story.isSyncing=false;



          /*
        this.requirements.forEach((theme)=>{
          theme.epics.forEach((epic)=>{
            epic.stories.forEach((myStory)=>{
              
              if(myStory._id==data.story){
                myStory.jiraIssueID=data.jiraID;
                myStory.jiraKey=data.jiraKey;
                myStory.jiraSynced=true;
                myStory.isSyncing=false;

                console.log("Story Found",myStory);
              }
            })
          });
        });
        */
         
       // this.initStories();



        break;
      }
    }
  }
  onStartStorySyncing(storyID){
    for(let story of this.allStories){
      if(story._id==storyID){
      story.isSyncing=true;
      break;
      }
    }
  }

  initThemesExpandedState(){
    this.themesExpanded=[];
    this.epicsExpanded=[];
    let tIndex=0;
    
    for(let theme of this.requirements){
      this.themesExpanded.push(true);
      let eIndex=0;
      for(let epic of theme.epics){
        let key=`${tIndex}-${eIndex}`;
        this.epicsExpanded[key]=true;
        eIndex++;
      }
      tIndex++;
    }
  }

  getEpicKey(themeIndex,epicIndex){
    return `${themeIndex}-${epicIndex}`;
  }

  getEpicExpanded(themeIndex,epicIndex){
    let key=`${themeIndex}-${epicIndex}`;
    return this.epicsExpanded[key];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receiveData'] && this.receiveData=='refresh') {
      console.log('Data received from Parent: ', this.receiveData);
      this.resetRequirements();
      this.resetToAllEpics();
      this.initProposalInsights();
    }
  }

  private getThemeEpicSolutions(theme:string,epic:string){
    let allSolutions=[];
    for(let feature of this._proposalDetail.features){
      if(feature.theme==theme && feature.epic==epic){
        if(feature.release){
          let release_shortname = this.releases_list.find(el=>el._id==feature.release);
          if(release_shortname)feature['release_shortname'] = release_shortname.short_name;
        }
        allSolutions.push(feature);
      }
    }
    return allSolutions;
  }

  updateSelectedText(editor: Editor): void {
    // const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, '\n');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectionStartIndex = from;
    // this.quickSelectionEndIndex = to; console.log(from, to);
    // const selectedHTML = editor.state.doc.textBetween(from, to, '\n');
    // this.beforeHTML = editor.state.doc.textBetween(0, from, '\n');
    // this.afterHTML = editor.state.doc.textBetween(to, editor.state.doc.content.size, '\n');
    // console.log(this.tabGroupForm.get('executiveSummary').value);
    // console.log("beforeHTML:", this.beforeHTML);
    // console.log("afterHTML:", this.afterHTML);
    console.log("editor:", editor);
    const { from, to } = editor.state.selection;
    console.log("from:", from);
    console.log("to:", to);
    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
  }

  initSolutions() {
    this.requirements.forEach((theme) => {
      theme.epics.forEach((epic) => {
        let solutions = this.getThemeEpicSolutions(theme.theme, epic.name);
        epic.solutions = solutions;
      });
    });
  }

  search(term){
    this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
    );
  this.initProjectStories();

  }

  resetRequirements(){
    this.requirements=[...this._proposalDetail.epics];
     
    let index=0;
    for(let i=0;i<this.requirements.length;i++){
      for(let j=0;j<this.requirements[i].epics.length;j++){
        this.requirements[i].epics[j]["index"]=index;
        index++;
      }
    }


    this.initSolutions();
    this.initThemesExpandedState();
  }

  initStories(){
    this.requirements = this.requirements.map((theme) => ({
      ...theme,
      epics: theme.epics.map((epic) => ({
        ...epic,
        stories: this.getEpicStories(theme.theme, epic.name) || []
      }))
    }));

  }

  resetToAllEpics(){
    this.epicTypes = this._proposalDetail.epics?.flatMap((epic) => {
      return epic.epics.map((themeEpic) => {
        return { label: themeEpic.name, value: themeEpic.name };
      });
    }) ?? [];    
  }

  toggleDetails(productId: string,pro_data,index): void
    {
        // If the product is already selected...
        if ( this.selectedFeature && this.selectedFeature['_id'] === productId && this.selectedFeature['index']=== index )
        {
          // Close the details
          this.closeDetails();
          return;
        }else{
          this.selectedFeature = pro_data.find(o => o._id === productId);
          this.selectedFeature['index'] = index;
          if(this.selectedFeature['theme']){
            let findTheme = this.themeListTypes.find(el=>el.value==this.selectedFeature.theme);
            if(findTheme && findTheme.epics){
              this.themeEpicTypes = findTheme.epics;
            }
          }
          this._changeDetectorRef.detectChanges();
        }
  }

  featureComplexityChange(event,solution){
    // let findFeature = this.original_features.find(el=>el._id==infoValues._id);
    // console.log("event",event);
    // console.log("solution",solution);
    let component = parseInt(solution['componentsCount']);
    if(component>0 && solution.masterFeatureID){
      if(event.value=='Low')solution['effort'] = component*solution.masterFeatureID['lowEffort'];
      else if(event.value=='Medium')solution['effort'] = component*solution.masterFeatureID['mediumEffort'];
      else if(event.value=='High')solution['effort'] = component*solution.masterFeatureID['highEffort'];
    }
    // console.log("solution",solution);
  }

  featureThemeChange(event,inputFormGroupelement){
    // console.log(event);
    let findTheme = this.themeListTypes.find(el=>el.value==event.value);
    if(findTheme && findTheme.epics){
      this.themeEpicTypes = findTheme.epics;
    }
    // console.log("findTheme",findTheme);console.log("infoValues",this.themeEpicTypes);
  }

  onComponentsCountChange(value: any,solution): void {
    let effort_val = 0;
    if(solution.masterFeatureID){
      if(solution.complexity=='Low')effort_val = solution.masterFeatureID['lowEffort'];
      else if(solution.complexity=='Medium')effort_val = solution.masterFeatureID['mediumEffort'];
      else if(solution.complexity=='High')effort_val = solution.masterFeatureID['highEffort'];
      let calcVal = solution.componentsCount*effort_val;
      solution['effort'] = calcVal;
    }
  }

  updateSelectedFeature(solution): void
  {
    
    // console.log(inputFormGroup.value);return;
    this._proposalService.update({id:this._proposalDetail._id,feature_action:'update',feature_data:solution})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.showFlashMessage('success');
                this.flashMessage = 'Solution updated successfully!';
                if(this.showModal){
                  const dialogRef = this.dialog.open(NpsComponent,{ 
                    panelClass: 'custom-dialog-container-nps',
                    disableClose: true,
                    data: {
                      category: this._proposalDetail.category
                    }
                  });
                }
                setTimeout(() =>
                {
                  // this.closeDetails();
                  this.onFeatureUpdate.emit({refresh:true});
                }, 2000);
              }
              else this.showFlashMessage('error');
            },
            (response) =>
            {
            },
        );
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;


      // Hide it after 3 seconds
      setTimeout(() =>
      {
          this.flashMessageType = null;

      }, 1000);
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){ 
    this.insideTextArea=true;
  }

  onMouseLeave(){ 
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }
  onRegenerateEpicInitiated(event){
    console.log("Regenerate Epic called",event);
    let isTestPlan=this._page_id=="testing"?true:false;
    let testPlan="";
    if(isTestPlan){
      testPlan=this._testplanDetail._id;
    }
    this.websocketService.sendRegenerateEpicCommand(isTestPlan,event.theme._id,event.epic._id,this._proposalDetail._id,testPlan,"12345");

    for(let theme of this._proposalDetail.epics){
      if(theme._id==event.theme._id){
        for(let epic of theme.epics){
          if(epic._id==event.epic._id){
            epic.isRegenerating=true;
           break;
          }
        }
      }
    }

    for(let theme of this.requirements){
      if(theme._id==event.theme._id){
        for(let epic of theme.epics){
          if(epic._id==event.epic._id){
            epic.isRegenerating=true;
           break;
          }
        }
      }
    }
    this.isRegenerating=true;
    this.isRegenerating=false;

   

    this._changeDetectorRef.detectChanges();

  }
  getRegeneratorViewType(){
    if(this._page_id=="testing"){
      return 2;
    }
    
    if(this._proposalDetail.category=="Proposal"){
      return 0;
    }
    if(this._proposalDetail.category=="Project"){
      return 1;
    }
  }
  onRegenerateThemeInitiated(event){
    console.log("Regenerate Theme called",event);

    let isTestPlan=this._page_id=="testing"?true:false;
    let testPlan="";
    if(isTestPlan){
      testPlan=this._testplanDetail._id;
    }
    this.websocketService.sendRegenerateThemeCommand(isTestPlan,event.theme._id,"",this._proposalDetail._id,testPlan,"12345");

    for(let theme of this._proposalDetail.epics){
      if(theme._id==event.theme._id){
        theme.isRegenerating=true;
       break;
      }
    }
    for(let theme of this.requirements){
      if(theme._id==event.theme._id){
        theme.isRegenerating=true;
      }
    }
    this.isRegenerating=true;
    this.isRegenerating=false;
    this._changeDetectorRef.detectChanges();
  }
  onMouseDown(event){

    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
     }
    // console.log("On Selection Change:",event.clientX,event.clientY);
     const textarea = event.target as HTMLTextAreaElement;
    // const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    //const targetId = (event.target as HTMLElement).id;
    //console.log('Target element ID:', targetId);

    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    // this.quickSelectedText=selectedText;
    if (this.quickSelectedText.trim().length > 0) {
      
     /*
      if(event.pageY<this.mouseDownPos){

      
      this.menuPosition = {
          top: `${event.pageY-90}px`,
          left: this.menuPosition.left
      };

      }
      */

      /*
        this.menuPosition = {
            top: `${clientY + window.scrollY -30}px`,
            left: `${90+window.scrollX}px`
        };
*/
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

  onTextSelect(event,targetId){ 
    console.log("Event:",event);
    if(!this.is_write_allowed){
     return;
    }
     // const targetId = (event.target as HTMLElement).id;
    console.log('Target element ID:', targetId);
 
     if(targetId.includes("description")||targetId.includes("solution")){
       let data=targetId.split("-");
       this.selectedFeatureData={
         type:data[0],
         index:parseInt(data[1])
       };
     }else{
       this.selectedTargetIdEditor = targetId;
       this.selectedFeatureData={
         type:"",
         index:0
       };
     }
     // console.log('selectedFeatureData', this.selectedFeatureData);
     this.mouseInitialPosition=event.clientY;
     //This code place menu at bottom of selection
     const textarea = event.target as HTMLTextAreaElement;
 
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
         let last=event.pageY-120;
         if(last<this.mouseDownPos){
           const screenWidth = window.innerWidth;
           const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;
 
         this.menuPosition = {
             top: `${last}px`,
             left: `${xPosition}px`
         };
 
         }
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
  }

  closeDetails(): void
    {
      this.selectedFeature = null;
    }

  onChangeTheme(event){
    // console.log(event.value);
    let selectedThemes=event.value;
    this.filterselectedThemes = event.value;
    if(selectedThemes.length){
      this.filterApplied = true;
      this.epicTypes=[];
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.epicTypes.push(...epics);
        }
      }
    }else{
      if(!this.filterselectedEpics.length && !this.filterselectedThemes.length)this.filterApplied = false;
      this.resetToAllEpics();
    }
    this.initProjectStories();
    this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
    );

  
  }

  onChangeEpic(event){
    // console.log(event.value);
    this.filterselectedEpics=event.value;
    if(this.filterselectedEpics.length){
      this.filterApplied = true;
    }else{
      if(!this.filterselectedEpics.length && !this.filterselectedThemes.length)this.filterApplied = false;
    }
    this.initProjectStories();
    this.filterRequirements(this.inputFormGroup.get("search").value,
      this.inputFormGroup.get("theme").value,
      this.inputFormGroup.get("epic_name").value,
      this.inputFormGroup.get("release").value
    );
  }

  trackByFn(index: number, item: any): any
  {
      return item.id || index;
  }

  dropTheme(event){
    moveItemInArray(this.requirements, event.previousIndex, event.currentIndex);
    moveItemInArray(this._proposalDetail.epics, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  dropEpic(event,themeIndex){
      
    moveItemInArray(this.requirements[themeIndex].epics, event.previousIndex, event.currentIndex);
  // moveItemInArray(this._proposalDetail.epics[themeIndex].epics, event.previousIndex, event.currentIndex);

    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  dropSolution(event,themeIndex,epicIndex){
    //console.log("On Drop Solution:",event,themeIndex,epicIndex);
    moveItemInArray(this.requirements[themeIndex].epics[epicIndex].solutions, event.previousIndex, event.currentIndex);
      

  }

  dropRequirement(event,themeIndex,epicIndex){
    //On Drop Requirement
    moveItemInArray(this.requirements[themeIndex].epics[epicIndex].features, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  onAddNewFeature(){
    this.fuseDrawer.getComponent("themeDrawer").toggle();
  }

  onToggleAccordion(expand){
    // this.expandState++;
    // console.log("Current State:",this.expandState);
    // if(this.expandState>=3){
    //   this.expandState=0;
    // }

    this.expandState = expand;
    //0 =expanded all 1= themes expanded , epics collapsed  2= all collapsed
    let expandThemes=true;
    let expandEpics=true
    if(this.expandState==0){
      expandThemes=true;
      expandEpics=true;
    }else if(this.expandState==1){
      expandThemes=true;
      expandEpics=false;
    }else if(this.expandState==2){
      expandThemes=false;
      expandEpics=false;
    }
    
      this.themesExpanded=this.themesExpanded.map((theme)=>expandThemes);

      let tIndex=0;
    
      for(let theme of this.requirements){
        this.themesExpanded.push(true);
        let eIndex=0;
        for(let epic of theme.epics){
          let key=`${tIndex}-${eIndex}`;
          this.epicsExpanded[key]=expandEpics;
          eIndex++;
        }
        tIndex++;
      }
       

      this._changeDetectorRef.detectChanges();
       

  }

  filterRequirements(
    searchTerm: string,
    selectedThemes: string[],
    selectedEpics: string[],
    selectedReleases: string[]
  ) {

    if(searchTerm=="" && !selectedThemes.length && !selectedEpics.length && !selectedReleases.length){
      this.resetRequirements();
      return;
    }
    this.resetRequirements();

    this.requirements=this.requirements
    .filter((requirement) => {
      // Apply theme filter if selectedThemes is not empty
      if (selectedThemes.length > 0) {
        return selectedThemes.includes(requirement.theme);
      }
      return true; // No theme filter applied, include all
    })
    .map((requirement) => {
      // Filter epics within the theme
      const filteredEpics = requirement.epics.filter((epic) => {
        // Apply epic filter only if selectedEpics is not empty
        const matchesEpic = selectedEpics.length === 0 || selectedEpics.includes(epic.name);

        // Apply search term filter only if searchTerm is not empty
        const matchesSearchTerm =
          searchTerm === "" ||
          epic.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          requirement.theme.toLowerCase().includes(searchTerm.toLowerCase()) ||
          epic.features.some((feature) =>
            feature.title.toLowerCase().includes(searchTerm.toLowerCase())
          );

        return matchesEpic && matchesSearchTerm;
      });

      return { ...requirement, epics: filteredEpics };
    })
    .filter((requirement) => requirement.epics.length > 0); // Only include requirements with epics
    
    if(selectedReleases.length>0){
      let themes_epic = [];
      let list_requirements = JSON.parse(JSON.stringify(this.requirements));
      list_requirements.forEach(elepics => {
        let epic_epics = [];
        elepics.epics.forEach(epic => {
          epic.features = epic.features.filter(feature => selectedReleases.indexOf(feature.release) >= 0);
          epic.solutions = epic.solutions.filter(solution => selectedReleases.indexOf(solution.release) >= 0);
          if(epic.features.length || epic.solutions.length)epic_epics.push(epic);
        });
        if(epic_epics.length)themes_epic.push(elepics);
      });
      this.requirements = themes_epic;
    }
   
  
  }

  onClickAddSolution(theme,epic){

    
    this._proposalDetail['dialog_type'] = "addfeature";
    this._proposalDetail['selected_theme'] = theme;
    this._proposalDetail['selected_epic'] = epic;

    const dialogRef = this.dialog.open(FeatureComponent,
      {
        panelClass: 'add-feature-dialog',
        disableClose: true,
        data: this._proposalDetail,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log("on Add Feature: ",result);
      this.onRefreshProposal.emit();
    });

  }

  getThemeFeatures(theme){
    let allFeatures=[];
    for(let epic of theme.epics){
      allFeatures.push(...epic.features);
    }
    return allFeatures;
  }

  onClickAddTheme(){
    this.addThemeDrawer.isEdit=false;
      this.addThemeDrawer.themeForm.get("themeName").setValue("");
      this.addThemeDrawer.themeDrawer.toggle();

  }

  onClickDeleteSolution(featureID,theme,epic){
    this._proposalService.update({id:this._proposalDetail._id,feature_action:'delete',feature_data:{_id:featureID}})
        .subscribe(
          (value) =>
          {
            if(value.status){
              this._proposalDetail.features.forEach((feature, index) => {
                if (feature._id === featureID && feature.theme==theme && feature.epic==epic) {
                  this._proposalDetail.features.splice(index, 1);
                  this.initSolutions();
                }
              });
            }
          
            });

  }

  onDeleteTheme(requirement:any){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this theme? All associated epics, requirements, solutions, and insights will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==requirement.theme){
            this._proposalDetail.epics.splice(i,1);
            this.themeTypes.splice(i,1);
            let capIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==requirement.theme);
            this._proposalDetail.businessCapabilities.splice(capIndex,1);
            this._proposalDetail.ValueChanged = {oldTheme:requirement.theme,newTheme:''};
            break;
          }
        }
        this.resetRequirements();
        this.initProjectStories();
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities,ValueChanged:this._proposalDetail.ValueChanged});
      }
    });
  }

  onEditTheme(theme:any){
    this.addThemeDrawer.isEdit=true;
    let index=this._proposalDetail.epics.findIndex((req)=>req.theme==theme.theme);
    this.addThemeDrawer.selectedIndex=index;
    this.addThemeDrawer.themeForm.get("themeName").setValue(theme.theme);
    this.addThemeDrawer.themeDrawer.toggle();
  }

  onClickAddEpic(){
      this.addEpicDrawer.isEdit=false;
      this.addEpicDrawer.themeForm.get("epicName").setValue("");
      this.addEpicDrawer.themeForm.get("theme").setValue("");
      this.addEpicDrawer.epicDrawer.toggle();
  }

  onClickAddFeature(theme,epic){
    // console.log(this.addFeature);
    this.addFeature.isEdit=false;
    this.addFeature.selectedIndex=-1;
    this.addFeature.themeForm.get("feature").setValue("");
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeForm.get("release").setValue("");
    this.addFeature.themeDrawer.toggle();
  }

  onSaveTheme(theme){

    if(theme.isEdit){
        let oldTheme=this._proposalDetail.epics[theme.index].theme;
        let capabilityIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==oldTheme);
        this._proposalDetail.businessCapabilities[capabilityIndex]=theme.theme;
        this._proposalDetail.ValueChanged = {oldTheme:oldTheme,newTheme:theme.theme};

      this._proposalDetail.epics[theme.index].theme=theme.theme;
      this._proposalDetail.epics[theme.index].pendingJiraUpdates=true;

      let themeTypeIndex=this.themeTypes.findIndex((tType)=>tType.value==oldTheme);
      if(themeTypeIndex>=0){
        this.themeTypes[themeTypeIndex].value=theme.theme;
        this.themeTypes[themeTypeIndex].label=theme.theme;
      }


    }else{
      this._proposalDetail.epics.push({theme:theme.theme,epics:[]});
      this.themeTypes.push({value:theme.theme,label:theme.theme});
      this._proposalDetail.businessCapabilities.push(theme.theme);
      this._proposalDetail.ValueChanged = {oldTheme:'',newTheme:theme.theme};
    }
    this.resetRequirements();
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities,ValueChanged:this._proposalDetail.ValueChanged});
  }

  onEditEpic(theme,epic){
    this.addEpicDrawer.isEdit=true;
    this.addEpicDrawer.oldEpic=epic;
    this.addEpicDrawer.themeForm.get("epicName").setValue(epic);
    this.addEpicDrawer.themeForm.get("theme").setValue(theme);
    this.addEpicDrawer.epicDrawer.toggle();
  }
  
  onDeleteEpic(theme,selectedEpic){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this epic? All associated features, and, solutions will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==theme){
            let epicIndex=this._proposalDetail.epics[i].epics.findIndex((epic)=>epic.name==selectedEpic);
            if(epicIndex>=0){
              this._proposalDetail.epics[i].epics.splice(epicIndex,1);
            }
            break;
          }
        }
        this.resetRequirements();
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
      }
    });
  }

  onSaveEpic(event){
    if(event.isEdit){
      this._proposalDetail.epics.forEach(theme => {

        if(theme.theme==event.theme){
          let epicIndex=theme.epics.findIndex((ep)=>ep.name==event.oldEpic);
          if(epicIndex>=0){
            theme.epics[epicIndex].name=event.epic;
            this._proposalDetail.ValueChanged = {oldEpic:event.oldEpic,newEpic:event.epic};
            theme.epics[epicIndex].pendingJiraUpdates=true;
          }
        }
      });
    }else{
    this._proposalDetail.epics.forEach(theme => {

      if(theme.theme==event.theme){
        theme.epics.push({name:event.epic,features:[]});

      }

    });
  }
    
    this.onChangeTheme({value:this.inputFormGroup.get("theme").value});

    this.resetRequirements();
    this.onSaveEpics.emit({epics:this._proposalDetail.epics,ValueChanged:this._proposalDetail.ValueChanged});
  }

  onSaveFeature(event:any){
    if(!event.isEdit){
      //Add New Mode
    this._proposalDetail.epics.forEach(theme => {
      if(theme.theme==event.theme){
        theme.epics.forEach((epic)=>{
          if(epic.name==event.epic){
            if(epic.features){
            epic.features.push({title:event.feature,release:event.release});
            }else{
              epic.features=[{title:event.feature,release:event.release}];
            }
          }
        });
      }
    });
  }else{
      //Edit Mode
      if(event.index!=-1){
        this._proposalDetail.epics.forEach(theme => {
          if(theme.theme==event.theme){
  
            theme.epics.forEach((epic)=>{
  
              if(epic.name==event.epic){
                
                epic.features[event.index]={title:event.feature,release:event.release};
                
              }
  
            });
  
          }
        });
      }
  }
  this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onDeleteFeatureCallback(event){
    this.onDeleteFeature(event.theme,event.epic,event.feature,event.index);
  }

  onDeleteFeature(selectedTheme,selectedEpic,feature,index){

    this._proposalDetail.epics.forEach(theme => {
      if (theme.theme == selectedTheme) {
          theme.epics.forEach((epic) => {
              if (epic.name == selectedEpic) {
                  if (epic.features && index !== -1 && index < epic.features.length) {

                          epic.features.splice(index, 1);
                  }
              }
          });
      }
  });
  this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onEditFeature(theme,epic,feature,index){
    this.addFeature.isEdit=true;
    this.addFeature.selectedIndex=index;
    this.addFeature.themeForm.get("feature").setValue(feature.title);
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.themeForm.get("release").setValue(feature.release);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeDrawer.toggle();
    // console.log(this.addFeature);
  }

  initProposalInsights(){
    this._proposalService.getProposalInsights(this._proposalDetail._id).subscribe((response)=>{
      if(response.success==true){
        this.proposalInsights=response.data;
      }
    });
  }

  onRequirementReleaseSelection(event,theme,epic,feature,index){
    this._proposalDetail.epics.forEach(eachtheme => {
      if(eachtheme.theme==theme){
        eachtheme.epics.forEach((eachepic)=>{
          if(eachepic.name==epic){   
            eachepic.features[index].release=event.value; 
          }
        });

      }
    });
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onSolutionReleaseSelection(event,feature,index){
    feature['release'] = event.value;
    this.updateSelectedFeature(feature);
  }

  async onRegenerateTheme(theme,themeIndex){

    console.log("on Regenerate Themes");
    if(theme.isRegenerating){
      return;
    }

    this.regenDrawer.drawer.toggle();
    
    this.regenDrawer.initRegenView(this.proposalInsights,theme,null,0);

    this.themesExpanded[themeIndex]=true;

  }
  async onRegenerateEpic(theme,epic,themeIndex,epicIndex){

    if(epic.isRegenerating){
      return;
    }

    this.regenDrawer.drawer.toggle();
    
    this.regenDrawer.initRegenView(this.proposalInsights,theme,epic,1);

    this.epicsExpanded[this.getEpicKey(themeIndex,epicIndex)]=true;

  }

  async onSelectInsight(theme,epic){
    // console.log(theme);
    if(this.proposalInsights.length){
      let insight = this.proposalInsights.filter(el=>el.capability==theme);
      // console.log(insight);
      // return;
      this.questionsArray.clear();
      this.answerEditors_readonly = [];
      if(insight.length && insight[0]['questions']){
        this.selectedInsight=insight[0];
        for (let question of insight[0]['questions']) {
          if(epic!=null){
            if(question['epic']!=epic.name){
            continue;
            }
          }
          let parsedNotes = await marked.parse(question.notes);
          const plainText = this.stripHtml(parsedNotes);
          const newEditor2 = new Editor({
            content: parsedNotes,
            extensions: [
              StarterKit.configure({
                bulletList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
                orderedList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
              }),
              BulletList,
              ListItem,
              Underline,
              TextStyle,
              Color,
              Placeholder.configure({
                placeholder: 'Describe your current process or how you envision it.',
              }),
              Heading.configure({
                levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
              })
            ],
            editable: false,
            enablePasteRules: false,
            parseOptions: {
              preserveWhitespace: "full",
            },
            editorProps: {
              attributes: {
                class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
                spellCheck: 'false',
              },
            }
          });
          this.answerEditors_readonly.push(newEditor2);
          
          this.questionsArray.push(this._formBuilder.group({
            title     : [question.title || ''],
            notes     : [plainText || ''],
            capability: [question.capability || '']
          }));
        };
      }
    }
    this._changeDetectorRef.detectChanges();
    this.paymentDetails$ = this.questionsArray.valueChanges;
    setTimeout(()=>{
      this.insightDrawer.open();
    },500);
    
  }

  onCompleteQuickAction(newValue){

    console.log("Value to update:",newValue);
    console.log("Selection:",this.quickSelectedText);
    console.log("selectedFeatureData:",this.selectedFeatureData);
    console.log(this.quickSelectionStartIndex,this.quickSelectionEndIndex);
    
    if(this.selectedFeatureData.type=="description"){
      const { from, to } = this.editor2.state.selection;
      const parser = ProseMirrorDOMParser.fromSchema(this.editor2.schema);
      const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
      const tr: Transaction = this.editor2.state.tr.replaceWith(from, to, replacementNode);
      const newTo = from + replacementNode.size;
      this.editor2.view.dispatch(tr);
      this.editor2.chain().focus().setTextSelection({ from, to: newTo }).run();
      this.showQuickPromptMenu=false;
    }
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();   
  }

  stripHtml(html: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || '';
  }
  regenOpenedChanged(event){
    console.log("Regenerate Drwaer opend event");
    if(event===false){
      this.clear();
    }
  }
  openedChanged(event){
    if(event===false){
      this.clear();
    }
    setTimeout(() => {
      // const editorElement1 = document.querySelector('#answerTextReadonly div');
      // if (editorElement1) {
      //   editorElement1.setAttribute('contenteditable', 'false');
      //   this.editor_readonly.setEditable(false);
      // }
      // const editorElement2 = document.querySelector('#documentTextReadonly div');
      // if (editorElement2) {
      //   editorElement2.setAttribute('contenteditable', 'false');
      //   this.editor2_readonly.setEditable(false);
      // }
    },1000);
  }

  openDialog(type,theme,epic) {
    this._proposalDetail['dialog_type'] = type;
    this._proposalDetail['selected_theme'] = theme;
    this._proposalDetail['selected_epic'] = epic;
    const dialogRef = this.dialog.open(FeatureComponent,
      { 
        panelClass: 'add-feature-dialog',
        disableClose: true,
        data: this._proposalDetail,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      this.onFeatureUpdate.emit({refresh:true});
    });
  }

  onClickJiraIntegrations(){
    const dialogRef = this.dialog.open(JiraIntegrationComponent,{ panelClass: 'custom-dialog-container',disableClose: true,data:{proposal:this._proposalDetail,proposalID:this._proposalDetail._id}  });
    dialogRef.afterClosed().subscribe(result => {
      if(result.saved){
        this.ngOnInit();
      }
    });
  }

  clear(){
    //reset values
    this.onCloseDrawer();
  }

  onCloseDrawer(){
    this.selectedInsight=null;
    this.insightDrawer.close();
  }

  onThemeUpdate(event){
    console.log(event);
  }
    
  onUpdateStories(pull:boolean){
    this.initProjectStories();
    this.onFeatureUpdate.emit({refresh:true});
  }

  getSolutionCategoryForRequirement(requirementID){
    const solutionCategory = this._proposalDetail.features.find(feature => feature.requirementID === requirementID);
    if(solutionCategory){
      return solutionCategory.solutionCategories.join(",");
    }

    return "";
  }

  getEpicStories(theme:string,epic:string){
    let epicStories=[];
    for(let eachstory of this.allStories){
      if(eachstory.theme==theme && eachstory.epic==epic){
        epicStories.push(eachstory);
      }
    }
    epicStories = epicStories.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    epicStories=epicStories.reverse();
    return epicStories;
  }

  getSyncLabel(){
    if(this._page_id=="testing"){
      return "Test Cases"
    }

    return "Stories";
  }

  getNotSyncedCount(){
    if(this._page_id=="testing"){
      return this.notSyncedTestCases;
    }else{
    let notSynced=0;
    if(!this.allStories){
      return 0;
    }
    for(let story of this.allStories){
      if(story.jiraSynced==false || story.pendingJiraUpdates){
        notSynced++;
      }
    }

    if(notSynced==0){
      this.isSyncingProject=false;
    }

    return notSynced;
  }
  }

  getIntegrationStatus(){
    if(this._proposalDetail && this._proposalDetail.integrations && this._proposalDetail.integrations.jira && this._proposalDetail.integrations.jira.active==true){
      return true;
    }

    return false;
  }

  onSyncProjectWithJira(){
    if(this.isSyncingProject){
      return;
    }
    this.isSyncingProject=true;

    if(this._page_id=="testing"){
      this.atlassianService.jiraSync({proposalID:this._proposalDetail._id,testPlanID:this._testplanDetail._id,type:"testcases"}).subscribe((response)=>{
        if(response.success){
         
        }
      });
    }else{
      this.atlassianService.syncProjectWithJira(this._proposalDetail._id).subscribe((response)=>{
        if(response.success){
         
        }
      });
    }

    
  }

  onRegenerateSolutions(proposalID,themeID,epicID,epic){
    if(epic.isRegenerating){
      return;
    }
    epic.isRegenerating=true;
    
    // console.log("On Regenerate Epic Solutions:",proposalID,themeID,epicID);

    this.websocketService.sendRegenerateSolutionCommand(proposalID,themeID,epicID);
  }


  
  serchSol(event: MouseEvent): void {
    event.stopPropagation(); // Prevent triggering outside click logic
    this.searchSolAct = !this.searchSolAct;

    if (this.searchSolAct) {
      this.attachOutsideClickListener();
    } else if (this.unlistenClickOutside) {
      this.unlistenClickOutside();
    }
  }

  private attachOutsideClickListener(): void {
    this.unlistenClickOutside = this.renderer.listen('document', 'click', (event: Event) => {
      const clickedInside = this.el.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.searchSolAct = false;
        this.unlistenClickOutside();
      }
    });
  }

  onDivClick(event: MouseEvent): void {
    event.stopPropagation();
  }

} 
