import { AsyncPipe, CurrencyPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { Router,ActivatedRoute } from '@angular/router';
import analytics_event from 'app/core/common/analytics_event';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { CommonService } from 'app/core/common/common.service';
import { finalize, Subject, takeUntil, Observable, Subscription } from 'rxjs';
import { IProposal } from 'app/core/proposal/proposal.types';
import { DateTime } from 'luxon';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AnalyticsService } from 'app/core/common/analytics_service';
import { PricingPlanService } from 'app/core/pricing-plan/pricing-plan.service';
import { IAgentDashboard, User, IAgentListDashboard } from 'app/core/user/user.types';
import { fuseAnimations } from '@fuse/animations';
import * as introJs from 'intro.js';
import { NewProposalComponent } from 'app/modules/auth/new-proposal/new-proposal.component';
import { ContactComponent } from '../build/contact/contact.component';
import moment from 'moment';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor, ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl } from '@angular/forms';

export interface AgentElement {
  agent_name: string;
  project_name: string;
  status: string;
  created_by: string;
  created_on:string;
}

@Component({
  selector: 'app-agents',
  standalone: true,
  imports: [MatIconModule, FormsModule, ReactiveFormsModule, AsyncPipe,MatInputModule,MatSelectModule, MatProgressBarModule,MatButtonModule,MatDialogModule,MatRippleModule,MatMenuModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, CurrencyPipe],
  templateUrl: './agents.component.html',
  styleUrl: './agents.component.scss',
  animations     : fuseAnimations
})
export class AgentsComponent {

  headerForm: UntypedFormGroup;
  isPaid: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  agentStats: IAgentDashboard | null = null;
  user: User;
  displayedColumns: string[] = ['agent', 'name', 'author','created'];
  dataSource: IAgentListDashboard[];
  originaldataSource: IAgentListDashboard[];
  agents: any[] = [
    {label: "Pre-Sales", value:"Pre-Sales Agent"},
    {label: "Design", value:"Design Agent"},
    {label: "Build", value:"Build Agent"},
    {label: "Test", value:"Test Agent"},
    {label: "Go-Live", value:"Go-Live Agent"},
  ];

  constructor(
    public dialog: MatDialog,
    public _router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _cdr: ChangeDetectorRef,
    private _analyticsService:AnalyticsService,
    private _formBuilder: UntypedFormBuilder,
    private _pricingPlanService: PricingPlanService
  ){
    this._userService.user$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((user: User) =>
    {
        this.user = user;
    });
    this._pricingPlanService.getStatus().subscribe((response) => {
      if(response)this.isPaid=((response['subscription_status']=='CONTINUE' && response.remaining_data.proposal.status) || (response['subscription_status']=='CANCELLED' && response.remaining_data.proposal.status))?true:false;
    });
    this._userService.getAgentStats().subscribe((data) => {
      this.agentStats = data;
    });
    // this._userService.getAgentList().subscribe((data) => {
    //   this.dataSource = data['data'];
    // });
    this.fetchAllAgents();
  }


  ngOnInit(): void
  {
    this.headerForm = this._formBuilder.group({
      search : [''],
      agent : ['']
    });
  }

  fetchAllAgents(): void {
    this.dataSource = []; // Initialize the dataSource
    this._userService.agentList$.subscribe({
        next: (agents) => {
            if (agents) {
                this.dataSource = agents;
                this.originaldataSource = agents;
                this._cdr.detectChanges();
            }
        },
        error: (err) => {
            console.error('Error fetching agents:', err);
        }
    });

    this._userService.getAgentList().subscribe({
        error: (err) => {
            console.error('Error triggering batch fetch:', err);
        }
    });
  }

  openDialog(type) {
    try{
      this._analyticsService.trackEvent(analytics_event.START_NEW_PROPOSAL,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
    }catch(error){ 
    }

    if(this.isPaid){
      const dialogRef = this.dialog.open(NewProposalComponent,
        { 
          panelClass: 'custom-dialog-container',
          disableClose: true,
          data: {
            category: type
          }
        });
      dialogRef.afterClosed().subscribe(result => {
        
      });
    }
    else {
      this._router.navigate(['/settings/pricing']);
    }
  }

  request() {
    const dialogRef = this.dialog.open(ContactComponent,
      { 
        panelClass: 'custom-dialog-container',
        disableClose: true,
        data: {
          category: 'Testing'
        }
      });

      this._analyticsService.trackEvent(analytics_event.BUILD_REQUEST_EARLY_ACCESS,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
  }

  navigate(element){
    if(element.category=='Proposal')this._router.navigate(['/proposal',element._id]);
    if(element.category=='Project')this._router.navigate(['/project',element._id]);
    if(element.category=='Test Plan')this._router.navigate(['/testing',element._id]);
  }

  filterCount() {
    const self = this;
    const alldata = JSON.parse(JSON.stringify(self.originaldataSource));
    const agent = this.headerForm.get('agent').value || [];
    const searchValue = this.headerForm.get('search').value.toLowerCase();

    let filteredData = alldata.filter(obj => {
        return Object.values(obj).some(val =>
            typeof val === 'string' && val.toLowerCase().includes(searchValue)
        );
    });

    if (agent.length) {
        filteredData = filteredData.filter(item => agent.indexOf(item.agent) >= 0);
    }

    this.dataSource = filteredData.length ? filteredData : [];
    this._cdr.detectChanges();
  }

  demo(){
    window.open('https://calendly.com/amit-bwf4/30min', '_blank');
  }

  format(date){
    return moment(date).format('DD MMM, YYYY');
  }

}
