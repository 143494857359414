<app-add-feature #addFeature (onDeleteFeature)="onDeleteFeatureCallback($event)"  (onSaveFeature)="onSaveFeature($event)" [epics]="_proposalDetail.epics" [themes]="themeTypes" [releases_list]="releases_list"></app-add-feature>
<div class="flex actBt flex-wrap items-center mt-8 mb-8 gap-3 justify-between">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Manage Releases</div>
        <div class="text-[#94A3B8] text-md font-normal"> Manage and finalize project releases and backlog.</div> 
    </div>
        <!-- Add product button -->
    <div *ngIf="is_write_allowed" class="add-get-ai-bt flex gap-3">
        <button class="addThemeBt" mat-flat-button [color]="'primary'" (click)="relDrawer.toggle()"  >
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="">Add New Release </span>
        </button>
    </div>
  </div>
  <div class="bg-card rounded-lg .min-h-120 shadow pb-0 py-3 mt-6">
      <!-- Header Section -->
    <section class="flex flex-row flex-wrap justify-end px-3">
        
        <form class="flex flex-wrap gap-2" [formGroup]="inputFormGroup">
            <mat-form-field class="fuse-mat-dense searchTab">
                <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input matInput formControlName="search" [autocomplete]="'off'" [placeholder]="'Search'" (input)="search($event)" >
            </mat-form-field>
            <!-- Add filters -->
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
                <mat-select
                    [formControlName]="'theme'"
                    [placeholder]="'Themes'" multiple (selectionChange)="filterCount()">
                    @for (option of themeTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}} </mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
                <mat-select
                    [formControlName]="'epic_name'"
                    [placeholder]="'Epic Name'" multiple (selectionChange)="filterCount()">
                    @for (option of epicTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
            </mat-form-field>
    
        </form>
    
    </section>
    <div class="loader text-center mt-6"  *ngIf="load_release">
        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <!-- <div class="kanbanview" *ngIf="(releases_list.length && !load_release)">
        <div class="kanbanTab" *ngFor="let eachRelease of releases_list;let i = index;">
            <div class="kanbanHead">
                <h3>{{eachRelease.name}}</h3>
                <span class="noRequire">{{(eachRelease.requirements && eachRelease.requirements.length)?eachRelease.requirements.length:0}}</span>
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu" class="moreBt">
                        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>                                     
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item >
                            <mat-icon [svgIcon]="'heroicons_outline:pencil-square'"></mat-icon>
                            <span>Edit Release </span>
                        </button>
                        <button  mat-menu-item>
                            <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                            <span>Delete Release </span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="requirementList">
                <div class="bg-card card flex flex-col gap-2 p-2 rounded shadow" *ngFor="let eachRequirements of eachRelease.requirements;let i = index;">
                    <div class="cardTag">
                        <span>{{eachRequirements.theme.title}}</span>
                    </div>
                    <p>{{eachRequirements.title}}</p>
                    <a class="epicN">{{eachRequirements.epic.title}}</a>
                </div>
                <div class="addRequireBt">
                    <button class="flex gap-2 items-center p-2 text-[#64748B] text-md" (click)="onClickAddFeature('','')">
                        <mat-icon class="text-[#9AA6B8] icon-size-5" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                        <span>Add Requirement</span>
                    </button>
                </div>
            </div>
        </div>
    </div> -->

    <!-- Board -->
    <div 
    class="p-6 sm:p-8 sm:pt-4 overflow-y-auto w-full"
    cdkScrollable>

        <!-- Lists -->
        <div
            class="flex"
            cdkDropList
            [cdkDropListData]="releases_list"
            [cdkDropListOrientation]="'horizontal'" (cdkDropListDropped)="listDropped($event)">

            <!-- Group all cdkDropList's after this point together so that the cards can be transferred between lists -->
            <div
                class="flex items-start gap-6 pr-6"
                cdkDropListGroup>

                <!-- List -->
                <ng-container *ngFor="let list of releases_list; trackBy: trackByFn">
                    <div *ngIf="list"
                        class="flex-0 w-72 p-2 rounded-2xl bg-default"
                        cdkDrag
                        [cdkDragLockAxis]="'x'">

                        <div
                            class="flex items-center justify-between"
                            cdkDragHandle>
                            <div class="flex items-center w-full py-2 px-3 rounded-md cursor-text border border-transparent focus-within:bg-white focus-within:shadow-sm focus-within:border-primary dark:focus-within:bg-gray-900 releaseHeading">
                                {{list.name}}
                            </div>
                            <div class="flex items-center justify-center min-w-6 ml-4 text-sm font-semibold leading-6 rounded-full bg-gray-300 text-secondary dark:bg-gray-700">
                                {{list.requirements.length}}
                            </div>
                            <div *ngIf="is_write_allowed" class="ml-1">
                                <button
                                    class="w-8 h-8 min-h-8"
                                    mat-icon-button
                                    [matMenuTriggerFor]="listMenu">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
                                </button>
                                <mat-menu #listMenu="matMenu">
                                    <button class="font-normal text-black" mat-menu-item (click)="editList(list)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:pencil-square'"></mat-icon>
                                        Edit Release
                                    </button>
                                    <button class="font-normal text-black" mat-menu-item [disabled] = "list.default" (click)="deleteList(list._id)">
                                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                        Delete Release
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <!-- Cards -->
                        <div class="mt-2 rounded-xl bg-gray-400 bg-opacity-12 dark:bg-transparent dark:border">
                            <div
                                [id]="list._id"
                                class="p-3 pb-0 innerBoard"
                                cdkDropList
                                [cdkDropListData]="list.requirements"
                                (cdkDropListDropped)="cardDropped($event)">

                                <!-- Card -->
                                <ng-container *ngFor="let card of list.requirements; let i=index;trackBy: trackByFn">
                                    <a
                                        class="flex flex-col items-start mb-3 p-3 space-y-3 shadow rounded-lg overflow-hidden bg-card"
                                        (click)="(is_write_allowed?onEditFeature(card.theme.title,card.epic.title,card,card._id):'')"
                                        cdkDrag>
                                        <!-- Theme -->
                                        <div class="cardTag">
                                            <span>{{card.theme.title}}</span>
                                        </div>
                                        <!-- Title -->
                                        <div class="cardDesc leading-5">{{card.title}}</div>
                                        <!-- Epic -->
                                        <div class="epicN">
                                            <span>{{card.epic.title}}</span>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>

                            <!-- New card -->
                            <div *ngIf="is_write_allowed" class="addRequireBt">
                                <button class="flex gap-2 items-center  text-[#64748B] text-lg" (click)="onClickAddFeature('','',list._id)">
                                    <mat-icon class="text-[#9AA6B8] icon-size-5" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                    <span>Add Requirement</span>
                                </button>
                            </div>
                            <div *ngIf="(!is_write_allowed && !list.requirements.length)" class="addRequireBt" style="padding-bottom: 10px;">
                                No Requirements Added
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

        </div>

    </div>

  </div>

  
<fuse-drawer
class="w-screen min-w-screen sm:w-100 sm:min-w-100 releaseD z-999"
fixed
[mode]="'over'"
[name]="'releaseDrawer'"
[position]="'right'" (openedChanged)="openedChanged($event)"
#relDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex justify-between mb-4 pl-5 pt-4 w-100" >
            <div class="heading">
                <span class="text-sm ">{{drawerGroupForm.get('_id').value?'Edit Release':'Add Release'}}</span>
                <h5 class="font-medium text-xl break-all">{{drawerGroupForm.get('_id').value?'Edit Release':'Add New Release'}}</h5>
            </div>
            <button mat-icon-button (click)="onCloseDrawer()" >
                <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <form [formGroup]="drawerGroupForm" #drawerGroupNgForm="ngForm" class="flex flex-col flex-auto p-2">
            <div class="flex flex-col ml-3 mr-3">
                
                <div class="inputSec">
                    <mat-form-field class="w-full">
                        <mat-label>Release Name</mat-label>
                        <input matInput [formControlName]="'name'" [readonly]="drawerGroupForm.get('default').value">
                    </mat-form-field>
                </div>

                <div class="inputSec mt-4">
                    <mat-form-field class="w-full">
                        <mat-label>Short Name</mat-label>
                        <input matInput [formControlName]="'short_name'" maxlength="3" max="3" [readonly]="drawerGroupForm.get('default').value">
                    </mat-form-field>
                </div>

                <div class="dateSec mt-4 flex justify-between gap-2">
                    <div class="choosedate">
                        <mat-label class="font-medium">Start Date</mat-label>
                        <mat-form-field class="w-full mt-1">
                            <input matInput [matDatepicker]="pickerstart" formControlName="startDate"[min]="today">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="pickerstart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerstart></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    <div class="choosedate">
                        <mat-label class="font-medium">End Date</mat-label>
                        <mat-form-field class="w-full mt-1">
                            <input matInput [matDatepicker]="pickerend" formControlName="endDate" [min]="today">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="pickerend"></mat-datepicker-toggle>
                            <mat-datepicker #pickerend></mat-datepicker>
                        </mat-form-field>
                        <mat-error *ngIf="(drawerGroupForm.get('startDate').value && drawerGroupForm.get('endDate').value && drawerGroupForm.hasError('endDateInvalid'))">
                            End date must be after the start date.
                        </mat-error>
                    </div>                  
                </div>

                <div class="descSec mt-4">
                    <div class="descEdit flex justify-between pb-2">
                        <h5 class="font-medium">Short Description</h5>
                    </div>
                    <mat-form-field class="flex-auto w-full gt-xs:pl-3">
                        <textarea matInput  placeholder="Enter Description" id="" class="border m-0 p-2 rounded w-full text-lg" style="resize: none;" [rows]="5" cdkTextareaAutosize [formControlName]="'description'" [readonly]="drawerGroupForm.get('default').value"></textarea>
                    </mat-form-field>
                </div>

                <div class="actionTag mt-6 flex flex-col gap-6">
                    
                    <div class="flex gap-6 justify-end">
                        <button class="downloadBt savedoc w-50 flex gap-2 items-center justify-center" (click)="(save_loader?'':autoSave());save_loader=true;" [disabled]="(save_loader || drawerGroupForm.invalid)">
                            <mat-spinner *ngIf="save_loader" class="matSpinnerW" [diameter]="24" [mode]="'indeterminate'"></mat-spinner>
                            Save
                        </button>
                    </div>
                    <div class="my-1 mb-2" *ngIf="docSaved">
                        <fuse-alert *ngIf="docSaved"
                            [appearance]="'soft'"
                            [showIcon]="true"
                            type="success"
                            [dismissible]="true" [dismissed]="false">
                            Saved successfully!
                        </fuse-alert>
                    </div>
                    
                    
                </div>

            </div>
        </form>
    </div>
</fuse-drawer>