import { messages } from './../../mock-api/common/messages/data';
import { inject, Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { IWebsocketMessage } from './websocket.types';
import { environment } from 'environments/environment';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { UserService } from '../user/user.service';

@Injectable({providedIn: 'root'})
export class WebsocketService
{

    private websocket: ReconnectingWebSocket;
    public connectionID:ReplaySubject<string>=new ReplaySubject<string>();
    public messageSubject: Subject<IWebsocketMessage> = new Subject<IWebsocketMessage>();

    private retries = 0;
   

    public isConnected:boolean=false;
    socketOptions = {
      connectionTimeout: 5000,           // Wait 5 seconds for a connection
  maxRetries: 5,                    // Retry up to 5 times
  minReconnectionDelay: 2000,       // Wait at least 2 seconds before retrying
  maxReconnectionDelay: 30000,      // Wait no more than 30 seconds between retries
  reconnectionDelayGrowFactor: 1.5, // Increase delay by 1.5x on each retry
  minUptime: 10000,                 // Treat connections open for at least 10 seconds as successful
                     // Enable debug logging
};

  private maxRetries = this.socketOptions.maxRetries;
  
    private pendingMessages:any[]=[];
    user=null;
    constructor(private _userService:UserService){
      this._userService.user$.subscribe((response)=>{
        this.user=response;
      })
      this.connect();
    }

    connect(): void {

        this.websocket=new ReconnectingWebSocket(environment.websocketURL,[],this.socketOptions);
        this.websocket.onopen = (event) => {
          console.log('WebSocket connection established ');
        this.isConnected=true;
        this.retries = 0;
        if(this.user){
          this.sendConnectionCommand(this.user._id);
        }
        
        this.sendPendingMessages();
        };

        this.websocket.onerror = (error) => {

          console.error('WebSocket error:', error);
        };
        this.websocket.onmessage = (event) => {
          // console.log("Message:",event);
            let message=JSON.parse(event.data);
           this.processMessages(message);
        };

        this.websocket.onclose = (event) => {
          this.isConnected = false;
          console.log('WebSocket connection closed');

          /*
          if (this.retries >= this.maxRetries) {
            console.error('Max retries reached. Connection failed.');
            return;
          }

          // Increment retry counter and wait before reconnecting
          this.retries++;
          const delay = Math.pow(this.socketOptions.reconnectDecay, this.retries) * this.socketOptions.reconnectInterval;
          console.log(`Retrying in ${delay} ms...`);

          setTimeout(() => this.connect(), delay);
          */
      };


      }

      private sendPendingMessages(){
        if(this.pendingMessages.length>0){
        setTimeout(()=>{
          for(let message of this.pendingMessages){
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
              this.websocket.send(message);
            }
          }

          this.pendingMessages=[];
        },1000);
      }
      }
    
      send(message: string): void {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(message);
        } else {
          this.websocket=null;
          this.pendingMessages.push(message);
          console.error('WebSocket is not open');
          this.connect();
        }
      }
    
      disconnect(): void {
        if (this.websocket) {
          this.websocket.close();
          console.log('WebSocket connection closed');
        }
      }

      processMessages(message){
        if(message){
          try{
              this.messageSubject.next(message);
            // console.log("message Data:",message.type);
              if(message.type=="connection"){
                  this.connectionID.next(message.data);
              }
          }catch(error){
              console.log("error",error);
          }
        }
      }

      sendRegenerateEpicCommand(isTestPlan:boolean,themeID:string,epicID:string,proposalID:string,testPlan:string,taskID:string){

        let payload={
            type:"regenerate_epic",
            data:{
              isTestPlan,
              themeID,
              epicID,
              proposalID,
              taskID,
              testPlan
              
                }
            };

             this.send(JSON.stringify(payload));
      }
      sendRegenerateThemeCommand(isTestPlan:boolean,themeID:string,epicID:string,proposalID:string,testPlan:string,taskID:string){

        let payload={
            type:"regenerate_theme",
            data:{
              isTestPlan,
              themeID,
              epicID,
              proposalID,
              taskID,
              testPlan
              
                }
            };

             this.send(JSON.stringify(payload));
      }

      sendRefineRequirementsCommand(requirement:string,technology:string,industry:string,taskId:string){

        let payload={
            type:"refine_requirements",
            data:{
                requirement:requirement,
                technology:technology,
                industry:industry,
                taskID:taskId,
                }
            };

             this.send(JSON.stringify(payload));
      }
      sendRegenerateSolutionCommand(proposalID:string,themeID:string,epicID:string){

        let payload={
            type:"regenerate_epic_solutions",
            data:{
              proposalID,
              themeID,
              epicID,
              taskID:proposalID,
                }
            };

             this.send(JSON.stringify(payload));
      }
      sendGenerateInsightsCommand(proposalID:string,taskId:string){

        let payload={
            type:"generate_proposal_insights",
            data:{
                proposalID:proposalID,
                taskID:taskId,
                }
            };

             this.send(JSON.stringify(payload));
      }

      sendConnectionCommand(userid:string){
        console.log("On Send COnnection Command:",userid);
        if(!userid || userid==""){
          return;
        }
        
        setTimeout(()=>{

          let payload={
            type:"connection",
            userID:userid,
            data:""
            };

            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
              this.websocket.send(JSON.stringify(payload));
            }
        },2000);
      }

      sendValidateInsightQuestionCommand(insightID:string,questionID:string,question:String,taskId:string,technology:string,cloud:any,industry:string,answer:string){
        let payload={
            type:"validate_insight_question",
            
            data:{
                insightID:insightID,
                questionID:questionID,
                question:question,
                taskID:taskId,
                technology:technology,
                clouds:cloud,
                industry:industry,
                answer:answer
                }
            };
             this.send(JSON.stringify(payload));
      }

      sendMetadataListCommand(metadataID:string,describeMetadata_objectID:string,describemetadata_object:String,inFolder:string,taskId:string,access_token:string,salesForces_url:any){
        let payload={
            type:"metadata_list",
            data:{
              metadataID:metadataID,
              describeMetadata_objectID:describeMetadata_objectID,
              describemetadata_object:describemetadata_object,
              describemetadata_object_inFolder:inFolder,
              access_token:access_token,
              salesForces_url:salesForces_url,
              taskID:taskId
              }
            };
            console.log("payload",payload);
            this.send(JSON.stringify(payload));
      }

      sendMetadataRequestCommand(metadataID:string,taskId:string,access_token:string,salesForces_url:any){
        let payload={
            type:"metadata_request",
            data:{
              metadataID:metadataID,
              access_token:access_token,
              salesForces_url:salesForces_url,
              taskID:taskId,
              sessionDetails: localStorage.getItem('accessToken')
              }
            };
            console.log("payload",payload);
            this.send(JSON.stringify(payload));
      }

      sendFixGrammarCommand(input:string,prompt:string,taskId:string){
        let payload={
            type:"fix_grammar",
            
            data:{
                input:input,
                prompt:prompt,
                taskID:taskId,
                }
            };
             this.send(JSON.stringify(payload));
      }

      sendPersonalizeFeatureCommand(featureID:string,industry:string,projectScope:string,taskId:string){

        let payload={
            type:"personalize_feature",
            data:{
                featureID:featureID,
                industry:industry,
                taskID:taskId,
                projectScope:projectScope
                }
            };

             this.send(JSON.stringify(payload));
      }

      sendBusinessCapabilitiesCommand(technology:string,industry:string,clouds:string[],taskId:string,summary:string){
        let payload={
            type:"business_capabilities",
            data:{
                technology:technology,
                industry:industry,
                clouds:clouds,
                taskID:taskId,
                summary:summary
                }
            };

             this.send(JSON.stringify(payload));
      }

      sendBusinessOutcomesCommand(technology:string,industry:string,clouds:string[],summary:string,capabilities:string[],taskId:string){
        let payload={
            type:"business_outcomes",
            data:{
                technology:technology,
                industry:industry,
                clouds:clouds,
                summary:summary,
                capabilities:capabilities,
                taskID:taskId
                }
            };

             this.send(JSON.stringify(payload));
      }

      sendTestRecommendationsTypeCommand(technology:string,industry:string,clouds:string[],summary:string,capabilities:string[],taskId:string,test_summary:string){
        let payload={
            type:"test_type_recommendations",
            data:{
                technology:technology,
                industry:industry,
                clouds:clouds,
                summary:summary,
                capabilities:capabilities,
                taskID:taskId,
                test_summary:test_summary
                }
            };
            // console.log(payload);
             this.send(JSON.stringify(payload));
      }

      sendMetadataChatCommand(prompt:string,metadataID:string,taskId:string,history:any){
        let payload={
            type:"metadata_chat",
            data:{
                prompt:prompt,
                taskID:taskId,
                metadataID:metadataID,
                history:history.map(chat => ({
                  value: chat.value,
                  isMine: chat.isMine
              }))
                }
            };
             this.send(JSON.stringify(payload));
      }

      sendPlaygroundCommand(prompt:string,model:string,knowledgebase:string,taskId:string,embeddingsType:number,selectedDocumentType:string,history:any[]){
        let payload={
            type:"playground",
            data:{
                prompt:prompt,
                taskID:taskId,
                model:model,
                knowledgebase:knowledgebase,
                embeddingsType:embeddingsType,
                selectedDocumentType:selectedDocumentType,
                 history:history.map(chat => ({
                  value: chat.value,
                  isMine: chat.isMine
              }))
                }
            };
             this.send(JSON.stringify(payload));
      }
      
      getInsightTheme(question:string,taskId:string,themes:string[]){
        let payload={
          type:"detect_insight_theme",
          data:{
            question:question,
            taskID:taskId,
            themes:themes
            }
          };console.log(payload);
          this.send(JSON.stringify(payload));
      }
      
}
