
<div class="flex flex-col flex-auto jiraboxmain relative" >

    <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" *ngIf="showQuickPromptMenu" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="menuPosition"></app-quick-prompt> -->


    <span class="close-dialog" [mat-dialog-close]="true" (click)="closeDialog()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>

    <!-- Main -->
    <div class="flex-auto p-6 m-p-0 mat-horizontal-content-container min-w-full">

      <span class="close-dialog2 cursor-pointer absolute" [mat-dialog-close]="true" (click)="closeDialog()">
          <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
      </span>

        <div class="max-w-5xl formBox">
            
          


          @if (!isAtlassianIntegrated()) {
            <div  class="p-4  rounded proposalForm flex flex-col justify-between items-center ">

                <section class="flex min-w-full justify-center mt-5 mb-10" *ngIf="currentStep<=5">
                     <div class="w-1/2 gap-4  flex items-center justify-center">
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 0 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 1 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 2 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 3 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 4 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                        <div class="flex-1 min-h-1" [ngClass]="currentStep >= 5 ? 'bg-[#4F45E4]' : 'bg-[#CFD1D4]'"></div>
                    </div>
                </section>


                <section *ngIf="currentStep===0" class="flex flex-col justify-center items-center">
                    <div class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                        Authenticate
                    </div>
                    <div class="text-md">
                        Please authenticate with your Atlassian account to begin the integration process. This step is required to ensure secure access to your Atlassian resources and enable seamless interaction with the platform. Once authenticated, you will be able to connect and configure the necessary services for full integration.
                    </div>

                    <button *ngIf="!checkIfAtlassianAuthenticationCompleted()" mat-flat-button class="bg-[#0F66E0] text-white mt-12 mb-10" (click)="onClickConnectJira()">
                        <img src="assets/icons/jira_icon.svg">
                        <span class="ml-2">Connect to Jira Account</span>
                    </button>
                    <div *ngIf="checkIfAtlassianAuthenticationCompleted()" class="text-lg font-medium text-green-500 mb-10 mt-5">
                        Jira Authentication Success!
                    </div>
                 
                    <fuse-alert class="mb-5"  [appearance]="'outline'" type="info" [dismissible]="true" [dismissed]="false">
                      Note: Before setting up the Jira connection,ensure that your project includes the standard issue types: Epic,Bug,Task,Story,Subtask and Release (if using versions). Make sure to configure these in your jira project settings before proceeding.
                  </fuse-alert>

                    <!-- <button *ngIf="checkIfAtlassianAuthenticationCompleted()" mat-flat-button class="bg-[#0F66E0] text-white mt-5 mb-10 py-8" (click)="onClickConnectJira()">
                      <img src="assets/icons/jira_icon.svg">
                      <span class="ml-2">Reauthenticate</span>
                  </button> -->


                  
                 
                    
                 




                </section>

                <section *ngIf="currentStep===1" class="flex flex-col justify-between mb-10 items-center">
                    <div class="font-medium leading-7 sm:leading-10 text-3xl text-center tracking-tight">
                        Select Your Jira Site
                    </div>
                    
                    <div class="flex gap-2 items-center justify-center">
                   <div class="flex w-full gap-2 mt-2 dropdown px-2 rounded-md items-center justify-center"> 
                    <mat-select class="min-w-60 min-h-12" [(value)]="selectedSite" placeholder="Please select jira site" (selectionChange)="onClickReloadProjects()">
                        <mat-option *ngFor="let site of jiraSites" [value]="site.id">
                          {{ site.name }}
                        </mat-option>
                      </mat-select>
                     
                   </div>
                   <button (click)="onClickReloadSites()"><img [ngClass]="isLoadingSites? 'rotate-animation':'no-rotation'" src="assets/icons/sync_icon.svg"></button>
                </div>
                </section>


                <section *ngIf="currentStep===2" class="flex flex-col justify-between mb-10 items-center">
                    <div class="font-medium leading-7  sm:leading-10 text-3xl text-center tracking-tight">
                      Select your Jira project
                    </div>
                    <div class="flex gap-2 items-center justify-center">
                   <div class="flex w-full gap-2 mt-2 dropdown px-2 rounded-md items-center justify-center"> 
                    <mat-select class="min-w-60 min-h-12" [(value)]="selectedProject" placeholder="Please select jira project">
                        <mat-option *ngFor="let project of jiraProjects" [value]="project.id">
                          {{ project.name }}
                        </mat-option>
                      </mat-select>
                     
                   </div>
                   <button (click)="onClickReloadProjects()"><img [ngClass]="isLoadingProjects? 'rotate-animation':'no-rotation'" src="assets/icons/sync_icon.svg"></button>
                </div>
                </section>


                <section *ngIf="currentStep===3" class="flex flex-col justify-between mb-10 items-center min-w-full">
                    <div class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                      Configure field mapping
                    </div>
                    


                    <div class="grid items-center grid-cols-3  gap-3 mt-5">
                        <div class="w-full flex gap-1 justify-center items-center  text-lg ml-12"><img class="w-5 h-5" src="assets/icons/gen_logo_icon.svg"> GetGenerative</div>
                        <div></div>
                        <div class="w-full gap-1 flex justify-center items-center  text-lg"><img class="w-4 h-4" src="assets/icons/jira_logo.svg">Jira</div>


                        <div class="flex w-full pl-10 items-center justify-start  text-lg">Theme</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedThemeMapping" (selectionChange)="onChangeTheme($event)">
                            <mat-option *ngFor="let option of jiraThemeMapOptions" [value]="option.value" [disabled]="!option.enable">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>


                          <div class="flex w-full pl-10 items-center justify-start  text-lg">Epic</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedEpicMapping" (selectionChange)="onChangeEpic($event)">
                            <mat-option *ngFor="let option of jiraEpicMapOptions" [value]="option.value" [disabled]="!option.enable">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>



                          <div class="flex w-full pl-10 items-center justify-start  text-lg">User Story</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedStoryMapping">
                            <mat-option *ngFor="let option of jiraStoryMapOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>



                          <div class="flex w-full pl-10 items-center justify-start  text-lg">Release</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedReleaseMapping" (selectionChange)="onChangeRelease($event)">
                            <mat-option *ngFor="let option of jiraReleaseMapOptions" [value]="option.value" [disabled]="!option.enable">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>

                          @if(isTesting){
                          <div class="flex w-full pl-10 items-center justify-start  text-lg">Test Case</div>
                          <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                          <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                              <mat-select class="w-full px-2 min-h-9" [(value)]="selectedTestCaseMapping" (selectionChange)="onChangeTestCase($event)">
                              <mat-option *ngFor="let option of testCaseMappingOptions" [value]="option.value" [disabled]="!option.enable">
                                {{ option.label }}
                              </mat-option>
                            </mat-select></div>
                          


                            <div class="flex w-full pl-10 items-center justify-start  text-lg">Defect</div>
                            <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                            <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                                <mat-select class="w-full px-2 min-h-9" [(value)]="selectedDefectMapping" (selectionChange)="onChangeDefect($event)">
                                <mat-option *ngFor="let option of defectMappingOptions" [value]="option.value" [disabled]="!option.enable">
                                  {{ option.label }}
                                </mat-option>
                              </mat-select></div>




                          }



                    </div>





                </section>

                <section *ngIf="currentStep===4" class="flex flex-col justify-between mb-10 items-center min-w-full">
                    <div class="font-medium leading-7 sm:leading-10 text-3xl text-center tracking-tight">
                      Configure status mapping
                    </div>
                    
                    


                    <div class="grid items-center grid-cols-3 gap-3 mt-5">
                        <div class="w-full flex gap-1 justify-center items-center text-lg ml-12"><img class="w-5 h-5" src="assets/icons/gen_logo_icon.svg"> GetGenerative</div>
                        <div></div>
                        <div class="w-full gap-1 flex justify-center items-center text-lg"><img class="w-4 h-4" src="assets/icons/jira_logo.svg">Jira</div>


                        <div class="flex w-full pl-10 items-center justify-start text-lg">To Do</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedToDoMapping">
                            <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>


                          <div class="flex w-full pl-10 items-center justify-start text-lg">In Progress</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedInProgressMapping">
                            <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>



                          <div class="flex w-full pl-10 items-center justify-start text-lg">Done</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedDoneMapping">
                            <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>



                          <div class="flex w-full pl-10 items-center justify-start text-lg">New</div>
                        <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                            <mat-select class="w-full px-2 min-h-9" [(value)]="selectedNewMapping">
                            <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>



                    </div>





                </section>


                <section *ngIf="currentStep===5" class="flex flex-col justify-center mb-10 items-center min-w-full">
                    <div class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                        Sync Settings
                    </div>
                    
                    <div class="flex w-1/2 flex-col justify-start items-start mt-10"  *ngIf="false">
                    <mat-checkbox
                    *ngIf="false"
                    [(ngModel)]="importExistingJiraIssues"
                    [checked]="false"
                  >
                  <span  class="text-[#64748B] text-md mt-5">Import existing issues from Jira</span>

                  </mat-checkbox>

                  <mat-checkbox
                  *ngIf="false"
                  class="text-[#64748B] text-md mt-5"
                  [checked]="false"
                  [(ngModel)]="ignoreDoneIssues"
                >
                <span  class=" text-md mt-5">Ignore issues in a <strong>done</strong> state.</span>
                </mat-checkbox>
            </div>

             <div class="flex w-1/2  mt-10" *ngIf="false">
            <div   class="flex w-full items-center justify-end mr-5 font-medium text-lg ">Sync Mode Selection:</div>
                        
            <div   class="flex w-full dropdown px-2 rounded-md items-center justify-start bg-[#4F45E4] mat-blue">
                <mat-select class="w-full px-2 min-h-12" [(value)]="selectedSyncMode">
                <mat-option *ngFor="let option of jiraSyncModeOptions" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select></div>

              </div>

              <div class="flex justify-center  mt-5">
                <div class="flex w-full items-center justify-end mr-5 font-medium text-lg ">Sync Type:</div>
                            
                <div class="flex w-full dropdown px-2 rounded-md items-center justify-start bg-[#4F45E4] mat-blue">
                    <mat-select class="w-full px-2 w-40 min-h-12" [(value)]="selectedSyncType"  style="width: 160px !important; ">
                    <mat-option *ngFor="let option of jiraSyncTypeOptions" [value]="option.value">
                      {{ option.label }}
                    </mat-option>
                  </mat-select></div>
    
                  </div>

                </section>


                <section *ngIf="currentStep===6" class="flex flex-col justify-center items-center min-w-full min-h-120">

                    <div class="h-full flex flex-col items-start justify-center">
                    <mat-progress-bar
                    [mode]="isSavingMappings?'indeterminate':'determinate'"
                    value="100"
                    ></mat-progress-bar>
                   <div class="w-full flex justify-center mt-5"><img  *ngIf="!isSavingMappings" class="w-12 h-12" src="assets/icons/jira_logo.svg"></div> 
                    <div *ngIf="isSavingMappings" class="flex w-full justify-center font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-5">
                        GetGenerative.ai is completing your setup..
                    </div>
                    <div *ngIf="!isSavingMappings" class="flex w-full justify-center font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-1">
                        Jira integration successfully setup
                    </div>
                </div>
                </section>

                <div class="flex gap-4" *ngIf="currentStep<=5">
                    <button *ngIf="currentStep>0" (click)="onClickBack()" mat-flat-button class="bg-[#1E293B] text-white px-7"><span>Back</span></button>
                    <button *ngIf="checkIfAtlassianAuthenticationCompleted() && currentStep==0" (click)="onClickConnectJira()" mat-flat-button class="bg-[#1E293B] text-white px-7 flex items-center justify-center"><img class="w-3 h-3" src="assets/icons/jira_icon.svg">
                      <div>Reauthenticate</div></button>
                    <button *ngIf="checkIfAtlassianAuthenticationCompleted()|| currentStep>0"  (click)="onClickNext()" mat-flat-button class="bg-[#4F45E4] text-white px-7"><span>Next</span></button>
                 </div>

            </div>
          }


          @if (isAtlassianIntegrated()) {
            <div class="p-4 manageJira" *ngIf="!showConfigs" >


              <section class="flex flex-col justify-center items-center gap-6">
                <div class="font-medium leading-7 mb-4 sm:leading-10 text-4xl text-center tracking-tight">
                  Manage Your Jira Integration
                </div>
                <div class="flex flex-wrap flex-row gap-6">
                  <div class="border card p-4 rounded jiraCard">
                    <div class="flex flex-col gap-6">
                      <img src="assets/icons/reauth.svg" class="bg-[#0F66E0] icon-size-8 rounded" style="padding: 5px;" alt="">
                      <h4 class="text-3xl font-medium">Re-Authenticate</h4>
                      <p class="text-md font-normal">Re-authenticate your Jira account to ensure secure and uninterrupted access to Project Management Tools</p>
                      <button (click)="onReauthenticate()"  class="bg-[#0F66E0] text-white px-7 text-2xl p-2 rounded">Re-Authenticate</button>
                    </div>
                  </div>
                  <div class="border card p-4 rounded jiraCard">
                    <div class="flex flex-col gap-6">
                      <img src="assets/icons/cogwhite.svg" class="bg-[#4F45E4] icon-size-8 rounded" style="padding: 5px;" alt="">
                      <h4 class="text-3xl font-medium">Configure Settings</h4>
                      <p class="text-md font-normal">Easily adjust preferences and security options to personalize your experience and enhance Workflow Efficiency</p>
                      <button (click)="onConfigureSettings()"  class="bg-[#4F45E4] text-white px-7 text-2xl p-2 rounded">Configure Settings</button>
                    </div>
                  </div>
                  <div class="border card p-4 rounded jiraCard">
                    <div class="flex flex-col gap-6">
                      <img src="assets/icons/pauseint.svg" class="bg-[#1E293B] icon-size-8 rounded" style="padding: 5px;" alt="">
                      <h4 class="text-3xl font-medium">{{getIntegrationStatus()? "Pause Integration":"Resume Integration"}}</h4>
                      <p class="text-md font-normal">Temporarily disable syncs and external connections to optimize project management and minimize distractions.</p>
                      <button (click)="onPauseIntegrations()"  class="bg-[#1E293B] text-white px-7 text-2xl p-2 rounded">
                        <div class="flex gap-1 items-center justify-center">
                          <mat-progress-spinner *ngIf="isPausingIntegration" mode="indeterminate"></mat-progress-spinner> <span>{{getIntegrationStatus()? "Pause Integration":"Resume Integration"}}</span>
                          </div>
                        </button>
                    </div>
                  </div>
                </div>

                <div class="flex w-full">
                  <div class="border card flex flex-wrap flex-row gap-6 items-center justify-between p-4 rounded w-full">
                    <div class="">
                      <h4 class="text-3xl font-medium">Remove Integration</h4>
                      <p class="text-md font-normal">Permanently deletes the integration between <br> GetGenerative.ai and Jira, removing all synced data links.</p>
                    </div>
                    <button (click)="onRemoveIntegrations()"  class="border flex flex-row gap-2 h-12 items-center p-2  px-2 text-2xl rounded text-lg text-black"> 
                     <div class="flex items-center justify-center">
                      <mat-progress-spinner *ngIf="isRemovingIntegration" mode="indeterminate"></mat-progress-spinner> 
                      <span class="flex items-center gap-3">
                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon> Remove Integration
                      </span>
                    </div>
                    </button>
                  </div>
                </div>

                @if (showAlert) {
               <div class="flex justify-start w-full">
                  <fuse-alert  [appearance]="'outline'" type="success" [dismissible]="true" [dismissed]="false">
                    {{alertMessage}}
                </fuse-alert>
               </div>
              }
              </section>

            </div>
          }



                <div class="p-4 manageJira" *ngIf="showConfigs" >


              <section class="flex flex-col justify-center items-center gap-6">
                <div class="font-medium leading-7 mb-4 sm:leading-10 text-4xl text-center tracking-tight">
                  Configure Settings
                </div>
                <div class="flex flex-row flex-wrap gap-6 mb-6 mappingCard">
                  <div class="card p-4 rounded-2xl shadow w-full">

                    <h3 class="card-custom-head">Custom Field Mapping</h3>
                    
                    <div class="grid items-center grid-cols-3 gap-3 mt-5 px-6 mapBox">
                      <div class="w-full flex gap-1 justify-center items-center  text-lg"><img class="w-5 h-5" src="assets/icons/gen_logo_icon.svg"> GetGenerative</div>
                      <div></div>
                      <div class="w-full gap-1 flex justify-center items-center  text-lg"><img class="w-4 h-4" src="assets/icons/jira_logo.svg">Jira</div>


                      <div class="flex w-full items-center justify-start  text-lg">Theme</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedThemeMapping" (selectionChange)="onChangeTheme($event)">
                          <mat-option *ngFor="let option of jiraThemeMapOptions" [value]="option.value" [disabled]="!option.enable">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>


                        <div class="flex w-full items-center justify-start  text-lg">Epic</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedEpicMapping" (selectionChange)="onChangeEpic($event)">
                          <mat-option *ngFor="let option of jiraEpicMapOptions" [value]="option.value" [disabled]="!option.enable">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>



                        <div class="flex w-full items-center justify-start  text-lg">User Story</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedStoryMapping">
                          <mat-option *ngFor="let option of jiraStoryMapOptions" [value]="option.value">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>



                        <div class="flex w-full items-center justify-start  text-lg">Release</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedReleaseMapping" (selectionChange)="onChangeRelease($event)">
                          <mat-option *ngFor="let option of jiraReleaseMapOptions" [value]="option.value" [disabled]="!option.enable">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>

                        @if(isTesting){
                          <div class="flex w-full items-center justify-start  text-lg">Test Case</div>
                          <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                          <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                              <mat-select class="w-full px-2 min-h-9" [(value)]="selectedTestCaseMapping" (selectionChange)="onChangeTestCase($event)">
                              <mat-option *ngFor="let option of testCaseMappingOptions" [value]="option.value" [disabled]="!option.enable">
                                {{ option.label }}
                              </mat-option>
                            </mat-select></div>

                            <div class="flex w-full items-center justify-start  text-lg">Defect</div>
                            <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                            <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                                <mat-select class="w-full px-2 min-h-9" [(value)]="selectedDefectMapping" (selectionChange)="onChangeDefect($event)">
                                <mat-option *ngFor="let option of defectMappingOptions" [value]="option.value" [disabled]="!option.enable">
                                  {{ option.label }}
                                </mat-option>
                              </mat-select></div>
                        }


                  </div>


                  </div>
                  <div class="card p-4 rounded-2xl shadow w-full">

                    <h3 class="card-custom-head">Custom State Mapping</h3>

                    <div class="grid items-center grid-cols-3 gap-3 mt-5 px-6 mapBox">
                      <div class="w-full flex gap-1 justify-center items-center  text-lg"><img class="w-5 h-5" src="assets/icons/gen_logo_icon.svg"> GetGenerative</div>
                      <div></div>
                      <div class="w-full gap-1 flex justify-center items-center  text-lg"><img class="w-4 h-4" src="assets/icons/jira_logo.svg">Jira</div>


                      <div class="flex w-full items-center justify-start  text-lg">To Do</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedToDoMapping">
                          <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>


                        <div class="flex w-full items-center justify-start  text-lg">In Progress</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedInProgressMapping">
                          <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>



                        <div class="flex w-full items-center justify-start  text-lg">Done</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedDoneMapping">
                          <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>



                        <div class="flex w-full items-center justify-start  text-lg">New</div>
                      <div class="flex w-full items-center justify-center"><img class="max-w-5" src="assets/icons/mapping_icon.svg"></div>
                      <div class="flex w-full dropdown px-2 rounded-md items-center justify-center">
                          <mat-select class="w-full px-2 min-h-9" [(value)]="selectedNewMapping">
                          <mat-option *ngFor="let option of jirStatusMappingOptions" [value]="option.value">
                            {{ option.label }}
                          </mat-option>
                        </mat-select></div>



                  </div>

                  </div>
                </div>

                <div class="flex w-full">
                  <div class="flex w-full flex-col gap-6">
                    <div class="card p-4 rounded-2xl shadow w-full">
  
                      <h3 class="card-custom-head pl-10">Sync Setup</h3>
                      <div class="flex flex-row justify-between mb-10 items-center min-w-full">
                        
                        <div class="flex w-1/2 flex-col justify-start items-start mt-10" *ngIf="false">
                        <mat-checkbox
                        *ngIf="false"
                        [(ngModel)]="importExistingJiraIssues"
                        [checked]="false"
                      >
                      <span class="text-[#64748B] text-md mt-5">Import existing issues from Jira</span>
    
                      </mat-checkbox>
    
                      <mat-checkbox
                      *ngIf="false"
                      class="text-[#64748B] text-md mt-5"
                      [checked]="false"
                      [(ngModel)]="ignoreDoneIssues"
                    >
                    <span class="text-[#64748B] text-md mt-5">Ignore issues in a <strong>done</strong> state.</span>
                    </mat-checkbox>
                </div>
    
                <div class="flex flex-col  " >
                  <div class="flex   mt-10" *ngIf="false">
                    <div class="flex w-full items-center mr-5 font-medium text-lg text-[#64748B]">Sync Mode Selection:</div>
                                
                    <div class="flex w-full dropdown px-2 rounded-md items-center justify-start bg-[#4F45E4] mat-blue">
                        <mat-select class="w-full px-2 min-h-12" [(value)]="selectedSyncMode">
                        <mat-option *ngFor="let option of jiraSyncModeOptions" [value]="option.value">
                          {{ option.label }}
                        </mat-option>
                      </mat-select></div>
        
                      </div>
        
                      <div class="flex  pl-6 mt-5">
                        <div class="flex w-full items-center mr-5 font-medium text-lg ">Sync Type:</div>
                                    
                        <div class="flex w-full dropdown px-2 rounded-md items-center justify-start bg-[#4F45E4] mat-blue">
                            <mat-select class="w-full px-2 min-h-12" [(value)]="selectedSyncType" style="width: 160px !important; ">
                            <mat-option *ngFor="let option of jiraSyncTypeOptions" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-select></div>
            
                          </div>
                </div>
    
                    </div>
                    </div>

                    <div class="action flex items-center" [ngClass]="docSaved?'justify-between':'justify-end'">
                      <div class="my-1 mb-2" *ngIf="docSaved">
                        <fuse-alert
                            [appearance]="'soft'"
                            [showIcon]="true"
                            type="success"
                            [dismissible]="true" [dismissed]="false">
                            Saved Successfully!
                        </fuse-alert>
                    </div>
                      <button mat-flat-button class="bg-[#4F45E4] w-40 text-white" (click)="onClickUpdateMappings()">
                        <div class="flex gap-1 items-center">
                          <mat-progress-spinner *ngIf="isSavingMappings" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                          </div>
                      </button>
                    </div>
                   

                  </div>
                </div>

              </section>

            </div>




        </div>

    </div>

</div>
