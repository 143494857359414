import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';

import { IPagination } from '../knowledge-base/knowledge-base.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { ITechnologyDomain } from './technology-domain.types';

@Injectable({providedIn: 'root'})
export class TechnologyDomainService
{
    private _authService=inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _technologyDomains: ReplaySubject<ITechnologyDomain[]> = new ReplaySubject<ITechnologyDomain[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set technologyDomains(value: ITechnologyDomain[])
    {
        // Store the value
        this._technologyDomains.next(value);
    }

    get technologyDomains$(): Observable<ITechnologyDomain[]>
    {
        return this._technologyDomains.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(pageIndex:number,search:string=""): Observable<ITechnologyDomain[]>
    {
        let skip=pageIndex*10;

        let query='cloud?skip='+skip+'&limit=10';
        if(search!=""){
            query='cloud?skip='+skip+'&limit=10&searchText='+search;
        }

       return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){
                    
                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: pageIndex,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._technologyDomains.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }
    getAll(): Observable<ITechnologyDomain[]>
    {
        let skip=0;

        let query='cloud?skip='+skip+'&limit=1000';
         

       return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){
                        this._technologyDomains.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }
    getByID(id:string): Observable<ITechnologyDomain>
    {
        

        let query='cloud?id='+id;
        

       return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get_by_id',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){
                    
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }

    getWithTechnology(pageIndex:number,limit:number,technology:string): Observable<ITechnologyDomain[]>
    {
        let skip=pageIndex*10;

       return this._httpClient.get(environment.api_endpoint + 'cloud?technology='+technology+'&skip='+skip+'&limit='+limit, { headers: { method: 'get_technology',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){
                    
                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: pageIndex,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._technologyDomains.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }


    saveTechnologyDomain(techDomain:ITechnologyDomain): Observable<any>
    {
        

       return this._httpClient.post(environment.api_endpoint + 'cloud',JSON.stringify(techDomain), { headers: { method: 'create','Authorization': 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                if(response.success){

                    return of({success:true});

                }else{
                    return of({success:false,message:response.message});
                }
            }),
        );

    }
    
    deleteTechnologyDomain(domainID:string): Observable<boolean>
    {

       return this._httpClient.post(environment.api_endpoint + 'cloud',JSON.stringify({_id:domainID}), { headers: { method: 'delete','Authorization': 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                if(response.success){

                    
                    return of(true);

                    
                }else{
                    return of(false);
                }
            }),
        );

    }

}
