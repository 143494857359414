import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { EventService } from 'app/core/common/event.service';

@Component({
  selector: 'app-connect-salesforce',
  standalone: true,
  imports: [NgClass,RouterLink, MatIconModule, MatProgressSpinnerModule, FormsModule,  NgIf, ReactiveFormsModule,  MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, FuseAlertComponent,MatRadioModule,NgFor,AsyncPipe,MatStepperModule,MatDialogModule],
  templateUrl: './connect-salesforce.component.html',
  styleUrl: './connect-salesforce.component.scss'
})

export class ConnectSalesforceComponent {
  @ViewChild('horizontalStepper') private horizontalStepper: MatStepperModule;
  @ViewChild(MatStepper) private stepper: MatStepper;
  
  horizontalStepperForm: UntypedFormGroup;
  flashMessage: string;
  flashMessageType: 'success' | 'error' | null = null;
  type: string;
  load_authenticate: boolean = false;
  load_extract: boolean = false;
  user_orgs: any = [];
  proposal_id: string;

  constructor( 
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConnectSalesforceComponent>,
    private cdr: ChangeDetectorRef,
    private _websocketService: WebsocketService,
    private _formBuilder: UntypedFormBuilder, 
    private eventService: EventService,
    private _proposalService: ProposalService 
  ){
    if(data){
      this.proposal_id = data.proposal_id;
      this.user_orgs = data.user_orgs;
      this.type = data.type;
    }
  }

  ngOnInit(): void
  { 
      // Horizontal stepper form
      this.horizontalStepperForm = this._formBuilder.group({
          step0: [''],
          step1: this._formBuilder.group({
            salesForces_url: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            secret_key: ['', Validators.required]
          }),
          step2: [''],
      });
      if(this.type && this.type=='edit'){
        this.setData();
      }
  }

  setData(){
    this.horizontalStepperForm.get('step1.salesForces_url').setValue(this.user_orgs[0].instance_url);
    this.horizontalStepperForm.get('step1.username').setValue(this.user_orgs[0].username);
    this.horizontalStepperForm.get('step1.password').setValue(this.user_orgs[0].password);
    this.horizontalStepperForm.get('step1.secret_key').setValue(this.user_orgs[0].secret_key);
    //this.moveNextStep();
  }

  authenticate(){
    this.load_authenticate = true;
    let dataObject = {
      salesForces_url: this.horizontalStepperForm.get('step1.salesForces_url').value.trim(),
      username: this.horizontalStepperForm.get('step1.username').value.trim(),
      password: this.horizontalStepperForm.get('step1.password').value.trim(),
      secret_key: this.horizontalStepperForm.get('step1.secret_key').value.trim(),
      proposal: this.proposal_id
    };
    this._proposalService.authenticate_login(dataObject).subscribe(
        (value) =>
        {
            if(value.status){
              this.user_orgs = [value.data];
              this.flashMessage = "Salesforce org connected successfully.";
              this.showFlashMessage('success');
            }
            else {
              this.load_authenticate = false;
              this.flashMessage = "Salesforce org connection failed.";
              this.showFlashMessage('error');
            }
        },
        (response) =>
        {
        }
    );
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;
      // Hide it after 3 seconds
      setTimeout(() =>
      {
        if(this.flashMessageType=='success')this.moveNextStep();
        this.flashMessageType = null;
        this.load_authenticate = false;
      }, 3000);
  }

  moveNextStep(){
    this.zone.run(() => {
      this.horizontalStepper['selectedIndex']++;
      this.cdr.detectChanges();
    });
  }

  metadataExtract(){
    this.load_extract = true;
    this._proposalService.metadata_describe({access_token:this.user_orgs[0].access_token,salesForces_url:this.user_orgs[0].instance_url,orgId:this.user_orgs[0].organizationId,proposal:this.proposal_id}).subscribe((response)=>{
      if(response.status && response.data){
        this.load_extract = false;
        if(response.data.describeMetadata.length)this.callSocket(response.data);
        this.cdr.detectChanges();
        this.dialogRef.close({fetch:true});
      }
      else {
        this.load_extract = false;
      }
    });
  }

  callSocket(response){
    this.eventService.emitEventMetadata({extract:true});
    response.describeMetadata.forEach(el=>{
      // console.log(el._id);
      this._websocketService.sendMetadataListCommand(response._id,el._id,(el.hasOwnProperty('childXmlNames')?el.childXmlNames:el.xmlName),el.inFolder,el._id,this.user_orgs[0].access_token,this.user_orgs[0].instance_url);
    })
  }

  skip(){
    this.dialogRef.close({skip:true});
  }

}
