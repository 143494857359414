import { tags } from '../../../../mock-api/apps/tasks/data';
import { trigger } from '@angular/animations';
import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, NgZone, ComponentRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass, DOCUMENT } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertType } from '@fuse/components/alert';
import { CommonService } from 'app/core/common/common.service';
import { first, firstValueFrom } from 'rxjs';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import { NgxTiptapModule } from 'ngx-tiptap';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { SelectStoryComponent } from '../select-story/select-story.component';

@Component({
  selector: 'app-test-case-create',
  standalone: true,
  imports: [MatIconModule,NgxTiptapModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule,DragDropModule],
  templateUrl: './test-case-create.component.html',
  styleUrl: './test-case-create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TestCaseCreateComponent implements OnDestroy{
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanelOriginCategory') private _tagsPanelOriginCategory: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  @ViewChild('tagsPanelCategory') private _tagsPanelCategory: TemplateRef<any>;
  @ViewChild("fileInput") private fileInput:ElementRef;

  alert: { type: FuseAlertType; message: string } = {
    type   : 'success',
    message: '',
  };

  private _tagsPanelOverlayRef: OverlayRef;
  testCaseForm:UntypedFormGroup;
  tagArray: FormArray<any>;
  userTestCase: any={};
  proposal: any={};
  testplan: any={};
  selectedTheme: string;
  selectedEpic: string;
  isSavingStory: boolean = false;
  tagsEditMode: boolean = false;
  tagsEditModeCategory: boolean = false;
  is_write_allowed: boolean = false;
  isUploadingFiles:boolean=false;
  sizeLimitAlert:boolean=false;
  stories: any[]=[];
  themeTypes: any[]=[];
  epicTypes: any[]=[];
  releaseTypes=[];
  filteredTags:any[]=[];
  filteredTagsCategory:any[]=[];
  alltagTypes:any[]=[];
  alltagCategoryTypes:any[]=[];
  fileAttachments=[];
  all_categories:any[]=[];
  fileLoader:boolean = false;
  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter Preconditions",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ]
  });
  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter description",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ]
  });

  statusTypes:any[]=[
    {value:'Active',label:"Active",icon:'assets/icons/s_active.svg'},
    {value:'Draft',label:"Draft",icon:'assets/icons/s_draft.svg'},
    {value:'In Review',label:"In Review",icon:'assets/icons/s_review.svg'},
    {value:'Outdated',label:"Outdated",icon:'assets/icons/s_outdated.png'},
    {value:'Rejected',label:"Rejected",icon:'assets/icons/s_rejected.svg'}
  ];

  priorityTypes:any[]=[
    {value:'Medium',label:"Medium",icon:'assets/icons/p_medium.svg'},
    {value:'Highest',label:"Highest",icon:'assets/icons/p_highest.svg'},
    {value:'High',label:"High",icon:'assets/icons/p_high.svg'},
    {value:'Low',label:"Low",icon:'assets/icons/p_low.svg'},
    {value:'Lowest',label:"Lowest",icon:'assets/icons/p_lowest.svg'}
  ];

  typeList:any[]=[
    {value:'Acceptance',label:"Acceptance"}, 
    {value:'Accessibility',label:"Accessibility"}, 
    {value:'Compatibility',label:"Compatibility"},
    {value:'Destructive',label:"Destructive"},
    {value:'Functionality',label:"Functionality"},
    {value:'Performance',label:"Performance"},
    {value:'Regression',label:"Regression"},
    {value:'Security',label:"Security"},
    {value:'Smoke & Sanity',label:"Smoke & Sanity"},
    {value:'Usability',label:"Usability"}
  ];

  storyViewList = [
    { _id: '', step: '', expectedResult: '', actualResult: '', status: 'Untested' }
  ];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private _proposalService:ProposalService,
    private _authService:AuthService,
    private _commonService:CommonService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
    private dialog:MatDialogRef<TestCaseCreateComponent>,
    private dialog2:MatDialog,
    private viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,private overlayContainer: OverlayContainer,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ){
    if(data){
      this.themeTypes=data.themeTypes;
      this.testplan=data.testplan;
      this.epicTypes=data.epicTypes;
      this.selectedTheme=data.themeName;
      this.selectedEpic=data.epicName;
      this.proposal=data.proposal;
      this.stories=data.stories;
      this.is_write_allowed=data.is_write_allowed;
      this.userTestCase=data.testcase || {_id:""};
      this.all_categories=data.all_categories;
      console.log("Data: ",data);
    }
  }


  ngOnDestroy(){
    this.editor.destroy();
    this.editor1.destroy();
  }

  ngOnInit(): void {
    this.tagArray = this._formBuilder.array([]) as FormArray;
    this.testCaseForm = this._formBuilder.group({
      title:["",Validators.required],
      description:["",Validators.required],
      theme:["",Validators.required],
      epic:["",Validators.required],
      release:["",Validators.required],
      priority:["",Validators.required],
      status:["",Validators.required],
      type:["",Validators.required],
      category:[""],
      preconditions:["",Validators.required],
      testPlan:["",Validators.required],
      proposal:["",Validators.required],
      story:["",Validators.required],
      storyID:["",Validators.required],
      tags:this._formBuilder.array([]),
      steps:this._formBuilder.array(this.storyViewList.map(story => this.createStepGroup(story)))
    });


    if(this.userTestCase && this.userTestCase._id && this.userTestCase._id!=""){
      // console.log(this.userTestCase,this.userTestCase.release._id);
      this.testCaseForm.get("title").setValue(this.userTestCase.title);
      this.testCaseForm.get("description").setValue(this.userTestCase.description);
      this.testCaseForm.get("theme").setValue(this.userTestCase.theme);
      this.testCaseForm.get("epic").setValue(this.userTestCase.epic);
      this.testCaseForm.get("release").setValue(this.userTestCase.release._id);
      this.testCaseForm.get("priority").setValue(this.userTestCase.priority);
      this.testCaseForm.get("status").setValue(this.userTestCase.status);
      this.testCaseForm.get("preconditions").setValue(this.userTestCase.preconditions);
      this.testCaseForm.get("testPlan").setValue(this.userTestCase.testPlan);
      this.testCaseForm.get("proposal").setValue(this.proposal._id);
      this.testCaseForm.get("story").setValue((this.userTestCase.story && this.userTestCase.story._id)?this.userTestCase.story._id:'');
      this.testCaseForm.get("storyID").setValue((this.userTestCase.story && this.userTestCase.story.storyID)?this.userTestCase.story.storyID:'');
      this.testCaseForm.get("category").setValue(this.userTestCase.category);
      if(this.typeList.find(el=>el.label!=this.userTestCase.type))this.typeList.push({label:this.userTestCase.type,value:this.userTestCase.type});
      this.testCaseForm.get("type").setValue(this.userTestCase.type);
      this.steps.clear();  // Clear existing form array items, if any
      this.userTestCase.steps.forEach(item => {
        this.steps.push(this.createStepGroup(item));
      });
      // Clear any existing controls (optional, if you are updating the entire array)
      this.tagArray.clear();

      // Add a control for each tag
      this.userTestCase.tags.forEach(tag => {
        this.tagArray.push(this._formBuilder.control(tag));
        this.alltagTypes.push({label:tag,value:tag});
      });

      if(this.userTestCase.attachments && this.userTestCase.attachments.length){
        this.fileAttachments=this.userTestCase.attachments.map((attachment)=>{
          return {fileName:attachment};
        });
      }
      this.filteredTags = this.alltagTypes;
    }
    else {
      this.testCaseForm.get("proposal").setValue(this.proposal._id);
      this.testCaseForm.get("testPlan").setValue(this.testplan._id);
    }
    if(this.selectedTheme)this.testCaseForm.get("theme").setValue(this.selectedTheme);
    if(this.selectedEpic) this.testCaseForm.get("epic").setValue(this.selectedEpic);
    // this.tagArray = this.newStoryForm.get('tags') as FormArray;
    this.initReleaseTypes();
    if(this.userTestCase.release && this.userTestCase.release._id){
      this.testCaseForm.get("release").setValue((this.userTestCase.release._id.hasOwnProperty('_id'))?this.userTestCase.release._id._id:this.userTestCase.release._id);
    }
    // console.log( this.testCaseForm.value);
  };
    

  onSaveTestCase(){
    let saveCaseRequest;
    if(this.userTestCase._id && this.userTestCase._id!=""){
      //Edit Mode
      saveCaseRequest={
        _id:this.userTestCase._id,
        title:this.testCaseForm.get("title").value,
        theme:this.testCaseForm.get("theme").value,
        epic:this.testCaseForm.get("epic").value,
        story:this.testCaseForm.get("story").value,
        release:this.testCaseForm.get("release").value,
        category:this.testCaseForm.get("category").value,
        priority:this.testCaseForm.get("priority").value,
        status:this.testCaseForm.get("status").value,
        type:this.testCaseForm.get("type").value,
        testPlan:this.testCaseForm.get("testPlan").value,
        proposal:this.testCaseForm.get("proposal").value,
        tags:this.tagArray.value || [],
        description:this.testCaseForm.get("description").value,
        preconditions:this.testCaseForm.get("preconditions").value,
        attachments:this.fileAttachments.map((attachment)=>attachment.fileName)
      };
      saveCaseRequest.steps = this.steps.value;
    }else{
      //Create Mode
      saveCaseRequest={
        title:this.testCaseForm.get("title").value,
        theme:this.testCaseForm.get("theme").value,
        epic:this.testCaseForm.get("epic").value,
        story:this.testCaseForm.get("story").value,
        release:this.testCaseForm.get("release").value,
        category:this.testCaseForm.get("category").value,
        priority:this.testCaseForm.get("priority").value,
        status:this.testCaseForm.get("status").value,
        type:this.testCaseForm.get("type").value,
        tags:this.tagArray.value || [],
        description:this.testCaseForm.get("description").value,
        preconditions:this.testCaseForm.get("preconditions").value,
        testPlan:this.testplan._id,
        proposal:this.proposal._id,
        attachments:this.fileAttachments.map((attachment)=>attachment.fileName)
      };
      saveCaseRequest.steps = this.steps.value.map(({ _id, ...rest }) => rest);
    }
    this.isSavingStory=true;
    // console.log(saveCaseRequest);
    this._proposalService.createTestCases(saveCaseRequest).subscribe((response)=>{
      if(response.ok){
        this.closeDialogDirectly();
      }
    });
  }

  closeDialogDirectly() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Immediately remove mat-dialog-container from the DOM
        const dialogContainer = this.document.querySelector('mat-dialog-container');
        if (dialogContainer) {
          dialogContainer.remove();
        }
        
        // Close the dialog reference
        this.ngZone.run(() => {
          this.dialog.close({ updated: true });
        });
      }, 0);
    });
  }

  dropped(event: CdkDragDrop<any[]>): void
  {
    if(this.is_write_allowed){
      // Move the item in the array
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  
      // Mark for check
      this._changeDetectorRef.markForCheck();
      console.log(event);
    }
  }

  get steps(): FormArray {
    return this.testCaseForm.get('steps') as FormArray;
  }

  createStepGroup(story): FormGroup {
    let storyForm = new FormGroup({
      _id: new FormControl(story._id?story._id:''),
      step: new FormControl(story.step),
      expectedResult: new FormControl(story.expectedResult),
      actualResult: new FormControl(story.actualResult),
      status: new FormControl(story.status)
    });

    return storyForm;
  }

  addSteps(): void {
    this.steps.push(this.createStepGroup({step:'', expectedResult: '', actualResult: '', status: 'Untested'}));
  }

  deleteStep(index: number): void {
    this.steps.removeAt(index);
  }

  updatePriority(event){
    this.testCaseForm.get('priority').setValue(event.value);
  }

  updateStatus(event){
    this.testCaseForm.get('status').setValue(event.value);
  }

  getPriority(priority:number){
    let priorityFound=this.priorityTypes.find((p)=>p.value==priority);
    if(priorityFound){
      return priorityFound;
    }
    return  this.priorityTypes[0];
  }

  getStatus(status){
    let statusFound=this.statusTypes.find((p)=>p.value==status);
    if(statusFound){
      return statusFound;
    }
    return  this.statusTypes[0];
  }

  getStoryID(){
    if(this.userTestCase._id!=""){
      if(this.userTestCase.story.jiraKey && this.userTestCase.story.jiraKey!=""){
        return this.userTestCase.story.jiraKey;
      }
      return this.userTestCase.story.storyID;
    }
    return "";
  }

  initReleaseTypes(){
    this.releaseTypes=this.proposal.releases_list.map((release)=>{
      return {value:release._id,label:release.name};
    });
  }

  onChangeTheme(event){
    let selectedThemes=event.value;
    if(selectedThemes.length){
      this.epicTypes=[];
      for(let epic of this.proposal.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
  }

  getImageURL(url){
    return environment.file_url_cf+ url + '?token='+this._authService.accessToken
  }

  onClickSelectFile(){
    this.fileInput.nativeElement.value="";
    this.fileInput.nativeElement.click();
  }

  async onFilesSelected(event){
    this.fileLoader = true;
    const maxSizeInBytes = 1048576*20; //20MB
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      // Handle the selected files here
      for(let a=0;a<files.length;a++){
        if(files[a].size>maxSizeInBytes){
          
          this.alert={
            type:"error",
            message:"The maximum allowed file size is 20MB"
          }
          this.fileLoader = false;
          this.sizeLimitAlert=true;
          setTimeout(()=>{
            this.sizeLimitAlert=false;
          },3000);
          return;
      }
    }

      //console.log(files);
      for (let i = 0; i < files.length; i++) {
        if(files[i].size>maxSizeInBytes){
          this.alert={
            type:"error",
            message:"The maximum allowed file size is 20MB"
          }
          this.fileLoader = false;
          this.sizeLimitAlert=true;
          setTimeout(()=>{
            this.sizeLimitAlert=false;
          },3000);
        }else{
          let fileData=this.detectFileType(files[i]);
        this.fileAttachments.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size})

        }
      }
      await this.uploadAttachmentFiles();
    }
    
  }

  detectFileType(file: File): { value: number, label: string } | null {
    const fileTypeMap: { [key: string]: string } = {
      'application/pdf': 'PDF',
      'text/csv': 'CSV',
      'text/plain':"TXT",
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'image/jpeg': 'JPEG',
      'image/png': 'PNG',
      'image/jpg': 'JPG'
    };
    const fileType = file.type;
    if (fileTypeMap[fileType]) {
      let thisFileType=fileTypeMap[fileType];
      let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
      return { value: fileValue.value, label: thisFileType };
    }
    return null;
  }

  async uploadAttachmentFiles(){
    this.isUploadingFiles=true;
    for(let i=0;i<this.fileAttachments.length;i++){
      if(this.fileAttachments[i].uploadURL===""){
        try{
          let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.fileAttachments[i].title,filetype: this.fileAttachments[i].file.type}));
          this.fileAttachments[i].uploadURL=uploadResp.uploadURL;
          this.fileAttachments[i].fileName=uploadResp.filename;
        }catch(error){
          console.log("Error:",error);
        }
        await firstValueFrom(this._proposalService.uploadSupportingFile(this.fileAttachments[i].uploadURL,this.fileAttachments[i].file));
      }
    }
    this.isUploadingFiles=false;
    
    this.fileLoader = false;
    this._changeDetectorRef.detectChanges();
  }

  getFileTypeString(file){  
    if(file.fileName && file.fileName!=""){
      let splits=file.fileName.split(".");
      let fileExtension=splits[splits.length-1];
      return fileExtension.toUpperCase();
    }
    return "";
  }

  onRemoveFile(attachment){
    let attachmentIndex=this.fileAttachments.findIndex((att)=>att.fileName==attachment.fileName);

    if(attachmentIndex>=0){
      this.fileAttachments.splice(attachmentIndex,1);
    }
  }

  getFileClass(attachment): string {
    if(attachment.fileName && attachment.fileName!=""){
      let extension=this.getFileTypeString(attachment);
      let fileType:any=this._commonService.fileTypes.find((file)=>file.label==extension);

      if(fileType){
        fileType=fileType.value;

        switch (fileType) {
          case 0: return 'bg-red-600';
          case 1: return 'bg-green-600';
          case 2: return 'bg-gray-600';
          case 3: return 'bg-blue-600';
          case 4: return 'bg-blue-500';
          case 5: return 'bg-yellow-600';
          case 6: return 'bg-yellow-500';
          case 7: return 'bg-orange-600';
          case 8: return 'bg-orange-500';
          case 9: return 'bg-amber-600';
          case 10: return 'bg-green-600';
          default: return 'bg-gray-600';
        }
      }
    }
  }
  
  /**
     * Open tags panel
     */
  openTagsPanel(type): void
  {
      if(type=='tag'){
        this.filteredTags = this.alltagTypes;
        // Create the overlay
        this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
        });
      }
      else if(type=='category'){
        this.filteredTagsCategory = this.all_categories;//this.alltagCategoryTypes;
        // Create the overlay
        this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOriginCategory.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
        });
      }
      

      // Subscribe to the attachments observable
      this._tagsPanelOverlayRef.attachments().subscribe(() =>
      {
          // Focus to the search input once the overlay has been attached
          this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
      });

      // Create a portal from the template
      const templatePortal = new TemplatePortal((type=='tag'?this._tagsPanel:this._tagsPanelCategory), this._viewContainerRef);

      // Attach the portal to the overlay
      this._tagsPanelOverlayRef.attach(templatePortal);

      // Subscribe to the backdrop click
      this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
      {
          // If overlay exists and attached...
          if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
          {
              // Detach it
              this._tagsPanelOverlayRef.detach();

              // Reset the tag filter
              if(type=='tag'){
                this.filteredTags = this.alltagTypes;
                this.tagsEditMode = false;
              }
              else if(type=='category'){
                this.filteredTagsCategory = this.all_categories;//this.alltagCategoryTypes;
                this.tagsEditModeCategory = false;
              }
          }

          // If template portal exists and attached...
          if ( templatePortal && templatePortal.isAttached )
          {
              // Detach it
              templatePortal.detach();
          }
      });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event,type): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();
      if(type=='tag'){
        this.filteredTags = this.alltagTypes.filter(tag => tag.label.toLowerCase().includes(value));
      }
      else if(type=='category'){
        this.filteredTagsCategory = this.all_categories.filter(tag => tag.label.toLowerCase().includes(value));
      }
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string,type): boolean
  {
    if(type=='tag'){ 
      return !!!(inputValue === '' || this.alltagTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
    }
    else if(type=='category'){
      return !!!(inputValue === '' || this.all_categories.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
    }
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event,type): void
  {
    // Return if the pressed key is not 'Enter'
    if ( event.key !== 'Enter' )
      {
          return;
      }
    let tag, isTagApplied;
    if(type=='tag'){ 
      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value,type);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }
      // If there is a tag...
      tag = this.filteredTags[0];
      isTagApplied = this.alltagTypes.find(id => id === tag._id);
    }
    else if(type=='category'){
        // If there is no tag available...
        if ( this.filteredTags.length === 0 )
        {
            // Create the tag
            this.createTag(event.target.value,type);
  
            // Clear the input
            event.target.value = '';
  
            // Return
            return;
        }
        // If there is a tag...
        tag = this.filteredTagsCategory[0];
        isTagApplied = this.all_categories.find(id => id === tag._id);
  
    }

    // If the found tag is already applied to the task...
    if ( isTagApplied )
      {
          // Remove the tag from the task
          // this.deleteTagFromTask(tag);
      }
      else
      {
          // Otherwise add the tag to the task
          this.addTagToTask(tag,type);
      }
  }

  toggleTaskTag(event,type): void
  {  
    if(type=='tag'){ 
      // console.log(event);
      this.filteredTags = this.alltagTypes.filter(tag => !tag.label.toLowerCase().includes(event.value));
      this.alltagTypes = this.filteredTags;
      // console.log(this.alltagTypes);
      this.tagArray.clear();
      this.filteredTags.forEach((eachtag) => {
        this.tagArray.push(new FormControl(eachtag.label));
      });
      // Toggle the edit mode off
      this.tagsEditMode = false;
      // this.autoSave();
    }
    else if(type=='category'){
      this.testCaseForm.get('category').setValue(event.value);
      this._tagsPanelOverlayRef.dispose();
      this.filteredTagsCategory = this.all_categories.filter(tag => !tag.label.toLowerCase().includes(event.value));

      // Toggle the edit mode off
      this.tagsEditModeCategory = false;
    }
  }

  /**
   * Create a new tag
   *
   * @param title
   */
  createTag(title: string,type): void
  {
    const tag = {
        title,
    };
    this.addTagToTask(tag,type);
    
    if(type=='tag'){
      this.filteredTags = this.alltagTypes;
      // Toggle the edit mode off
      this.tagsEditMode = false;
    }
    else if(type=='category'){
      this.filteredTagsCategory = this.all_categories;
      // Toggle the edit mode off
      this.tagsEditModeCategory = false;
    }
  }
  /**
   * Add tag to the task
   *
   * @param tag
   */
  addTagToTask(tag,type): void
  {
    this._tagsPanelOverlayRef.dispose();
    if(type=='tag'){
      this.tagArray.push(new FormControl(tag.title));
      this.alltagTypes.push({label:tag.title,value:tag.title});
      this.filteredTags = this.alltagTypes;
      this.tagsEditMode = false;
      this._changeDetectorRef.detectChanges();
    }
    else if(type=='category'){
      this.testCaseForm.get('category').setValue(tag.title);
      this.all_categories.push({label:tag.title,value:tag.title});
      this.filteredTagsCategory = this.all_categories;
      this.tagsEditModeCategory = false;
      this._changeDetectorRef.detectChanges();
    }
  }
  // Toolbar methods for specific commands
  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
  }

  toggleHeading(level: number) {
      // this.editor.chain().focus().toggleHeading([ level ]).run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  selectStory(){
    const dialogRefstory = this.dialog2.open(SelectStoryComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data: {proposal:this.proposal,_testplanDetail:this.testplan,themeTypes:this.themeTypes}
    });
    dialogRefstory.beforeClosed().subscribe(result => {
      if(result && result._id){
        this.testCaseForm.get('story').setValue(result._id);
        this.testCaseForm.get('storyID').setValue(result.storyID);
        // dialogRefstory.close();
        this._changeDetectorRef.detectChanges();
      }
      // const overlayContainerElement = this.overlayContainer.getContainerElement();
      // if (overlayContainerElement) {
      //   const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
      //   overlayContainers.forEach(overlayPane => {
      //     overlayPane.remove();
      //   });
      // }
      // this._changeDetectorRef.detectChanges();
    });
    dialogRefstory.afterClosed().subscribe(result => {
    //   console.log(result);
      dialogRefstory.close();
    //   if(result && result._id){
    //     this.testCaseForm.get('story').setValue(result._id);
    //     this.testCaseForm.get('storyID').setValue(result.storyID);
    //   }
    });
  }


  closeDialog(){
    console.log('asdf');
    this.dialog.close();
  }



}
