import { WebsocketService } from '../../../../core/websockets/websocket.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input,OnInit,Output,SimpleChanges,ViewChild,viewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { UserStoryComponent } from '../user-story/user-story.component';
import { JIRA } from 'app/core/common/constants';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { NgClass, NgIf } from '@angular/common';
import { filter, Subscription } from 'rxjs';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { MatSelectModule } from '@angular/material/select';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { TestCaseCreateComponent } from '../test-case-create/test-case-create.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-test-case-table',
  standalone: true,
  imports: [MatProgressSpinnerModule,MatIcon,UserStoryComponent,NgClass,NgIf,MatSelectModule,DragDropModule,MatPaginatorModule],
  templateUrl: './test-case-table.component.html',
  styleUrl: './test-case-table.component.scss'
})
export class TestCaseTableComponent implements OnInit {
  priorityTypes=[
    {value:JIRA.MEDIUM,label:"Medium",icon:'assets/icons/p_medium.svg'},
    {value:JIRA.HIGHEST,label:"Highest",icon:'assets/icons/p_highest.svg'},
    {value:JIRA.HIGH,label:"High",icon:'assets/icons/p_high.svg'},
    {value:JIRA.LOWE,label:"Low",icon:'assets/icons/p_low.svg'},
    {value:JIRA.LOWEST,label:"Lowest",icon:'assets/icons/p_lowest.svg'}
  ];

  @Input()
  stories: any[];

  @Input()
  themeTypes=[];

  @Input()
  all_categories:any[]=[];
  
  @Input()
  epicTypes=[];

  @Input()
  releases_list=[];

  @Input()
  epicName:any;

  @Input()
  themeName:any;

  @Input()
  _page_id:any;

  @Input()
  is_write_allowed:any;

  @Input()
  testplan:any;

  @Input()
  proposal:any;

  @Input()
  myIndex:number;

  @Input()
  filterApplied:boolean;

  @Output()
  onUpdateStories: EventEmitter<boolean>= new EventEmitter<boolean>();

  readonly dialog = inject(MatDialog);
  tableViewList : any[] = [];
  storyList: any[]=[];
  isSyncingWithJira:boolean=false;
  loader_testcase:boolean=true;
  totalRecords = 100;
  pageSize = 5;
  currentPage = 0;
  isLoading:boolean=true;
  turnTaken:boolean=false;

  private loadingEventSubscription: Subscription;
  constructor(
    private websocketService:WebsocketService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _proposalService:ProposalService,
    private overlayContainer: OverlayContainer,
    private atlassianService:AtlassianService
  ){

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "test_case_synced")
    ).subscribe((message:any) => {
      console.log("Test Case Synced:",message);
      this.updateSyncedTestCase(message.data);
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "test_case_generated")
    ).subscribe((message) => {
      // console.log("test_case_generated",message);
        // if(message.taskID==this._proposalDetail._id){
        //   this.initial_load = true;
        //   this.getTestPlan(true);
        // }
    });
  }
  updateSyncedTestCase(testCase){
    for(let eachCase of this.tableViewList){
      if(eachCase._id==testCase.testCase){
        eachCase.jiraSynced=true;
        eachCase.isSyncing=false;
        eachCase.jiraID=testCase.jiraID;
        eachCase.jiraKey=testCase.jiraKey;
      }
    }
  }
  ngOnDestroy(){
    if(this.loadingEventSubscription){
    this.loadingEventSubscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    // console.log(this.themeName);console.log(this.epicName);
  }
  ngAfterViewInit(){

    if(this.stories){
      this.storyList = [];
      this.stories.forEach((rl) => {
        this.storyList.push({label:rl.storyID,value:rl._id});
      })
    }
    // console.log(this.filterApplied,this.myIndex);console.log(this.themeName);console.log(this.epicName);

    if(this.myIndex==0 || this.filterApplied){
      if(this.themeName && this.epicName){
        this.turnTaken=true;
        this.initTestCases(true);

      }
    }else{
      this.loadingEventSubscription=this._proposalService.currentLoadingEvent$.subscribe((response)=>{
        // console.log(response);console.log(this.turnTaken);
        if(this.myIndex==response && !this.turnTaken){
          if(this.themeName && this.epicName){
            this.turnTaken=true;
            this.initTestCases(true);
          }
        }
      });
    }



  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes['stories'] && changes['stories'].currentValue) {
  //     console.log(changes['stories'].currentValue);
  //   }
  // }

  openDialog() {
    const dialogRef = this.dialog.open(TestCaseCreateComponent,
      { 
        panelClass: 'add-story-dialog',
        disableClose: true,
        data:{
          epicName:this.epicName,
          themeName:this.themeName,
          proposal:this.proposal,
          testplan:this.testplan,
          themeTypes:this.themeTypes,
          epicTypes:this.epicTypes,
          stories:this.storyList,
          all_categories:this.all_categories
        }  
      }
    );
    dialogRef.beforeClosed().subscribe(result => {
      if(result && (result.ok || result.updated || result.success)){
        this.initTestCases();
      }
      // const overlayContainerElement = this.overlayContainer.getContainerElement();
      // if (overlayContainerElement) {
      //   const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
      //   overlayContainers.forEach(overlayPane => {
      //     overlayPane.remove();
      //   });
      // }
      // this._changeDetectorRef.detectChanges();
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log(result);
    //   if(result && result.updated){
    //     this.initTestCases();
    //   }
    // });
  }

  getReleaseValue(release_id){
    let element = this.releases_list.find(el=>el._id==release_id);
    return (element && element.short_name)?element.short_name:'';
  }

  getPriority(priority:number){
    let priorityFound=this.priorityTypes.find((p)=>p.value==priority);
    if(priorityFound){
      return priorityFound;
    }
    return  this.priorityTypes[0];
  }

  getIntegrationStatus(){
    if(this.proposal && this.proposal.integrations && this.proposal.integrations.jira && this.proposal.integrations.jira.active==true){
      return true;
    }
    return false;
  }

  getStatusString(status){
    if(status==JIRA.TO_DO){
      return "To Do";
    }else if(status==JIRA.IN_PROGRESS){
      return "In Progress";
    }else if(status==JIRA.DONE){
      return "Done";
    }
  }

  getStoryID(story){
    if(story && story._id!=""){
      if(story.jiraKey && story.jiraKey!=""){
        return story.jiraKey;
      }
      return story.storyID;
    }
    return "";
  }

  onPushToJira(){
    if(this.isSyncingWithJira){
      return;
    }
    this.isSyncingWithJira=true;
    this.atlassianService.syncProjectWithJira(this.proposal._id).subscribe((response)=>{
      if(response.success){
      }
    })
  }

  viewStoryInJira(story){
    if(!story.jiraSynced){
      return;
    }
    let issueURL=this.atlassianService.constructJiraIssueLink(this.proposal.integrations.jira.site,story.jiraKey);
    window.open(issueURL || "", "_blank");
  }

  onReleaseSelection(event,testcase){
    testcase['release']=event.value;
    this._proposalService.createTestCases(testcase).subscribe((response)=>{
      if(response && (response.ok || response.success || response.updated))this.initTestCases();
    });
  }

  onEditTestCase(testcase){
    const dialogRef = this.dialog.open(TestCaseCreateComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data:{
        epicName:this.epicName,
        themeName:this.themeName,
        proposal:this.proposal,
        testplan:this.testplan,
        themeTypes:this.themeTypes,
        epicTypes:this.epicTypes,
        stories:this.storyList,
        testcase:testcase,
        all_categories:this.all_categories
      }  
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && (result.ok || result.updated || result.success)){
        this.initTestCases();
      }
      // const overlayContainerElement = this.overlayContainer.getContainerElement();
      // if (overlayContainerElement) {
      //   const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
      //   overlayContainers.forEach(overlayPane => {
      //     overlayPane.remove();
      //   });
      // }
      // this._changeDetectorRef.detectChanges();
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result && (result.ok || result.success || result.updated))this.initTestCases();
    // });
  }

  onDeleteTestCase(testcase){
    if(testcase.delete){
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {
        message:"Are you sure you want to delete this test case?",
        title:"Confirm Delete",
        buttonTitle:"Delete"
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.yes===true){
        testcase.delete=true;
        this._proposalService.deleteTestCase({testCase:testcase._id,testPlan:this.testplan._id}).subscribe((response)=>{
          if(response && (response.ok || response.updated || response.success)){
            this.initTestCases();
            testcase.delete=false;
            this._changeDetectorRef.detectChanges();
          }
        })
      }
      // this.onKCDocUpload.emit({refresh:true});
      // const overlayContainerElement = this.overlayContainer.getContainerElement();
      // if (overlayContainerElement) {
      //   const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
      //   overlayContainers.forEach(overlayPane => {
      //     overlayPane.remove();
      //   });
      // }
      // this._changeDetectorRef.detectChanges();
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result && result.yes===true){
    //     testcase.delete=true;
    //     this._proposalService.deleteTestCase({testCase:testcase._id,testPlan:this.testplan._id}).subscribe((response)=>{
    //       if(response.success)this.initTestCases();
    //     })
    //   }
    // });
  }

  onStoryReleaseSelection(event,story){
    // console.log(event);
    // console.log(story);
    let saveStoryRequest={
      _id:story._id,
      proposalID:this.proposal._id,
      title:story.title,
      theme:story.theme,
      epic:story.epic,
      release:event.value,
      priority:story.priority,
      status:story.status,
      tags:story.tags,
      description:story.description,
      attachments:story.attachments,
      dependencies:[]
    }
    this.atlassianService.createUpdateStory(saveStoryRequest).subscribe((response)=>{
      if(response && response.push){
        this.onPushToJira();
      }else if(response && response.updated){
      this.onUpdateStories.emit(true);
      }
    });
  }

  dropped(event: CdkDragDrop<any[]>): void
  {
    if(this.is_write_allowed){
      // Move the item in the array
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      // Mark for check
      this._changeDetectorRef.markForCheck();
      console.log(event);
    }
  }

  syncSingleTestCase(testcase){
    
    console.log("Syn single testCase:",testcase);

    let request={
      proposalID:this.proposal._id,
      type:"testcase",
      testCaseID:testcase._id
    };
    testcase.isSyncing=true;
    this.atlassianService.jiraSync(request).subscribe((response)=>{
      if(response.success){
      }
    });

  }

  openStory(story){
    let epicTypes=[];
    for(let epic of this.proposal.epics){
      if(story.theme.includes(epic.theme)){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic.name };
        });
        epicTypes.push(...epics);
      }
    }
    const dialogRef = this.dialog.open(UserStoryComponent,
      { 
        panelClass: 'add-story-dialog',
        disableClose: true,
        data:{
          proposal:this.proposal,
          themeTypes:this.themeTypes,
          epicTypes:epicTypes,
          story:story,
          is_write_allowed:this.is_write_allowed,
          release:story.release
        }  
      }
    );
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.push){
        this.onPushToJira();
      }else if(result && result.updated){
        this.onUpdateStories.emit(true);
      }
      // const overlayContainerElement = this.overlayContainer.getContainerElement();
      // if (overlayContainerElement) {
      //   const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
      //   overlayContainers.forEach(overlayPane => {
      //     overlayPane.remove();
      //   });
      // }
      // this._changeDetectorRef.detectChanges();
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result && result.push){
    //    this.onPushToJira();
    //   }else if(result && result.updated){
    //     this.onUpdateStories.emit(true);
    //   }
    // });
  }

  initTestCases(myTurn=false){
    if(this.testplan && this.testplan._id){
      // console.log(this.themeName,this.epicName);
      const offset = this.currentPage * this.pageSize;
      this._proposalService.getTestCases(offset, this.pageSize, this.testplan._id, this.themeName, this.epicName,[], [], [], [], [], '','story').subscribe((response)=>{
        this.tableViewList = [];
        this.isLoading=false;
        this.totalRecords = response.total;
        if(response.data && response.data.length){
          this.tableViewList = JSON.parse(JSON.stringify(response.data));
        }
        this.loader_testcase = false;
        this._changeDetectorRef.detectChanges();
        // console.log(this.myIndex,myTurn);
        if(myTurn){
          this._proposalService.incrementCurrentLoadingEvent(this.myIndex);
        }
      });
    }
  }

  onPageChange(event: PageEvent) {
    this.loader_testcase = true;
    this.isLoading=true;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initTestCases();
  }
}
