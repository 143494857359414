import { user } from './../../mock-api/common/user/data';

import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
declare var webengage: any;
declare var mixpanel: any;

//webengage.debug(true);

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  

  constructor(private authService:AuthService){

  }


   logoutUser() {
    if (typeof webengage !== 'undefined') {
      webengage.user.logout();
    }
  }

   trackEvent(eventName: string, params: any) {
    if (typeof webengage !== 'undefined') {
      webengage.track(eventName, { ...params, date: new Date() });
    } else {
      console.log('Webengage is undefined');
    }

    if (typeof mixpanel !== 'undefined' && environment.server=='prod') {
      mixpanel.track(eventName, {
        ...params,
        date: new Date(),
        distinct_id: this.authService.user.contact?.email,
        userName: this.authService.user?.personal?.name?.first,
        userEmail: this.authService.user.contact?.email,
      });
    }

  }

   trackUser() {
    if (
      typeof webengage !== 'undefined' &&
      this.authService.user !== null
    ) {
      webengage.user.login(this.authService.user.contact?.email);

      webengage.user.setAttribute(
        'we_email',
        this.authService.user.contact?.email
      );
      webengage.user.setAttribute(
        'first_name',
        this.authService.user?.personal?.name?.first
      );
      webengage.user.setAttribute(
        'last_name',
        this.authService.user?.personal?.name?.last || ""
      );
    }

    if (
      typeof mixpanel != 'undefined' &&
      this.authService.user !== null && environment.server=='prod'
    ) {
      mixpanel.identify(this.authService.user?.contact?.email);
      mixpanel.people.set_once({
        name: this.authService.user?.personal?.name?.first,
        email: this.authService.user?.contact?.email
      });
    }

  }
}
