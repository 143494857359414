<div class="flex flex-col flex-auto min-w-0 relative featureTableCard shadow"  *ngIf="dialog_data.tour ==='proposal'">
    <span class="close-dialog"  [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <div class="flex-auto p-6 ">
        <div class="formBox ">
            <div class="flex flex-col gap-2 items-center settingHead">
                <h2 class="font-bold text-4xl ">Pre-Sales Agent</h2>
                <p class="text-2xl text-center ">Enrich needs analysis, automate proposal creation and optimize solution design.</p>
                
            </div>
            <div class="text-center mt-6">
                <button  mat-flat-button [color]="'primary'" type="button" style="flex-direction: row-reverse;" (click)="startTour()" [mat-dialog-close]="true">
                    Start Tour
                    <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                </button>
            </div>
          
        </div>
    </div>
</div>




<div class="flex flex-col flex-auto min-w-0 relative featureTableCard shadow" *ngIf="dialog_data.tour ==='project'">
    <span class="close-dialog"  [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
    <div class="flex-auto p-6 ">
        <div class="formBox ">
            <div class="flex flex-col gap-2 items-center settingHead">
                <h2 class="font-bold text-4xl ">Design Agent</h2>
                <p class="text-2xl text-center ">Fast-track design from requirements gathering to solution delivery.</p>
                
            </div>
            <div class="text-center mt-6">
                <button  mat-flat-button [color]="'primary'" type="button" style="flex-direction: row-reverse;" (click)="startProjectTour()" [mat-dialog-close]="true">
                    Start Tour
                    <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                </button>
            </div>
          
        </div>
    </div>
</div>




<div class="flex flex-col flex-auto min-w-0 relative featureTableCard shadow" *ngIf="dialog_data.tour ==='testing'">
    <span class="close-dialog"  [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
    <div class="flex-auto p-6 ">
        <div class="formBox ">
            <div class="flex flex-col gap-2 items-center settingHead">
                <h2 class="font-bold text-4xl ">Welcome to Testing Agent</h2>
                <p class="text-2xl text-center ">Accelerate testing from strategy to defect management effortlessly.</p>
                
            </div>
            <div class="text-center mt-6">
                <button  mat-flat-button [color]="'primary'" type="button" style="flex-direction: row-reverse;" (click)="startTestingTour()" [mat-dialog-close]="true">
                    Start Tour
                    <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                </button>
            </div>
          
        </div>
    </div>
</div>