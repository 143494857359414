import { DialogRef } from '@angular/cdk/dialog';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
  import { TemplatePortal } from '@angular/cdk/portal';
  import { NgClass, NgFor, NgIf } from '@angular/common';
  import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, ElementRef, TemplateRef, ViewContainerRef, Inject, Input } from '@angular/core';
  import { FormsModule, NgForm, NgModel, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
  import { ChangeDetectorRef } from '@angular/core';
  import { ActivatedRoute, Router, RouterLink } from '@angular/router';
  import { MatButtonModule } from '@angular/material/button';
  import { MatCheckboxModule } from '@angular/material/checkbox';
  import { MatOptionModule } from '@angular/material/core';
  import { MatFormFieldModule } from '@angular/material/form-field';
  import { MatIconModule } from '@angular/material/icon';
  import { MatInputModule } from '@angular/material/input';
  import { MatRadioModule } from '@angular/material/radio';
  import { MatSelectModule } from '@angular/material/select';
  import { MatStepper, MatStepperModule } from '@angular/material/stepper';
  import { MatProgressBarModule } from '@angular/material/progress-bar';
  import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
  import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
  import { CommonService } from 'app/core/common/common.service';
  import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
  import { FuseDrawerComponent } from '@fuse/components/drawer';
  import { FuseConfirmationService } from '@fuse/services/confirmation';
  import { ProposalService } from 'app/core/proposal/proposal.service';
  import { WebsocketService } from 'app/core/websockets/websocket.service';
  import { TechnologyDomainService } from 'app/core/technology-domain/technology-domain.service';
  import { UserService } from 'app/core/user/user.service';
  import { User } from 'app/core/user/user.types';
  import country_list from '../../../../assets/i18n/country_list.json';
  import { filter, finalize, firstValueFrom, Subject, takeUntil } from 'rxjs';
  import Speech from 'speak-tts';
  import {v4 as uuidv4} from 'uuid';
  import { COMMA, ENTER } from '@angular/cdk/keycodes';
  import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
  import { QuickPromptTipComponent } from 'app/modules/common/quick-prompt-tip/quick-prompt-tip.component';
  import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
  declare var webkitSpeechRecognition: any;
  
  import { NgxTiptapModule } from 'ngx-tiptap';
   
  import { fuseAnimations } from '@fuse/animations';
   
  import { Overlay } from '@angular/cdk/overlay';
  import { Platform } from '@angular/cdk/platform';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { JIRA } from 'app/core/common/constants';
import { GenericConfirmationComponent } from '../generic-confirmation/generic-confirmation.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation/delete-confirmation-dialog.component';
import { AnalyticsService } from 'app/core/common/analytics_service';
import analytics_event from 'app/core/common/analytics_event';
   
  export interface Country {
    code: string;
    name: string;
  }
@Component({
  selector: 'app-jira-integration',
  standalone: true,
  imports      : [MatProgressBarModule,NgxTiptapModule, EditorControlsComponent, NgClass,QuickPromptTipComponent,RouterLink,QuickPromptComponent, FuseDrawerComponent, MatIconModule, MatProgressSpinnerModule, FormsModule, FuseAlertComponent, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,MatChipsModule,NgFor],
  animations   : fuseAnimations,
  templateUrl: './jira-integration.component.html',
  styleUrl: './jira-integration.component.scss'
})
export class JiraIntegrationComponent {

  alertMessage:string="Jira integration removed successfully.";
  selectedThemeMapping=JIRA.EPIC;
  selectedEpicMapping=JIRA.LABEL;
  selectedStoryMapping=JIRA.STORY;
  selectedReleaseMapping=JIRA.RELEASE;

  selectedToDoMapping=JIRA.TO_DO;
  selectedInProgressMapping=JIRA.IN_PROGRESS
  selectedDoneMapping=JIRA.DONE;
  selectedNewMapping=JIRA.TO_DO;

  selectedSyncType=0;
  selectedSyncMode=0;
  isSavingMappings:boolean=false;
  importExistingJiraIssues:boolean=false;
  ignoreDoneIssues:boolean=false;
  docSaved: boolean = false;
  isTesting:boolean=false;


  jiraSyncModeOptions=[
    {value:0,label:"Two-Way Sync"},
    {value:1,label:"Pull Only"},
    {value:2,label:"Push Only"},
  ];

  jiraSyncTypeOptions=[
    {value:0,label:"Manual"},
    {value:1,label:"Automatic"}
  ]

  jirStatusMappingOptions=[
    {value: JIRA.TO_DO, label: 'To Do'},
    {value: JIRA.IN_PROGRESS, label: 'In Progress'},
    {value: JIRA.DONE, label: 'Done'},
  ];

  jiraThemeMapOptions=[
    {value: JIRA.EPIC, label: 'Epic',enable:true},
    {value: JIRA.COMPONENT, label: 'Component',enable:true},
    {value: JIRA.RELEASE, label: 'Release',enable:true},
    {value: JIRA.LABEL, label: 'Label',enable:true},
    {value: JIRA.NONE, label: 'None',enable:true},
  ];

  jiraEpicMapOptions=[
    {value: JIRA.EPIC, label: 'Epic',enable:true},
    {value: JIRA.COMPONENT, label: 'Component',enable:true},
    {value: JIRA.RELEASE, label: 'Release',enable:true},
    {value: JIRA.TASK, label: 'Task',enable:true},
    {value: JIRA.LABEL, label: 'Label',enable:true},
    {value: JIRA.NONE, label: 'None',enable:true},
  ];

  jiraStoryMapOptions=[
    {value: JIRA.TASK, label: 'Task'},
    {value: JIRA.STORY, label: 'Story'},
    {value: JIRA.BUG, label: 'Bug'},
  ];

  jiraReleaseMapOptions=[
    {value: JIRA.EPIC, label: 'Epic',enable:true},
    {value: JIRA.RELEASE, label: 'Release',enable:true},
    {value: JIRA.COMPONENT, label: 'Component',enable:true},
    {value: JIRA.LABEL, label: 'Label',enable:true},
    {value: JIRA.NONE, label: 'None',enable:true},
  ];

  testCaseMappingOptions=[
    {value: JIRA.STORY, label: 'Story',enable:true},
    {value: JIRA.TASK, label: 'Task',enable:true},
    {value: JIRA.SUBTASK, label: 'Story Subtask',enable:true},
  ];

  defectMappingOptions=[
    {value: JIRA.BUG, label: 'Bug',enable:true},
    {value: JIRA.STORY, label: 'Story',enable:true},
    {value: JIRA.TASK, label: 'Task',enable:true},
  ];

  selectedTestCaseMapping=16;
  selectedDefectMapping=2;
  jiraSites=[];

  jiraProjects=[];

    isLoadingSites:boolean=false;
    isLoadingProjects:boolean=false;
    currentStep:number=0;
    addOnBlur = true;
    
    alert: { type: FuseAlertType; message: string } = {
      type   : 'success',
      message: '',
    };
    selectedSite="";
    selectedProject="";
    user: User;
    isQuickMenuHighlighted:boolean=false;
    proposalID="";
    proposal=null;
    testPlan="";
    showConfigs:boolean=false;
    showAlert:boolean=false;
    isPausingIntegration:boolean=false;
    isRemovingIntegration:boolean=false;

    mappingSaved:boolean=false;
    type=0;

    
    /**
     * Constructor
     */
    constructor(
      private zone: NgZone,
      private _router: Router,
      private cdr: ChangeDetectorRef,
      private dialog: MatDialogRef<JiraIntegrationComponent>,
      private matDialog:MatDialog,
      private _userService: UserService,
      private _proposalService: ProposalService,
      private _websocketService: WebsocketService,
      private _formBuilder: UntypedFormBuilder,
      private _confirmationService:FuseConfirmationService,
      private _overlay: Overlay,
      private _viewContainerRef: ViewContainerRef,
      private _technologyDomainService:TechnologyDomainService,
      private _commonService:CommonService,
      private platform: Platform,
      private _analyticsService:AnalyticsService,
      private atlassianService:AtlassianService,

      @Inject(MAT_DIALOG_DATA) public data: any
    )
    {
      
      
      this.proposalID=this.data.proposalID;
      this.proposal=this.data.proposal;
  
      if(this.data && this.data.isTesting){
        this.isTesting=true;

        this.testPlan=this.data.testPlan||"";
      }
  
    }
  
    ngOnInit(): void
    {

      if (this._router.url.includes("project")) {
          this.type=1;
      } else if(this._router.url.includes("testing")) {
        this.type=2;
      }else{
        this.type=0;
      }


      this.currentStep=0;
        // Subscribe to the user service
        this._userService.user$
        .subscribe((user: User) =>
        {
            this.user = user;
             
        });
        this.isLoadingSites=true;
        this.atlassianService.getSites(this.proposal._id).subscribe((response)=>{
          this.isLoadingSites=false;
          if(response.success==true && response.data){
            this.jiraSites=response.data;
          }
        });

        this.initMappings();

         
    }

    initMappings(){
       
      if(this.isAtlassianIntegrated()){

        this.selectedThemeMapping=this.proposal.integrations.jira.mappings.theme;
        this.selectedEpicMapping=this.proposal.integrations.jira.mappings.epic;
        this.selectedStoryMapping=this.proposal.integrations.jira.mappings.story;
        this.selectedReleaseMapping=this.proposal.integrations.jira.mappings.release;

        this.selectedToDoMapping=this.proposal.integrations.jira.mappings.toDo;
        this.selectedInProgressMapping=this.proposal.integrations.jira.mappings.inProgress;
        this.selectedDoneMapping=this.proposal.integrations.jira.mappings.done;
        this.selectedNewMapping=this.proposal.integrations.jira.mappings.new;

        this.importExistingJiraIssues=this.proposal.integrations.jira.importExistingJiraIssues;
        this.ignoreDoneIssues=this.proposal.integrations.jira.ignoreDoneIssues;

        this.selectedSyncMode=this.proposal.integrations.jira.syncMode;
        this.selectedSyncType=this.proposal.integrations.jira.syncType;

        this.selectedProject=this.proposal.integrations.jira.projectID;
        this.selectedSite=this.proposal.integrations.jira.cloudID;

        if(this.isTesting){
          this.selectedTestCaseMapping=this.proposal.integrations.jira.mappings.testCase;
          this.selectedDefectMapping=this.proposal.integrations.jira.mappings.defect;
        }


        this.onChangeTheme({value:this.selectedThemeMapping});
      }

      this.onChangeTheme({value:this.selectedThemeMapping});
    }
  
    closeDialog(){
      if(this.dialog)this.dialog.close({saved:this.mappingSaved});
    }
    onClickConnectJira(){
     
      try{
      if(this.type==0){
        //Connect Jira for Proposal
        this._analyticsService.trackEvent(analytics_event.CONNECT_PROPOSAL_JIRA,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
      }else if(this.type==1){
        localStorage.setItem("type","1");
      //Connect Jira for Project
      this._analyticsService.trackEvent(analytics_event.CONNECT_PROJECT_JIRA,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
      }else if(this.type==2){
        localStorage.setItem("type","2");
      //Connect Jira for Test Plan
      this._analyticsService.trackEvent(analytics_event.TEST_PLAN_CONNECT_JIRA,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
      }
    }catch(error){
      console.error(error);
    }

      localStorage.setItem("planID",this.testPlan);
      this.atlassianService.connectAtlassianAccount(this.proposal._id);
    }

    checkIfAtlassianAuthenticationCompleted(){
      if(this.proposal.integrations?.atlassianToken && this.proposal.integrations?.atlassianToken!=""){
        return true;
      }

      return false;
    }

    onClickReloadSites(){
      if(this.isLoadingSites){
        return;
      }
      this.isLoadingSites=true;
      this.atlassianService.getSites(this.proposal._id).subscribe((response)=>{
        this.isLoadingSites=false;
        if(response.success==true && response.data){
          this.jiraSites=response.data;
        }
      });
    }
    onClickReloadProjects(){
      if(this.isLoadingProjects){
        return;
      }
      this.isLoadingProjects=true;

      this.atlassianService.getSiteProjects(this.selectedSite,this.proposal._id).subscribe((response)=>{
        this.isLoadingProjects=false;

        if(response.success && response.data){
          this.jiraProjects=response.data;
        }
      });
    }

    
    onClickNext(){

      if(this.currentStep==1 && this.selectedSite==""){
        return;
      }

      if(this.currentStep==2 &&  this.selectedProject==""){
        return;
      }


      if(this.currentStep<6){
        this.currentStep++;
      }

      if(this.currentStep==6){
        //Save Mappings
        let request={
          active:true,
          cloudID:this.selectedSite,
          site:this.jiraSites.find((site)=>site.id==this.selectedSite).name,
          projectID:this.selectedProject,
          mappings:{
            theme:this.selectedThemeMapping,
            epic:this.selectedEpicMapping,
            story:this.selectedStoryMapping,
            release:this.selectedReleaseMapping,
            toDo:this.selectedToDoMapping,
            inProgress:this.selectedInProgressMapping,
            done:this.selectedDoneMapping,
            new:this.selectedNewMapping,
            testCase:this.selectedTestCaseMapping,
            defect:this.selectedDefectMapping
          },
          syncMode:this.selectedSyncMode,
          syncType:this.selectedSyncType,
          ignoreDoneIssues:this.ignoreDoneIssues,
          importExistingJiraIssues: this.importExistingJiraIssues
        }

        this.isSavingMappings=true;
        // console.log("Request:",request);

         this.atlassianService.saveJiraMappings(this.proposalID,request).subscribe((response)=>{
          if(response.success){
            this.mappingSaved=true;
            this.isSavingMappings=false;
          }
         })
          
        




      }
    }
    onClickBack(){
      if(this.currentStep>=1){
        this.currentStep--;
      }
    }

    onClickUpdateMappings(){
      if(this.isSavingMappings){
        return;
      }
      let request={
        active:true,
        cloudID:this.selectedSite,
        site:this.jiraSites.find((site)=>site.id==this.selectedSite).name,
        projectID:this.selectedProject,
        mappings:{
          theme:this.selectedThemeMapping,
          epic:this.selectedEpicMapping,
          story:this.selectedStoryMapping,
          release:this.selectedReleaseMapping,
          toDo:this.selectedToDoMapping,
          inProgress:this.selectedInProgressMapping,
          done:this.selectedDoneMapping,
          new:this.selectedNewMapping,
          testCase:this.selectedTestCaseMapping,
          defect:this.selectedDefectMapping
        },
        syncMode:this.selectedSyncMode,
        syncType:this.selectedSyncType,
        ignoreDoneIssues:this.ignoreDoneIssues,
        importExistingJiraIssues: this.importExistingJiraIssues
      }

      this.isSavingMappings=true;

       this.atlassianService.saveJiraMappings(this.proposalID,request).subscribe((response)=>{
        this.isSavingMappings=false;
        if(response.success){
          this.mappingSaved=true;
          this.docSaved = true;
          setTimeout(()=>{
            this.docSaved = false;
          },3000);
        }
       })
        
    }

    isAtlassianIntegrated():boolean{
      let integrated=false;
      
      if(this.proposal && this.proposal.integrations.jira && this.proposal.integrations.jira.projectID && this.proposal.integrations.jira.projectID!=""){

        integrated=true;

      }


      return integrated;
    }

    getIntegrationStatus(){
      return ((this.proposal && this.proposal.integrations && this.proposal.integrations.jira && this.proposal.integrations.jira.active)?this.proposal.integrations.jira.active:false);
    }

    onReauthenticate(){
      const dialogRef = this._confirmationService.open({
        dismissible:true,
        title:"Re-authenticate Jira Integration?",
        message:"Are you sure you want to re-authenticate your integration with Jira?",
        actions:{
          confirm:{
            show:true,
            label:"Confirm",
            color:'warn'
          },
          cancel:{
            show:true,
            label:"Cancel"
          }
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if(result=='confirmed'){ 
          this.onClickConnectJira();
        }
      }); 
    }
    onConfigureSettings(){
      this.showConfigs=true;
    }
    onPauseIntegrations(){
      if(this.isPausingIntegration){
        return;
      }
      const dialogRef = this._confirmationService.open({
        dismissible:true,
        title:"Pause Jira Integration?",
        message:"Are you sure you want to pause your integration with Jira?",
        actions:{
          confirm:{
            show:true,
            label:"Confirm",
            color:'warn'
          },
          cancel:{
            show:true,
            label:"Cancel"
          }
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if(result=='confirmed'){ 
          this.isPausingIntegration=true;
          this.atlassianService.pauseJiraIntegrations(this.proposal._id,!this.proposal.integrations.jira.active).subscribe((response)=>{
        this.isPausingIntegration=false;
        if(response.success){
          if(this.proposal.integrations.jira.active){
            this.proposal.integrations.jira.active=false;
            this.alertMessage="Jira integration paused successfully."
          }else{
            this.proposal.integrations.jira.active=true;
            this.alertMessage="Jira integration resumed successfully."
          }
          
          this.showAlert=true;

          setTimeout(()=>{
            this.showAlert=false;
            this.mappingSaved=true;
          },2000);
        }
          });
        }
      });
    }
    onRemoveIntegrations(){
      if(this.isRemovingIntegration){
        return;
      }
      const dialogRef = this.matDialog.open(DeleteConfirmationDialogComponent, {
        data: {message:"Are you sure you want to remove your integration with Jira? Please be aware that this will remove all links, and if you choose to sync again in the future, these links will be re-created (potentially duplicating your GetGenerative data in Jira)"},
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.yes===true){
          this.isRemovingIntegration=true;

          this.atlassianService.removeJiraIntegrations(this.proposal._id,this.testPlan).subscribe((response)=>{
            this.isPausingIntegration=false;
            if(response.success){
              
              this.alertMessage="Jira integration removed successfully."
              this.showAlert=true;
    
              setTimeout(()=>{
                this.showAlert=false;
                this.dialog.close({saved:true});
              },2000);
            }
        });
        }
      });

    }

    onChangeTheme(event){
      this.selectedThemeMapping=event.value;

      if (this.selectedThemeMapping === JIRA.EPIC) {
        if (this.selectedEpicMapping === JIRA.EPIC) {
          this.selectedEpicMapping = JIRA.NONE;
        }
        if (this.selectedReleaseMapping === JIRA.EPIC) {
          this.selectedReleaseMapping = JIRA.NONE;
        }
      } else if (this.selectedThemeMapping === JIRA.COMPONENT) {
        if (this.selectedEpicMapping === JIRA.COMPONENT) {
          this.selectedEpicMapping = JIRA.NONE;
        }
        if (this.selectedReleaseMapping === JIRA.COMPONENT) {
          this.selectedReleaseMapping = JIRA.NONE;
        }
      } else if (this.selectedThemeMapping === JIRA.RELEASE) {
        if (this.selectedEpicMapping === JIRA.RELEASE) {
          this.selectedEpicMapping = JIRA.NONE;
        }
        if (this.selectedReleaseMapping === JIRA.RELEASE) {
          this.selectedReleaseMapping = JIRA.NONE;
        }
      }
       this.updateOptionStates();
    }
    onChangeEpic(event){
      this.selectedEpicMapping = event.value;

  // Reset conflicting selections in other mappings based on the selected epic mapping
  if (this.selectedEpicMapping === JIRA.EPIC) {
    if (this.selectedThemeMapping === JIRA.EPIC) {
      this.selectedThemeMapping = JIRA.NONE;
    }
    if (this.selectedReleaseMapping === JIRA.EPIC) {
      this.selectedReleaseMapping = JIRA.NONE;
    }
  } else if (this.selectedEpicMapping === JIRA.COMPONENT) {
    if (this.selectedThemeMapping === JIRA.COMPONENT) {
      this.selectedThemeMapping = JIRA.NONE;
    }
    if (this.selectedReleaseMapping === JIRA.COMPONENT) {
      this.selectedReleaseMapping = JIRA.NONE;
    }
  } else if (this.selectedEpicMapping === JIRA.RELEASE) {
    if (this.selectedThemeMapping === JIRA.RELEASE) {
      this.selectedThemeMapping = JIRA.NONE;
    }
    if (this.selectedReleaseMapping === JIRA.RELEASE) {
      this.selectedReleaseMapping = JIRA.NONE;
    }
  }

  // Update option availability for all mappings
  this.updateOptionStates();
    }
    onChangeTestCase(event){
      this.selectedTestCaseMapping = event.value;
    }
    onChangeDefect(event){
      this.selectedDefectMapping=event.value;
    }
    onChangeRelease(event){
      this.selectedReleaseMapping = event.value;

      // Reset conflicting selections in other mappings based on the selected release mapping
      if (this.selectedReleaseMapping === JIRA.EPIC) {
        if (this.selectedThemeMapping === JIRA.EPIC) {
          this.selectedThemeMapping = JIRA.NONE;
        }
        if (this.selectedEpicMapping === JIRA.EPIC) {
          this.selectedEpicMapping = JIRA.NONE;
        }
      } else if (this.selectedReleaseMapping === JIRA.COMPONENT) {
        if (this.selectedThemeMapping === JIRA.COMPONENT) {
          this.selectedThemeMapping = JIRA.NONE;
        }
        if (this.selectedEpicMapping === JIRA.COMPONENT) {
          this.selectedEpicMapping = JIRA.NONE;
        }
      } else if (this.selectedReleaseMapping === JIRA.RELEASE) {
        if (this.selectedThemeMapping === JIRA.RELEASE) {
          this.selectedThemeMapping = JIRA.NONE;
        }
        if (this.selectedEpicMapping === JIRA.RELEASE) {
          this.selectedEpicMapping = JIRA.NONE;
        }
      }
    
      // Update option availability for all mappings
      this.updateOptionStates();
    }

    private updateOptionStates() {
    
        const selectedValues = new Set([this.selectedThemeMapping, this.selectedEpicMapping, this.selectedReleaseMapping]);
      
        // Enable or disable options in jiraThemeMapOptions
        this.jiraThemeMapOptions.forEach(option => {
          option.enable = !selectedValues.has(option.value) || option.value === JIRA.NONE;
        });
      
        // Enable or disable options in jiraEpicMapOptions
        this.jiraEpicMapOptions.forEach(option => {
          option.enable = !selectedValues.has(option.value) || option.value === JIRA.NONE;
        });
      
        // Enable or disable options in jiraReleaseMapOptions
        this.jiraReleaseMapOptions.forEach(option => {
          option.enable = !selectedValues.has(option.value) || option.value === JIRA.NONE;
        });
       

      this.cdr.detectChanges();
    }

  }
  