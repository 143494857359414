 

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'drawer'"
    [position]="'right'"
    (openedChanged)="openedChanged($event)"

    #drawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex items-center justify-between mt-5 ml-3 mr-3">
            <ng-container>
                <div class="flex flex-col">
                    <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->
                    <span class="ml-2 font-normal text-sm text-gray-400 d-block">Insights</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- Close button -->
                <button mat-icon-button (click)="onCloseDrawer()" >
                  <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
        <div class="flex flex-col mx-5">
            <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->
            <span class="font-medium text-xl text-black d-block">Process New Insights</span>

            <div class="flex gap-2 justify-start items-center mt-2">

                @if(insightsToBeProcessed.length){
                <button (click)="onClickRegenerate()" class="cursor-pointer rounded-full px-7 py-3 bg-[#4F45E4] text-white font-normal">Regenerate</button>
                }@else{
                <button 
                class="cursor-not-allowed rounded-full px-7 py-3 bg-gray-400 text-white font-normal" 
                disabled>
                Regenerate
            </button>
        }
                <button (click)="onCloseDrawer()" class="cursor-pointer rounded-full px-8 py-3 bg-transparent cancelBtn text-black font-normal">Cancel</button>

                
            </div>

            <div class="mt-7 text-lg font-semibold">
                @if(insightsToBeProcessed.length){
                    Following insights will be processed for regeneration
                }@else{
                    No insights available for processing
                }
            </div>


            @if (insightsToBeProcessed.length) {
            <div class="flex flex-col mt-6 mb-10">

                @for(insight of insightsToBeProcessed;track insight){
                <div class="flex flex-col gap-1 mt-5">
                    <div class="text-[#3B4554] text-lg font-semibold" [innerHTML]="getParsedText(insight.title)">
                        
                    </div>
                    <div class="text-[#3B4554] text-lg mt-1 font-normal" [innerHTML]="getParsedText(insight.notes)">
                        
                    </div>
                    <div class="flex justify-between items-center mt-2">

                        <div class="text-md font-normal text-[#94A3B8]">Created on  {{insight.created | date:'d MMM, y'}}</div>
                        <div class="text-md px-3 py-1 font-medium text-[#009938] rounded-lg bg-[#EFFFF1]">New</div>

                    </div>

                    <div class="w-full bg-[#CBD5E1] mt-7 h-[1.7px]"></div>
                </div>
            }

            </div>
        }

        </div>
        
        
    </div>
</fuse-drawer>
