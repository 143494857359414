import { filter } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { DatePipe, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { marked } from 'marked';

@Component({
  selector: 'app-regenerate-insights-view',
  standalone: true,
  imports: [DatePipe,FuseDrawerComponent,NgIf,MatIcon,MatMenuModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule],
  templateUrl: './regenerate-insights-view.component.html',
  styleUrl: './regenerate-insights-view.component.scss'
})
export class RegenerateInsightsViewComponent {
  @ViewChild("drawer")public drawer:FuseDrawerComponent;
 
  insightquestionForm:UntypedFormGroup;
  questionsArray:FormArray<any>;
  @Output()
  onRegenerateTheme=new EventEmitter<any>();

  @Output()
  onRegenerateEpic=new EventEmitter<any>();


  insightsToBeProcessed=[];

  type=0;
  epic=null;
  theme=null;

  constructor(private _formBuilder:UntypedFormBuilder){
    this.insightquestionForm = this._formBuilder.group({
      questions: this._formBuilder.array([]) // Empty FormArray initially
    });
     this.questionsArray = this.insightquestionForm.get('questions') as FormArray;
  }

  initRegenView(allInsights,theme,epic,type){

     
    if(!theme){
      return;
    }
    this.type=type;
    this.theme=theme;
    this.epic=epic;
    if(type==0){
      let themeInsights=allInsights.filter((insight)=>insight.capability==theme.theme);
      
      this.insightsToBeProcessed=themeInsights.flatMap((insight)=>insight.questions.filter((question)=> question.notes!="" && !question.used_in_generation));

      //console.log("ALl Insights: ",this.insightsToBeProcessed);

    }else{
      let themeInsights=allInsights.filter((insight)=>insight.capability==theme.theme);
      
      this.insightsToBeProcessed=themeInsights.flatMap((insight)=>insight.questions.filter((question)=> question.epic==epic.name &&  question.notes!="" && !question.used_in_generation));

    }

  }

   getParsedText(text){
    try{
      return  marked.parse(text);
    }catch(error){

    }
    return text;
  }
  onClickRegenerate(){
    if(this.type==0){
      this.onRegenerateTheme.emit({theme:this.theme});
    }else{
      this.onRegenerateEpic.emit({theme:this.theme,epic:this.epic});
    }
    this.drawer.close();
  }
   
  openedChanged(event){
    
  }
  onDeleteTheme(){

  }
  onCloseDrawer(){
    this.drawer.close();
    this.insightquestionForm.reset();
  }
  onSaveThemeEvent(){
    this.drawer.close();
  } 
}
