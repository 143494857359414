import { labels } from './../../../../mock-api/apps/scrumboard/data';
import { DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';

import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonService } from 'app/core/common/common.service';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FormArray, FormsModule, ReactiveFormsModule, RequiredValidator, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { InsightsService } from 'app/core/common/insights_service';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { NgxTiptapModule } from 'ngx-tiptap';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { ConfirmationDialogComponent } from 'app/modules/common/confirmation-dialog/confirmation-dialog.component';
import Speech from 'speak-tts';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { Transaction } from '@tiptap/pm/state';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { marked } from 'marked';
import { AutosizeModule } from 'ngx-autosize';
import { GenericConfirmationComponent } from 'app/modules/common/generic-confirmation/generic-confirmation.component';
import { Platform } from '@angular/cdk/platform';
declare var webkitSpeechRecognition: any;
@Component({
  selector: 'app-ai-insights',
  standalone: true,
  
  imports:[AutosizeModule,MatTooltip,NgxTiptapModule,EditorControlsComponent,DatePipe,MatExpansionModule,DragDropModule,MatSelectModule,NgStyle,MatMenuModule,MatMenuTrigger,NgClass,FuseDrawerComponent, MatIconModule,  FormsModule, FuseAlertComponent, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule,  MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,MatChipsModule,NgFor],
  templateUrl: './ai-insights.component.html',
  styleUrl: './ai-insights.component.scss',
})
export class AiInsightsComponent implements OnInit{
  @ViewChild(FuseDrawerComponent) insightDrawer: FuseDrawerComponent;
  @ViewChild("filePropertiesDrawer") filePropertiesDrawer: FuseDrawerComponent;




  @ViewChild("fileInput") private fileInput:ElementRef;
  @Input() proposalID:string;
  @Input() page_id:string='proposal';

  _proposalDetail: any = {};
  // @Input() proposal:any;
  @Input() set proposal(value: any) {
    if (value) {
      // Use a setter to detect changes in the Input property
      this._proposalDetail = value;
      this.ngOnInit();
      this._changeDetectorRef.detectChanges(); // Optionally force change detection
    }
  }

  @Input() user:any;

  @Input() accessStatus:any;
  @Output() onKCDocUpload=new EventEmitter<any>();

  answerEditors: Editor[] = []; 
  answerEditors_readonly: Editor[] = []; 

  selectedTheme="";
  recording:boolean=false;
  insightSaved:boolean=false;
  isLoadingInsights:boolean=true;
  alert: { type: FuseAlertType; message: string } = {
    type   : 'success',
    message: '',
  };
  maximumScoreValue: number = (environment.server=='stage')?20:80;
  sizeLimitAlert:boolean=false;
  saveClicked: boolean = false;
  selectedSupportingFiles=[];
  proposalInsights:any[]=[];
  isSavingInsight=false;
  currentScore:number=0;
  selectedInsightType=2;
  selectedQuestion=0;
  isRegeneratingProposal=false;
  
  isProcessingFiles:boolean=false;
  isPendingSave:boolean=false;

  isUploadingFiles=false;
  isValidatingInsight=false;

  insightForm:UntypedFormGroup;
  insightquestionForm:UntypedFormGroup;
  capabilities:any[]=[];
  capabilityEpics:any[]=[];
  epics=[];

  selectedInsightQuestion=null;
  selectedInsightQuestionNotes="";
  selectedCapabilityScore=0;
  selectedInsight=null;
  isGeneratingInsights=false;
  selectedSupportingDoc:any=null;
  paymentDetails$: Observable<any>;
  questionsArray: FormArray<any>;

  speechRecognition;
  speech;
  speechData;
  showQuickPromptMenu:boolean=false;
  insideTextArea=false;
  isQuickMenuHighlighted:boolean=false;

  mouseInitialPosition=null;
  is_write_allowed = false;
  mouseDownPos=0;
  private selectedFeatureData={
    type:"",
    index:0
  };
  menuPosition = { top: '500px', left: '80px' };
  quickSelectedText="";
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  overflowbubble:boolean=false;

  hasProcessedLocalInsight=true;

  all_docs:any [] = [];

  regenerateProposalConfirmation:FuseConfirmationConfig={
    title: "",
    message: "Are you sure you want to regenerate " + this.page_id +"?",
    icon: {
        show: true,
        name: "",
        color:'primary'
    },
    actions: {
        confirm: {
            show: true,
            label: "Yes",
            color:'primary',
        },
        cancel: {
            show: true,
            label: "Cancel"
        }
    },
    dismissible: true
  }


  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Answer the above question to enhance the input quality score.",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.overflowbubble = true;
      this.updateSelectedText(editor);
    },
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Describe your current process or how you envision it.",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
    
  });

  editor3 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter insight question",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
    
  });

  sortedInsights=[];

  editor2_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Describe your current process or how you envision it.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Answer the above question to enhance the input quality score.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  readonly dialog = inject(MatDialog);

  constructor(private platform:Platform,private zone: NgZone,private renderer: Renderer2,private _websocketService:WebsocketService,private _insightsService:InsightsService,private _changeDetectorRef: ChangeDetectorRef,private _commonService:CommonService,private _formBuilder: UntypedFormBuilder,private _proposalService:ProposalService){
    try{
      this.speechRecognition = new webkitSpeechRecognition();
      
      this.speech = new Speech(); // will throw an exception if not browser supported
      if (this.speech.hasBrowserSupport()) {
        this.speechRecognition.continuous = true;
        this.speechRecognition.interimResults = true;
        console.log(
          'speech synthesis supported',
          this.speech.hasBrowserSupport()
        );
        this.speech
          .init({
            volume: 1,
            lang: 'en-GB',
            rate: 1.2,
            pitch: 1,
            voice: 'Google UK English Female',
            splitSentences: true,
            // listeners: {
            //   onvoiceschanged: (voices) => {
            //     console.log('Event voiceschanged', voices);
            //   },
            // },
          })
          .then((data) => {
            // The "data" object contains the list of available voices and the voice synthesis params
            this.speechData = data;
          })
          .catch((e) => {
            console.error('An error occured while initializing : ', e);
          });
      }
    }catch(error){

    }

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "insight_doc_processed")
    ).subscribe((message:any) => {

      /*
        "type": "insight_doc_processed",
        "taskID": "66a089e8c9e74b00089474d4",
        "data": {
            "proposalID": "66a089e8c9e74b00089474d4",
            "documentID": "66a09c1c5a18bb0008ed8326",
            "insightID":"66a089eb4c2a3000085ce7ce"
        }
      }
      */

      if(this._proposalDetail._id==message.data.proposalID){
        if(this.selectedInsight && message.data.insightID==this.selectedInsight._id){
          this.hasProcessedLocalInsight=true;
          this.isValidatingInsight=false;
          this.isProcessingFiles=false;
        }

        for(let i=0;i<this._proposalDetail.supporting_docs.length;i++){
          if(this._proposalDetail.supporting_docs[i]._id==message.data.documentID){
            this._proposalDetail.supporting_docs[i].isProcessed=true;
          }
        }

        this.initProposalInsights();

      }
    });


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "insight_generated")
    ).subscribe((message:any) => {

      this.initProposalInsights();

    });


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "validated_insight_question")
    ).subscribe((message:any) => {
      console.log("Validation Result:",message);
      this.isValidatingInsight=false;
      this.initProposalInsights();
    });


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "supporting_doc_summary")
    ).subscribe((message) => {
      let docID=message.taskID;
      for(let i=0;i<this._proposalDetail.supporting_docs.length;i++){
        if(this._proposalDetail.supporting_docs[i]._id==docID){
          this._proposalDetail.supporting_docs[i].summary=message.data;
        }
      }

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "validate_supporting_doc")
    ).subscribe((message:any) => {
      let isValid=message.data.valid;
      let docID=message.data.documentID;

      for(let i=0;i<this._proposalDetail.supporting_docs.length;i++){
        if(this._proposalDetail.supporting_docs[i]._id==docID){
          this._proposalDetail.supporting_docs[i].valid=isValid;
        }
      }

    });
  }

  ngOnInit(): void {
    this.insightForm = this._formBuilder.group({
      title    : ['',Validators.required],
      notes    : [''],
      capability: ['',Validators.required],
      epic:["",Validators.required],
    });
    
    

    if(this.accessStatus != 'view')this.initProposalInsights();

    this.insightquestionForm = this._formBuilder.group({
      questions: this._formBuilder.array([]) // Empty FormArray initially
    });
    this.questionsArray = this.insightquestionForm.get('questions') as FormArray;
    // console.log(this.proposal);
    this.all_docs = [];
    if(this._proposalDetail && this._proposalDetail.supporting_docs && this._proposalDetail.supporting_docs.length){
      this._proposalDetail.supporting_docs.forEach((doc) => {
        doc['document_type']='supporting_docs';
        this.all_docs.push(doc);
      });
    }
    if(this._proposalDetail && this._proposalDetail.knowledge_center_docs && this._proposalDetail.knowledge_center_docs.length){
      this._proposalDetail.knowledge_center_docs.forEach((doc) => {
        doc['document_type']='knowledge_center_docs';
        this.all_docs.push(doc);
      });
    }
  }
  
  initProposalInsights(){
    this._proposalService.getProposalInsights(this.proposalID).subscribe((response)=>{

      if(response.success==true){
        this.proposalInsights=response.data;
        this.capabilities=[];
        this.capabilityEpics=[];


        for(let insight of this.proposalInsights){
          this.capabilities.push({label:insight.capability,value:insight._id});

         let findTheme=this._proposalDetail.epics.find((theme)=>theme.theme==insight.capability);

         if(findTheme){
          let themeEpics=findTheme.epics.map((epic)=>
            {
              return {label:epic.name,value:epic.name}
          });
          this.capabilityEpics.push({theme:findTheme.theme,epics:themeEpics});
         }

        }
        this.selectedTheme=this.capabilities[0].label;
        /*
        for(let theme of this._proposalDetail.epics){
          this.capabilities.push({label:theme.theme,value:theme.theme});
          let themeEpics=theme.epics.map((epic)=>
          {
            return {label:epic.name,value:epic.name}
        });
        this.capabilityEpics.push({theme:theme.theme,epics:themeEpics});
        }
        */
        this.epics=this.capabilityEpics[0].epics;
        this.currentScore=this._insightsService.getQualityScore(this._proposalDetail,this.proposalInsights);
        this.initSortedInsights();
        this.isLoadingInsights=false;
        this.resetSelectInsight();
        // this.onKCDocUpload.emit({refresh:true});
      }
    });
  }

  getThemeDefaultEpic(themeName:string){

    for(let theme of this._proposalDetail.epics){
      if(theme.theme==themeName){
        return theme.epics[0].name || "";
      }
    }

    return "";
  }

  initSortedInsights(){
    this.sortedInsights=[];

    this.proposalInsights = this.proposalInsights.map((insight) => {
      let updatedInsight = { ...insight };
      let defaultEpic=this.getThemeDefaultEpic(insight.capability);
      updatedInsight.questions = updatedInsight.questions.map((question) => {
          return {
              ...question,
              epic: question.epic ?? defaultEpic
          };
      });
  
      return updatedInsight;
  });

    this.sortedInsights = this._proposalDetail.epics.map(theme => {
      const themeInsights = this.proposalInsights.filter(
          insight => insight.capability === theme.theme
      );
      const epics = theme.epics.map(epic => {
          const epicInsights = themeInsights.map(insight => {
              const filteredQuestions = insight.questions.filter(
                  question => question.epic === epic.name
              );
              return {
                  ...insight,
                  questions: filteredQuestions
              };
          });
  
          return {
              epic: epic,
              insights: epicInsights
          };
      });
  
      return {
          theme: theme,
          epics: epics
      };
  });



  console.log("Sorted Insights:",this.sortedInsights);


  }

  getEpicInsights(theme,epic){

    let themeInsights=this.proposalInsights.filter((insight)=>insight.capability==theme);

    themeInsights=themeInsights.map((insight)=>{
      let updateInsight=insight;
      let filteredQuestions=insight.questions.filter((question)=>question.epic==epic);
      updateInsight.questions=filteredQuestions;
      return updateInsight;

    });

    return themeInsights;
  }
  getCapabilityEpics(insight){
    let uniqueEpics=[];
    for(let question of insight.questions){
      if(!uniqueEpics.includes(question.epic)){
        uniqueEpics.push(question.epic);
      }
    }

    return uniqueEpics;
  }

  calculateHeight(text: string, charsPerLine: number, lineHeight: number): number {
    const lines = Math.ceil(text.length / charsPerLine);
    return lines * lineHeight;
  }

  resetSelectInsight(){
    if(this.selectedInsight!=null){
      for(let insight of this.proposalInsights){
        if(insight._id==this.selectedInsight._id){
          this.selectedInsight=insight;
          break;
        }
      }

      for(let question of this.selectedInsight.questions){
        if(this.selectedInsightQuestion._id==question._id){
          this.selectedInsightQuestion=question;
          break;
        }
      }

      this.onSelectInsight(this.selectedInsight);

    }
  }

  getCapabilityPerQuestionScore(){
    let selectedCapability=this.insightForm.get("capability").value;
    if(selectedCapability==""){
      selectedCapability=this.proposalInsights[0]._id;
    }
    
    let insight=this.proposalInsights.find((insight)=>insight._id==selectedCapability)[0];
    return this.getPerQuestionScore(insight);
  }

  getPerQuestionScore(insight){
    if(!insight || !insight.hasOwnProperty("questions")){
      return 0;
    }
    return this._insightsService.getInsightPerQuestionScore(this.proposalInsights,insight);
  }

  getGenerateInsightsTextColor(){
    if(!this.isGeneratingInsights && !this.isLoadingInsights){
      return "#4F45E4";
    }

    return "#CFD1D4";
  }

  getRegenerateTextColor(){
    if(this.currentScore>=this.maximumScoreValue){
      return "#4F45E4";
    }

    return "#CFD1D4";
  }

  getRegenerateBorderColor(){
    if(this.currentScore>=this.maximumScoreValue){
      return "#FFFFFF";
    }
    return "#94A3B8";
  }

  getCurrentScoreString(){
     
    if(this.currentScore<100 && this.currentScore>0 && (this.currentScore<79 || this.currentScore>81 )){
      return this.currentScore.toString();
    }

    return "";
  }

  getFileTypeString(type:number){

    let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.value===type);


    return fileValue.label;
  }

  onSelectInsightQuestion(question,insight){ 
    this.hasProcessedLocalInsight=true;
    this.selectedInsightQuestion=question;
    this.selectedInsight=insight;
    this.selectedSupportingFiles=[];
    this.selectedInsightQuestionNotes=this.selectedInsightQuestion.notes;

    let parsedNotes=marked.parse(this.selectedInsightQuestion.notes);
    this.insightForm = this._formBuilder.group({
      title    : [this.selectedInsightQuestion.title,Validators.required],
      notes    : [parsedNotes],
      capability:[this.selectedInsight._id,Validators.required],
      epic:[this.selectedInsightQuestion.epic,Validators.required]
    });

    this.selectedCapabilityScore=this.getPerQuestionScore(this.selectedInsight);
    if(this.selectedInsightQuestion.linkedDocs && this.selectedInsightQuestion.linkedDocs.length){
      for(let file of this._proposalDetail.supporting_docs){
        if(this.selectedInsightQuestion.linkedDocs.includes(file._id)){
          this.selectedSupportingFiles.push(file);
        }
      }
      this._changeDetectorRef.detectChanges();
    }

    this.isProcessingFiles=false;

    console.log("Selected Insight:",this.selectedInsight);

    for(let insightDoc of this.selectedSupportingFiles){
      if(insightDoc.isProcessed==false){
        this.isProcessingFiles=true;
        break;
      }
    }

    this.selectedTheme=this.selectedInsight.capability;
   
   
    let selectedCap=this.capabilities.find((cap)=>cap.label==this.selectedTheme);
    
    if(selectedCap){
    let themEpics=this.capabilityEpics.find((themeEpic)=>themeEpic.theme==selectedCap.label);
    this.epics=themEpics.epics;
    }

    this.isValidatingInsight=this.selectedInsightQuestion.processing;

    // console.log("On Select Insight Files:",this.selectedSupportingFiles);

    this.insightDrawer.open();
  }

  onSelectInsight(insight){ 
    this.hasProcessedLocalInsight=true;
    this.selectedInsight=insight;
    this.questionsArray.clear();
    if(insight.questions && insight.questions.length){
      insight.questions.forEach((question) => {
        let parsedNotes=marked.parse(question.notes);
        if(this.accessStatus == 'admin'){
          const newEditor1 = new Editor({
            content: parsedNotes,
            extensions: [
              StarterKit.configure({
                bulletList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
                orderedList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
              }),
              BulletList,
              ListItem,
              Underline,
              TextStyle,
              Color,
              Placeholder.configure({
                placeholder: "Answer the above question to enhance the input quality score.",
              }),
              Heading.configure({
                levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
              })
            ],
            parseOptions: {
              preserveWhitespace: "full",
            },
            editorProps: {
              attributes: {
                class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
                spellCheck: 'false',
              },
            },
            onSelectionUpdate: ({ editor }) => {
              this.overflowbubble = true;
              this.updateSelectedText(editor);
            },
          });
          this.answerEditors.push(newEditor1);
        }
        else {
          const newEditor2 = new Editor({
            content: parsedNotes,
            extensions: [
              StarterKit.configure({
                bulletList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
                orderedList: {
                  keepMarks: true,
                  keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
                },
              }),
              BulletList,
              ListItem,
              Underline,
              TextStyle,
              Color,
              Placeholder.configure({
                placeholder: 'Describe your current process or how you envision it.',
              }),
              Heading.configure({
                levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
              })
            ],
            editable: false,
            enablePasteRules: false,
            parseOptions: {
              preserveWhitespace: "full",
            },
            editorProps: {
              attributes: {
                class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
                spellCheck: 'false',
              },
            }
          });
          this.answerEditors_readonly.push(newEditor2);
        }
        this.questionsArray.push(this._formBuilder.group({
          title     : [question.title || ''],  // Assign default values or empty strings
          notes     : [parsedNotes || ''],
          capability: [question.capability || '']
        }));
      });
    }

    this.selectedTheme=this.selectedInsight.capability;
   
   
    let selectedCap=this.capabilities.find((cap)=>cap.label==this.selectedTheme);
    
    if(selectedCap){
    let themEpics=this.capabilityEpics.find((themeEpic)=>themeEpic.theme==selectedCap.label);
    this.epics=themEpics.epics;
    }

    this._changeDetectorRef.detectChanges();
    this.paymentDetails$ = this.questionsArray.valueChanges;
    this.insightDrawer.open();
    
  }

  onSelectFile(supportingDoc){
    this.selectedSupportingDoc=supportingDoc;
    this.filePropertiesDrawer.open();
  }

  getSupportingDocSummaryFormatted(summary:string){
    return marked.parse(summary);
  }

  onChangeCapabilitySelection(event){
    
    if(event.value!=""){
      this.insightForm.get("epic").setValue("");
      let selectedInsight=this.proposalInsights.find((insight)=>insight._id==event.value);
      console.log("Select Capability:",selectedInsight);
      if(selectedInsight){
      this.selectedCapabilityScore=this.getPerQuestionScore(selectedInsight);
      this._changeDetectorRef.detectChanges();
      }
      this.selectedTheme=event.value;
      let selectedCap=this.capabilities.find((cap)=>cap.value==event.value);
      let themEpics=this.capabilityEpics.find((themeEpic)=>themeEpic.theme==selectedCap.label);
      this.epics=themEpics.epics;
    }

  }
  
  onChangeEpicSelection(event){
    
    if(event.value!=""){
      let selectedInsight=this.proposalInsights.find((insight)=>insight._id==event.value);
      console.log("Select Capability:",selectedInsight);
      if(selectedInsight){
      this.selectedCapabilityScore=this.getPerQuestionScore(selectedInsight);
      this._changeDetectorRef.detectChanges();
      }
    }
  }



  getFileClass(fileType: number): string {
    switch (fileType) {
      case 0: return 'bg-red-600';
      case 1: return 'bg-green-600';
      case 2: return 'bg-gray-600';
      case 3: return 'bg-blue-600';
      case 4: return 'bg-blue-500';
      case 5: return 'bg-yellow-600';
      case 6: return 'bg-yellow-500';
      case 7: return 'bg-orange-600';
      case 8: return 'bg-orange-500';
      case 9: return 'bg-amber-600';
      case 10: return 'bg-green-600';
      default: return 'bg-white';
    }
  }

  onChangeInsightType(type){
    this.selectedInsightType=type;
  }

  dropped(event: CdkDragDrop<any[]>): void
  {
    if(this.accessStatus=='admin'){
      // Move the item in the array
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any
  {
      return item.id || index;
  }

  openedChanged(event){

    if(event===false){
      this.clear();
    }
    setTimeout(() => {
      const editorElement1 = document.querySelector('#answerTextReadonly div');
      if (editorElement1) {
        editorElement1.setAttribute('contenteditable', 'false');
        this.editor_readonly.setEditable(false);
      }
      const editorElement2 = document.querySelector('#documentTextReadonly div');
      if (editorElement2) {
        editorElement2.setAttribute('contenteditable', 'false');
        this.editor2_readonly.setEditable(false);
      }
    },1000);
  }

  openedChangedFileDrawer(event){
    setTimeout(() => {
      const editorElement1 = document.querySelector('#answerTextReadonly div');
      if (editorElement1) {
        editorElement1.setAttribute('contenteditable', 'false');
        this.editor_readonly.setEditable(false);
      }
      const editorElement2 = document.querySelector('#documentTextReadonly div');
      if (editorElement2) {
        editorElement2.setAttribute('contenteditable', 'false');
        this.editor2_readonly.setEditable(false);
      }
    },1000);
  }

  onCloseFileDrawer(){
    this.filePropertiesDrawer.close();
  }

  clear(){
    //reset values
    this.onCloseDrawer();
  }

  onCloseDrawer(){
    this.selectedInsight=null;
    this.selectedInsightQuestion=null;
    this.selectedSupportingFiles=[];
    this.resetFileInput();
    if(this.saveClicked)this.onKCDocUpload.emit({refresh:true});
    this.insightDrawer.close();
    this.saveClicked = false;
  }

  resetFileInput(): void {
    if(this.fileInput){
      const fileInput = this.fileInput.nativeElement;
      this.renderer.setProperty(fileInput, 'value', '');
      this.renderer.removeChild(fileInput.parentNode, fileInput);
      this.renderer.appendChild(fileInput.parentNode, fileInput);
    }
  }

  onDeleteInsightQuestion(){
    if(this.selectedInsight!=null && this.selectedInsightQuestion!=null){
      this._proposalService.deleteInsightQuestion(this.selectedInsight._id,this.selectedInsightQuestion._id).subscribe((response)=>{
        this.insightDrawer.close();
        if(response.success==true){
          this.initProposalInsights();this.onKCDocUpload.emit({refresh:true});
        }else{

        }
      });
    }
  }

  onAddNewInsight(){
    this.selectedInsight=this.proposalInsights[0];
    this.selectedInsightQuestion={title:"",notes:"",_id:""};
    this.insightForm = this._formBuilder.group({
      title    : [this.selectedInsightQuestion.title,Validators.required],
      notes    : [this.selectedInsightQuestion.notes],
      capability:[this.selectedInsight._id,Validators.required],
      epic:["",Validators.required],
      linkedDocs:[],
  });
    this.insightDrawer.open();
  }

  getSelectedInsightQuestionRelevanceScore(){
    if(this.selectedInsightQuestion && this.selectedInsightQuestion.valid){
      return this.selectedCapabilityScore;
    }else if(this.selectedInsightQuestion && this.selectedInsightQuestion.valid==false &&  this.stripHtmlTags(this.selectedInsightQuestion.notes).trim()==""){
      return this.selectedCapabilityScore;
    }

    return 0;
  }

  onDeleteSupportingDocument(file:any){
    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {title:"Are you sure you want to delete this document?",subtitle:"All questions answered from this document will remain intact",yesAction:"Delete",noAction:"Cancel"},
    });


    dialogRef.afterClosed().subscribe(result => {

      if(result.yes===true){

        for(let i=0;i<this._proposalDetail.supporting_docs.length;i++){
          if(this._proposalDetail.supporting_docs[i]._id==file._id){
            this._proposalDetail.supporting_docs.splice(i,1);
            this._proposalService.deleteProposalSupportingDoc(this._proposalDetail._id,file._id).subscribe((response)=>{});
            this.filePropertiesDrawer.close();
            this.onKCDocUpload.emit({refresh:true});
            break;
          }
        }

      }

    });





   

  }

  onSaveInsightQuestion(){
    this.isSavingInsight=true;
    let questionData=this.insightForm.value;//console.log(questionData);
    let associatedDocs=this._proposalDetail.supporting_docs.map((doc)=>doc._id);
    let linkedDocs=this.selectedInsightQuestion.linkedDocs;console.log(this.selectedInsightQuestion);

    let oldInsight=this.selectedInsight._id;
    if(this.selectedInsight._id!=this.insightForm.get("capability").value){
      this.selectedInsight=this.proposalInsights.find((insight)=>insight._id==this.insightForm.get("capability").value);
    }



    this._proposalService.createUpdateInsightQuestion(questionData.capability,questionData.title,this.insightForm.get("notes").value,associatedDocs,this.selectedInsightQuestion._id,linkedDocs,oldInsight,(this.selectedInsightQuestion.add_via_interaction?this.selectedInsightQuestion.add_via_interaction:false),(this.selectedInsightQuestion.reference?this.selectedInsightQuestion.reference:''),this.insightForm.get("epic").value).subscribe((response)=>{
      this.isSavingInsight=false;
      
      if(response.success==true){
      this.selectedInsightQuestion._id=response.id;
      this.selectedInsightQuestion.epic=this.insightForm.get("epic").value;
      }
      this.saveClicked = true;
      this.insightSaved=true;
      setTimeout(()=>{
        this.insightSaved=false;
      },3000);




      if((this.selectedInsightQuestionNotes!=this.insightForm.get("notes").value && this.insightForm.get("notes").value!="") || (this.insightForm.get("notes").value!="" && !this.selectedInsightQuestion.valid)){
        this.isValidatingInsight=true;
        this._websocketService.sendValidateInsightQuestionCommand(this.selectedInsight._id,this.selectedInsightQuestion._id,this.selectedInsightQuestion.title,this.selectedInsight._id,this._proposalDetail.proposal_meta.technology,this._proposalDetail.proposal_meta.product,this._proposalDetail.proposal_meta.industry,this.insightForm.get("notes").value);
      }

      this.initProposalInsights();

    });

  }

  getTotalPendingInsights(){
    let totalPending=0;
    for(let insight of this.proposalInsights){
      for(let question of insight.questions){
        if(question.notes.trim()==""){
          totalPending++;
        }
      }
    }
    return totalPending;
  }

  getInsightScoreColor(){
    if(this.selectedInsightQuestion && this.selectedInsightQuestion.valid==true){
     return "#16A34A";
    }else if(this.selectedInsightQuestion && this.selectedInsightQuestion.valid==false && this.stripHtmlTags(this.selectedInsightQuestion.notes).trim()!=""){
      return "#FF0000"
    }else{
      return "#A5A5A5"
    }
  }

  getQuestionScore(question:any,insight:any){
    if(question.valid==true || (question.valid==false && this.stripHtmlTags(question.notes).trim()=="") ){
      return this.getPerQuestionScore(insight);
    }
    return 0;
  }

  stripHtmlTags(html: string): string {
    return html.replace(/<\/?[^>]+>/gi, '');
  }

  getInsightQuestionScoreColor(question:any){  
    if(question.valid==true){
     return "#16A34A";
    }else if(question.valid==false && this.stripHtmlTags(question.notes).trim()!=""){
      return "#FF0000"
    }else{
      return "#A5A5A5"
    }
  }

  getPendingInsightQuestionsCount(insight){
    let count=0;
    for(let question of insight.questions){
      if(question.valid==false){
        count++;
      }
    }
    return count;
  }

  getProcessedInsightQuestionsCount(insight){
    let count=0;
    for(let question of insight.questions){
      if(question.valid==true){
        count++;
      }
    }
    return count;
  }

  getTotalProcessedInsights(){
    let totalProcessed=0;
    for(let insight of this.proposalInsights){
      for(let question of insight.questions){
        if(question.notes.trim()!=""){
          totalProcessed++;
        }
      }
    }
    return totalProcessed;
  }

  getTotalUnusedProcessedInsights(){
    let totalProcessed=0;
    for(let insight of this.proposalInsights){

      for(let question of insight.questions){
        if(question.valid && !question.used_in_generation){
          totalProcessed++;
        }
      }
    }
    return totalProcessed;
  }

  getDocumentContributionScore(supportingDoc:any){
    return this._insightsService.getDocumentContributionScore(supportingDoc,this.proposalInsights,this._proposalDetail);
  }

  triggerFileInput(){
    this.fileInput.nativeElement.click();
  }

  isProcessingSelectedInsight(){
    if(this.isProcessingFiles || this.isSavingInsight || this.isUploadingFiles || this.isValidatingInsight ||
       this.insightForm.get("title").value.trim()=="" || this.insightForm.get("capability").value=="" ||
       this.insightForm.get("epic").value==""
      ){
      return true;
    }
    /*
    let isProcessed=true;
    for(let file of this.selectedSupportingFiles){
      if(!file.isProcessed){
        isProcessed=false;
        break;
      }
    }
    if(isProcessed){
      return false;
    }
    */
    return false;
  }

  async onFilesSelected(event){
    const maxSizeInBytes = 1048576*20; //20MB
     const input = event.target as HTMLInputElement;
     if (input.files) {
       const files: FileList = input.files;
       // Handle the selected files here
       for(let a=0;a<files.length;a++){
         if(files[a].size>maxSizeInBytes){
           
           this.alert={
             type:"error",
             message:"The maximum allowed file size is 20MB"
           }
           this.sizeLimitAlert=true;
           setTimeout(()=>{
             this.sizeLimitAlert=false;
           },3000);
           return;
       }
     }

      //console.log(files);
       for (let i = 0; i < files.length; i++) {
         if(files[i].size>maxSizeInBytes){
           this.alert={
             type:"error",
             message:"The maximum allowed file size is 20MB"
           }
           this.sizeLimitAlert=true;
           setTimeout(()=>{
             this.sizeLimitAlert=false;
           },3000);
         }else{
          let fileData=this.detectFileType(files[i]);
         this.selectedSupportingFiles.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size})

         }
       }
       await this.uploadSupportingFiles();
     }
     
  }

  async uploadSupportingFiles(){
    this.isUploadingFiles=true;
    for(let i=0;i<this.selectedSupportingFiles.length;i++){
      if(this.selectedSupportingFiles[i].uploadURL===""){   
        try{
          let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.selectedSupportingFiles[i].title,filetype: this.selectedSupportingFiles[i].file.type}));
          this.selectedSupportingFiles[i].uploadURL=uploadResp.uploadURL;
          this.selectedSupportingFiles[i].fileName=uploadResp.filename;
        }catch(error){
          console.log("Error:",error);
        }

        await firstValueFrom(this._proposalService.uploadSupportingFile(this.selectedSupportingFiles[i].uploadURL,this.selectedSupportingFiles[i].file));

        let supportingDocument={
          title: this.selectedSupportingFiles[i].title,
          description: "",
          type: this.selectedSupportingFiles[i].type,
          fileName: this.selectedSupportingFiles[i].fileName,
          technology:this._proposalDetail.proposal_meta.technology,
          clouds:this._proposalDetail.proposal_meta.product,
          industry:this._proposalDetail.proposal_meta.industry,
          proposalID:this._proposalDetail._id,
          fileSize:this.selectedSupportingFiles[i].fileSize,
          authorName: `${this.user.personal?.name?.first} ${this.user.personal?.name?.last}` || ""
        };

        try{
          let sdResponse=await firstValueFrom(this._proposalService.saveSupportingDocument(supportingDocument));
          this.selectedSupportingFiles[i].id=sdResponse.data._id||"";
          this._proposalDetail.supporting_docs.push(sdResponse.data);

          this._proposalService.updateProposalSupportingDoc(this._proposalDetail._id,sdResponse.data._id).subscribe((response)=>{});

        }catch(error){

        }
      }
    }
    for(let i=0;i<this.selectedSupportingFiles.length;i++){ 
      if(!this.selectedInsightQuestion.linkedDocs.includes(this.selectedSupportingFiles[i].id)){
        this.selectedInsightQuestion.linkedDocs.push(this.selectedSupportingFiles[i].id);
      }
    }
    this.isUploadingFiles=false;
    this.isProcessingFiles=true;
    this.hasProcessedLocalInsight=false;
    this.onSaveInsightQuestion();
  }

  detectFileType(file: File): { value: number, label: string } | null {
    const fileTypeMap: { [key: string]: string } = {
      'application/pdf': 'PDF',
      'text/csv': 'CSV',
      'text/plain':"TXT",
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'image/jpeg': 'JPEG',
      'image/png': 'PNG',
      'image/jpg': 'JPG'
    };
    const fileType = file.type;
    if (fileTypeMap[fileType]) {
      let thisFileType=fileTypeMap[fileType];
      let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
      return { value: fileValue.value, label: thisFileType };
    }
    return null;
  }

  onRegenerateProposal(){
    if(this.isRegeneratingProposal || this._proposalDetail.status!=2 || this.currentScore<this.maximumScoreValue){
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {insights:this.getTotalUnusedProcessedInsights(),page_id:this.page_id},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.yes===true){
        this.isRegeneratingProposal=true;
        this._proposalService.regenerateProposal(this._proposalDetail._id).subscribe((response)=>{
          this.isRegeneratingProposal=false;
          if(response.success==true){
            window.location.reload();
          }
        });
      }
    });
  }

  onGenerateProposalInsights(){
    if(this.isGeneratingInsights){
      return;
    }
    this.isGeneratingInsights=true;
    this._websocketService.sendGenerateInsightsCommand(this._proposalDetail._id,this._proposalDetail._id);
  }

  getSupportingDocFileSize(file){
    if(file.fileSize){
    const BYTES_PER_MB = 1024 * 1024;
    let mbs=file.fileSize / BYTES_PER_MB;
    return `${Math.ceil(mbs)} MB`;
    }
    return "0 MB";
  }

  getIncreaseScoreMessage(){
    let increaseBy=0;
    if(this.currentScore<this.maximumScoreValue){
      increaseBy=this.maximumScoreValue-this.currentScore;
    }
    if(increaseBy==0){
      return "Provide more insights below before regenerating the " + this.page_id + " for the best outcome.";
    }
    return `Answer the insights below to increase your score by ${increaseBy}, enabling the ${this.page_id} regeneration ability.`;
  }

  startSpeechRecognition() {
    this.recording = !this.recording;
    if(this.recording){
      let text = this.insightForm.get("notes").value;
      // console.log("text",text);
      this.speechRecognition.start();
      var interim_transcript = '';
      var final_transcript = text + ' ';
      this.speechRecognition.onresult = (event) => {
        // const result = text + " " +event.results[0][0].transcript;
        for (var i = event.resultIndex; i < event.results.length; ++i) {
            // Verify if the recognized text is the last with the isFinal property
            if (event.results[i].isFinal) {
                final_transcript += event.results[i][0].transcript;
                if (this.platform.ANDROID || this.platform.IOS) {
                  this.speechRecognition.stop();
                  this.recording=false;
                }
            } else {
                interim_transcript += event.results[i][0].transcript;
            }
        }
        // const result = text + " " +final_transcript;
        this.zone.run(() => {
          if (this.platform.ANDROID || this.platform.IOS) {
            this.insightForm.get('notes').setValue(final_transcript.toString());
          }else{
            this.insightForm.get('notes').setValue(final_transcript.toString() + '. ');
          }
          
        });
        // Choose which result may be useful for you

        // console.log("Interim: ", interim_transcript);

        // console.log("Final: ",final_transcript);

        // console.log("Simple: ", event.results[0][0].transcript);
      };
    }
    else {
      this.speechRecognition.stop();
    }
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){ 
    this.insideTextArea=true;
  }

  onMouseLeave(){ 
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onTextSelect(event,targetId){
    // console.log("Event:",event);
    if(!this.is_write_allowed){
     return;
    }
     // const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);
 
     if(targetId.includes("description")||targetId.includes("solution")){
       let data=targetId.split("-");
       this.selectedFeatureData={
         type:data[0],
         index:parseInt(data[1])
       };
     }else{
       this.selectedFeatureData={
         type:"",
         index:0
       };
     }
     // console.log('selectedFeatureData', this.selectedFeatureData);
     this.mouseInitialPosition=event.clientY;
     //This code place menu at bottom of selection
     const textarea = event.target as HTMLTextAreaElement;
 
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
         let last=event.pageY-120;
         if(last<this.mouseDownPos){
           const screenWidth = window.innerWidth;
           const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;
 
         this.menuPosition = {
             top: `${last}px`,
             left: `${xPosition}px`
         };
 
         }
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
  }

  onMouseDown(event){
    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
    }
    // console.log("On Selection Change:",event.clientX,event.clientY);
    const textarea = event.target as HTMLTextAreaElement;
    // const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    //  const targetId = (event.target as HTMLElement).id;
   // console.log('Target element ID:', targetId);

    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    // this.quickSelectedText=selectedText;
    if (this.quickSelectedText.trim().length > 0) {
      
     /*
      if(event.pageY<this.mouseDownPos){

      
      this.menuPosition = {
          top: `${event.pageY-90}px`,
          left: this.menuPosition.left
      };

      }
      */

      /*
        this.menuPosition = {
            top: `${clientY + window.scrollY -30}px`,
            left: `${90+window.scrollX}px`
        };
*/
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    } 
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');

    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
    if(!this.selectedText){
      this.overflowbubble = false;
    }
  }

  onCompleteQuickAction(newValue){
    const { from, to } = this.editor.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor.view.dispatch(tr);
    this.editor.chain().focus().setTextSelection({ from, to: newTo }).run();
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();
      
  }

  getDocumentScoreClass(file): string {
    let score=this.getDocumentContributionScore(file);
    return score === 0 ? 'text-[#4F45E4]' : 'text-[#16A34A]';
  }

}


