import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((userdata) =>
        {   
            let routeRole = route.data && route.data.roles?route.data.roles[0]:'';
            console.log("User Data:", userdata);
            console.log("Requested Route Role:", routeRole);
            // If the user is not authenticated...
            if (!isAuthenticated(userdata)) {
                console.log("User not authenticated");
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                return of(router.parseUrl(`sign-in?${redirectURL}`));
            }
            if (routeRole == 'User' && isAuthenticated(userdata) && !isAllowedRoute(state.url) && !isAdmin(userdata)){
                console.log("User needs onboarding");
                // return of(router.parseUrl(`onboarding`));
                return of(true);
            }
            if (isAuthenticated(userdata) && (isAllowedRoute(state.url) || (routeRole === 'Admin' && isAdmin(userdata)) || (routeRole === 'User' && !isAdmin(userdata)) || (routeRole === 'SME' && !isAdmin(userdata)))) {
                console.log("User has access to route");
                return of(true);
            }

            console.log("Access denied, redirecting to sign-in");
            return of(router.parseUrl(`sign-in`));
        }),
    );

    function isAuthenticated(userdata: any): boolean {
        return userdata && userdata.isAuthenticated;
    }

    function isAdmin(userdata: any): boolean {
        return userdata.additionalData && userdata.additionalData.is_system_admin;
    }

    function needsOnboarding(userdata: any): boolean {
        return (
            !userdata.additionalData ||
            !userdata.additionalData.organisation ||
            !userdata.additionalData.organisation.role ||
            !userdata.additionalData.organisation.organizationType ||
            !userdata.additionalData.organisation.organizationalLevel ||
            !userdata.additionalData.organisation.experienceLevel ||
            !userdata.additionalData.organisation.teamSize
        );
    }
    
    function isAllowedRoute(stateUrl: string): boolean {
        return ['/join', '/sign-out', '/settings', '/onboarding'].some((route) => stateUrl.includes(route));
    }
};
